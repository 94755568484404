import {Injectable} from '@angular/core';
import {GlobalSettings} from '@interfaces/global.interface';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  public config: GlobalSettings = {
    dbName: 'arkiusDeFiDB',
    metamaskEnabled: false,
    metamaskUserId: '',
    selectedPackage: {},
    navigation: [
      {route: '/certifications', token: 'NAVIGATION.CERTIFICATIONS', icon: 'status-badge-verified'},
      {route: '/products', token: 'NAVIGATION.PRODUCTS', icon: 'navigation-products'},
      {route: '/campaigns', token: 'NAVIGATION.CAMPAIGNS', icon: 'navigation-campaigns'},
    ],
    profile: [
      {
        section: 'NAVIGATION.SECTION.CONNECT',
        children: [
          {route: '/account', token: 'NAVIGATION.CONNECT', icon: 'system-settings'}
        ]
      },
      {
        section: 'NAVIGATION.SECTION.SUPPORT',
        children: [
          {route: 'mailto:app-support@valuesmovement.org', token: 'NAVIGATION.EMAIL', icon: 'profile-contact'}
        ]
      },
    ]
  };

  user: any = {
    info: {}
  };

  constructor() {
  }

  set(variable: string, value: any): void {
    this.config[variable] = value;
  }

  get(variable: string): any {
    return this.config[variable];
  }
}
