import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PostAuthor} from '@interfaces/forum.interface';
import {ModalSize, OptionsMenu, OptionsMenuItemIdentifier} from '@interfaces/common.interface';
import {ModalService} from '@services/modal.service';
import {PostDeleteCommentComponent} from '@app/components/modals/forum/post-delete-comment/post-delete-comment.component';
import {ModalConfig} from '@components/common/modal/modal.config';
import {IntercomService} from '@services/intercom.service';
import {BroadcastEvent} from '@interfaces/broadcast.interface';

@Component({
  selector: 'ark-post-author',
  templateUrl: './post-author.component.html',
  styleUrls: ['./post-author.component.scss']
})
export class PostAuthorComponent implements OnInit {

  @Input() author!: PostAuthor;
  @Input() metadata!: any;
  @Input() date?: string;
  @Input() comment = false;
  @Input() menu!: OptionsMenu;

  @Output() deleteComment: EventEmitter<any> = new EventEmitter<any>();

  get authorName(): string {
    return `${this.author.firstName} ${this.author.lastName}`;
  }

  modalConfig: ModalConfig = {
    size: ModalSize.DYNAMIC,
    style: 'dark'
  };

  constructor(private modal: ModalService, private intercom: IntercomService) {
    this.intercom.on(BroadcastEvent.DRAWER_ACTION, (data: any) => { //TODO: Proper interface
        if (this.metadata.id === data.post.id) {
          this.itemClicked(data.action);
        }
    });
  }

  ngOnInit(): void {
    if (this.date) {

    }
  }

  openMobileContextMenu(): void {
    this.intercom.broadcast({
      event: BroadcastEvent.SHOW_DRAWER,
      data: {
        menu: this.menu,
        metadata: this.metadata
      }
    });
  }

  async itemClicked(item: OptionsMenuItemIdentifier): Promise<void> {
    switch (item) {
      case 'comment.delete':
        const modal = this.modal.open(PostDeleteCommentComponent, this.modalConfig);
        modal.afterClosed.subscribe(shouldDelete => {
          if (shouldDelete) {
            this.deleteComment.emit();
          }
        })
        break;
    }
  }
}
