import {Component, Input, OnInit} from '@angular/core';
import {IconImage, IconSize} from '@interfaces/common.interface';

@Component({
  selector: 'ark-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {

  @Input() leftIcon: IconImage = '';
  @Input() iconSize: IconSize = 'small';
  @Input() rightIcon: IconImage = '';
  @Input() type: 'primary' | 'secondary' | 'negative' | 'error' | 'light' | 'black' | 'flat' | 'icon' | 'fab' = 'primary';
  @Input() size: 'tiny' | 'small' | 'middle' | 'xm' | 'medium' | 'large' | 'xl' | 'huge' | 'round' = 'medium';
  @Input() disabled: boolean | undefined = false;
  @Input() loading = false;
  @Input() link = false;

  @Input() iconColor = 'light';
  loaderColor: 'light' | 'cta' | 'dark' = 'dark';

  constructor() { }

  ngOnInit(): void {
    if (this.type === 'primary') {
      this.iconColor = 'light';
      this.loaderColor = 'light';
    }
    if (this.type === 'secondary') {
      this.iconColor = 'cta';
      this.loaderColor = 'cta';
    }
  }

}
