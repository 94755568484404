<div [formGroup]="form" class="wrapper" [ngClass]="getFormClasses()">
  <input
    type="radio"
    class="checkbox"
    [checked]="checked"
    [name]="name"
    [tabIndex]="tabIndex"
    (focus)="onFocus()"
    (blur)="onBlur()"
    (keydown.space)="toggleSelection($event)"
    (click)="toggleSelection($event)"
  >
  <label>{{label | translate}}<ng-content *ngIf="!label"></ng-content></label>
  <ark-icon icon="system-tick" color="light" size="micro" class="checkbox-tick"></ark-icon>
</div>
<div class="error" *ngIf="formStates.error && errorMessage" [@dropdownAnimation]>
  <span [innerHTML]="errorMessage"></span>
</div>
