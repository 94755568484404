import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {InputComponent} from '@components/form/input/input.component';

@Component({
  selector: 'ark-radio',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.scss']
})
export class RadioComponent extends InputComponent implements OnInit {

  _checked = false;

  get checked(): boolean {
    return this._checked;
  }

  @Input() set checked(value: boolean) {
    this._checked = value;
    this.formStates.selected = value;
  }

  constructor(fb: FormBuilder, translate: TranslateService) {
    super(fb, translate);
  }

  override ngOnInit(): void {
    if (this.disabled) {
      this.formStates.disabled = true;
      this.tabIndex = -1;
    }
  }

  toggleSelection(evt: Event): void {
    evt.preventDefault();
    evt.stopPropagation();
    if (!this.disabled) {
      this.formStates.selected = true;
      this.valueChanged.emit({value: this.formStates.selected, status: 'VALID'});
    }
  }

  override onFocus(): void {
    this.formStates.focused = true;
  }

  override onBlur(): void {
    this.lostFocus.emit({value: this.formStates.selected, status: 'VALID'});
    this.formStates.focused = false;
  }

}
