import {ChangeDetectorRef, Component} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {DropDownOption, ValueChangeEvent} from '@interfaces/form.interface';
import {ModalComponent} from '@components/common/modal/modal.component';
import {IProposalItem} from '@interfaces/proposal.interfase';
import {ProposalModelService} from '@app/models/proposal-model.service';
import {Category, IEntityCategory} from '@interfaces/api.category.interface';
import {CategoryModelService} from '@models/category-model.service';
import {BehaviorSubject} from 'rxjs';
import {ModalService} from '@services/modal.service';
import {
  ProposalActionSuccessComponent
} from '@components/modals/proposals/proposal-action-success/proposal-action-success.component';
import {ModalSize} from '@interfaces/common.interface';
import {
  ProposalActionErrorComponent
} from '@components/modals/proposals/proposal-action-error/proposal-action-error.component';
import {BroadcastEvent} from '@interfaces/broadcast.interface';
import {IntercomService} from '@services/intercom.service';

@Component({
  selector: 'ark-proposal-action',
  templateUrl: './proposal-action.component.html',
  styleUrls: ['./proposal-action.component.scss']
})
export class ProposalActionComponent {

  public form!: FormGroup;
  public busy: boolean = false;
  public categoryId: number = 0;
  public category: IEntityCategory[] = [];
  public edit: boolean = false;
  public item: IProposalItem | null = null;
  public categories: DropDownOption[] = [];
  public selectedCategory: string = '';

  public state = 'first';
  public previousStep = '';
  public stateChange = new BehaviorSubject('first');

  public disabled = false;
  public areasError = false;
  public tagsError = false;
  public goalsTokens = 0;

  public readonly minChars = 100;
  public readonly maxChars = 500;

  protected rates = 1;

  protected entityCategories: IEntityCategory[] = [];
  public parentCategories: DropDownOption [] = [];
  protected selectedEntityCategories: DropDownOption [] = [];

  constructor(
    private fb: FormBuilder,
    protected readonly parent: ModalComponent,
    private proposalService: ProposalModelService,
    private cRef: ChangeDetectorRef,
    private categoryService: CategoryModelService,
    private intercom: IntercomService,
    private readonly modalService: ModalService
  ) {
    this.categoryId = parent.data.categoryId;
    this.edit = parent.data.edit;
    this.entityCategories = this.parent.data.entityCategories;

    this.parent.closeButton = true;
    if (this.edit) {
      this.item = this.parent.data.item;
    }

    this.form = this.initForm();
    this.parent.scroll = true;

    this.stateChange.subscribe((state) => {
      this.previousStep = this.state;
      this.state = state;
      this.parent.scroll = state === 'second' || state === 'first';
    })

    this.categoryService.categoryList().then((categories: Category[]) => {
      this.category = ProposalActionComponent.getCategory(categories, +this.categoryId);
      this.categories = ProposalActionComponent.getSelectData(categories, +this.categoryId);
      this.parentCategories = this.transformEntityCategories();
      this.selectedEntityCategories = this.parentCategories;
    });
  }

  public initForm(): FormGroup {
    return this.fb.group({
      title: [this.item ? this.item!.title : null, Validators.required],
      description: [this.item ? this.item!.description : null, Validators.compose([Validators.required, Validators.minLength(100), Validators.maxLength(500)])],
      purpose: [this.item ? this.item!.purpose : null, Validators.required],
      methodology: [this.item ? this.item!.methodology : null, Validators.required],
      certificationCategoryId: [+this.categoryId, Validators.required],
      targetTokens: [this.item ? this.item!.targetTokens : 0],
      areas: [this.item && this.item.areas && this.item.areas.length > 0 ? this.item.areas : []],
      tags: [this.item && this.item.tags && this.item.tags.length > 0 ? this.item.tags : []],
    });
  }

  public formValueChange(event: ValueChangeEvent, control: string) {
    if (control === 'goal') {
      const value = event.value.replace(/\D/g, '');
      this.goalsTokens = +value * this.rates;
      this.form.get('targetTokens')?.setValue(this.goalsTokens);
    } else {
      this.form.get(control)?.setValue(event.value);
    }

    this.form.markAsDirty();
    this.form.updateValueAndValidity();
  }

  validateForm() {
    if (this.form.get('description')?.value === null) {
      this.form.get('description')?.setValue('');
    }
    if (this.form.get('title')?.value === null) {
      this.form.get('title')?.setValue('');
    }
    if (this.form.get('purpose')?.value === null) {
      this.form.get('purpose')?.setValue('');
    }
    if (this.form.get('methodology')?.value === null) {
      this.form.get('methodology')?.setValue('');
    }
    if (this.form.get('tags')?.value === null) {
      this.form.get('tags')?.setValue([]);
    }
    if (this.form.get('areas')?.value === null) {
      this.form.get('areas')?.setValue([]);
    }
    this.form.updateValueAndValidity();
  }

  public async save(): Promise<void> {
    this.validateForm();
    if (this.form?.valid && this.form?.dirty) {
      this.form.value.targetTokens = +this.form.value.targetTokens;
      let data = this.form.value;
      let entityCategories: any = [];
      this.selectedEntityCategories.forEach(item => entityCategories.push(+item.value));
      data['entityCategoryList'] = entityCategories;
      this.busy = true;

      if (this.edit) {
        this.proposalService.updateProposal({...this.form.value, certificationRequestId: '' + this.item?.certificationRequestId}).then(
          (res) => this.onSuccess(res),
          (error) => this.onError(error)
        );
      } else {
        this.proposalService.createProposal({...this.form.value}).then(
          (res) => this.onSuccess(res),
          (error) => this.onError(error)
        );
      }

      this.busy = false;
    }
  }

  private onError(error: any): void {
    console.error('error', error)
    this.parent.close();
    setTimeout(() => {
      this.modalService.open(ProposalActionErrorComponent, {
        style: 'dark',
        size: ModalSize.DYNAMIC,
      });
    }, 100);
  }

  private onSuccess(res: any): void {
    this.intercom.broadcast({
      event: this.edit ? BroadcastEvent.ENTITY_UPDATED: BroadcastEvent.ENTITY_CREATED,
    });
    this.parent.close();
    setTimeout(() => {
      this.modalService.open(ProposalActionSuccessComponent, {
        style: 'dark',
        size: ModalSize.DYNAMIC,
        data: {
          edit: this.edit
        }
      });
    }, 200);
  }

  public next(step: string): void {
    switch (step) {
      case 'first':
        this.stateChange.next('first');
        break;
      case 'second':
        if (this.state === 'first' && this.checkFirstStep() || this.state === 'third') {
          this.stateChange.next('second');
        } else {
          console.error('Step 2 not allowed');
        }
        break;
      default:
        break;
    }
  }

  public checkFirstStep(): boolean {
    return this.form.get('title')?.value && (this.selectedEntityCategories.length > 0 && this.selectedEntityCategories.length <= 3)
     && this.form.get('areas')?.value.length > 0  && this.form.get('tags')?.value.length > 0;
  }

  public checkSecondStep(): boolean {
    return this.form.get('description')?.value
      && this.form.get('purpose')?.value
      && this.form.get('methodology')?.value
      && this.form.get('description')?.value.length  >= this.minChars
      && this.form.get('description')?.value.length  <= this.maxChars
      && this.form.get('purpose')?.value.length  >= this.minChars
      && this.form.get('purpose')?.value.length  <= this.maxChars
      && this.form.get('methodology')?.value.length  >= this.minChars
      && this.form.get('methodology')?.value.length  <= this.maxChars;
  }

  public onAreasChange(value: any) {
    this.form.get('areas')?.setValue(value);
    const keywords = this.form.get('areas')?.value as Array<string>;
    this.areasError = keywords.length === 0 || keywords.length > 15;
    this.form.markAsDirty();
    this.form.updateValueAndValidity();
  }


  public onTagsChange(value: any) {
    this.form.get('tags')?.setValue(value);
    const keywords = this.form.get('tags')?.value as Array<string>;
    this.tagsError = keywords.length === 0 || keywords.length > 15;
    this.form.markAsDirty();
    this.form.updateValueAndValidity();
  }

  public moreSelected(item: DropDownOption): void {
    this.categoryId = +item;
    this.disabled = +this.categoryId === 0;

    this.categories.forEach((i) => {
      if (+i.value === +item) {
        this.selectedCategory = i.name;
      }
    });
    this.cRef.detectChanges();
  }

  public selectedOptions(event: any): void {
    this.selectedEntityCategories = event;
  }

  protected static getCategory(categories: Category[], categoryId: number): IEntityCategory[] {
    let _categories: IEntityCategory[] = [];
    categories.forEach(category => {
      if (+category.certificationCategoryId! === +categoryId) {
        _categories = [{
          certificationCategoryId: +categoryId,
          certificationCategoryName: category.name
        }];
      }
    })
    return _categories;
  }

  private static getSelectData(categories: Category[], categoryId: number): DropDownOption[] {
    let options: DropDownOption[] = [];

    categories.forEach((category) => {
      let option: DropDownOption = {name: category.name, value: '' + category.certificationCategoryId}


      option['selected'] = +category!.certificationCategoryId! === categoryId;
      options.push(option);
    });

    return options;
  }

  protected transformEntityCategories() {
    if (this.entityCategories.length === 0 && this.categoryId !== 0 && this.category.length > 0) {
      this.entityCategories = this.category;
    }
    let result: DropDownOption[] = [];
    this.entityCategories.forEach(item => {
      const el: DropDownOption = {
        value: item.certificationCategoryId,
        name: item.certificationCategoryName,
        selected: true
      }
      result.push(el);
    })
    return result;
  }
}
