import {Injectable} from '@angular/core';
import {ApiService} from '@services/api.service';
import {
  ApiAuthChallengeResponse,
  ApiAuthLoginRequest,
  ApiAuthLoginResponse
} from '@interfaces/api.auth.interface';
import {MetamaskService} from '@services/metamask.service';

@Injectable({
  providedIn: 'root'
})
export class AuthModelService {

  constructor(private api: ApiService,
              private metamask: MetamaskService) {
  }

  public async loginWithMagic(walletId: string, magicLinkToken: string, invitationCode?: string, firstName?: string, lastName?: string): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const payload: ApiAuthLoginRequest = { walletId, magicLinkToken, invitationCode, firstName, lastName };
      if (!invitationCode) {
        delete payload.invitationCode;
      }
      if (!firstName) {
        delete payload.firstName;
      }
      if (!lastName) {
        delete payload.lastName;
      }
      this.api.auth.login(payload).subscribe(async(response: ApiAuthLoginResponse) => {
        resolve(response);
      }, (error) => {
        reject(error);
      });
    })
  }

  public async login(walletId: string): Promise<any> {
    return new Promise(async (resolve, reject) => {
      this.api.auth.challenge(walletId).subscribe(async (response: ApiAuthChallengeResponse) => {
        if (response.nonce) {
          this.metamask.sign(response.nonce, walletId).then(signature => {
            if (signature) {
              this.api.auth.login({signature, walletId}).subscribe((loginResponse: ApiAuthLoginResponse) => {
                resolve(loginResponse);
              }, (err) => {
                reject(err);
              });
            } else {
              reject ('User did not provide signature');
            }
          }).catch(err => {
            reject(err);
          });
        } else {
          reject('No challenge nonce returned');
        }
      }, (error) => {
        reject(error);
      });
    });
  }
}
