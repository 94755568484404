import {Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild} from '@angular/core';
import {DateTime} from 'luxon';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {distinctUntilChanged} from 'rxjs/operators';

@Component({
  selector: 'ark-date',
  templateUrl: './date.component.html',
  styleUrls: ['./date.component.scss']
})
export class DateComponent implements OnInit {

  @ViewChild('dobContainer', {static: true}) dobContainer: ElementRef | any;
  @ViewChild('dayInput') dayInput: ElementRef | any;
  @ViewChild('monthInput') monthInput: ElementRef | any;
  @ViewChild('yearInput') yearInput: ElementRef | any;
  @Input() label: string | any = '';
  @Output() dateChange = new EventEmitter<any>();
  form: FormGroup | any;
  hasFocus = false;
  hasError = false;
  localValue: any = '';
  isUS = false;

  constructor(private fb: FormBuilder, private rendered: Renderer2) {
    this.isUS = navigator.language.split('-')[1] === 'US';
  }

  get value(): string | any {
    return this.localValue;
  }

  @Input() set value(value: string | any) {
    this.localValue = value;
    if (value) {
      this.form.get('day').setValue(DateTime.fromISO(this.value).day.toString().padStart(2, '0'));
      this.form.get('month').setValue(DateTime.fromISO(this.value).month.toString().padStart(2, '0'));
      this.form.get('year').setValue(DateTime.fromISO(this.value).year.toString().padStart(2, '0'));
    }
  }

  onInput(e: any, control: string, max: number): void {
    const value = e.target.value;

    const len = value === '0' ? 1 : value.length;
    if (len >= max) {
      if (this.isUS) {
        if (control === 'month') {
          this.dayInput.nativeElement.focus();
          this.dayInput.nativeElement.select();
        } else if (control === 'day') {
          this.yearInput.nativeElement.focus();
          this.yearInput.nativeElement.select();
        }
      } else {
        if (control === 'day') {
          this.monthInput.nativeElement.focus();
          this.monthInput.nativeElement.select();
        } else if (control === 'month') {
          this.yearInput.nativeElement.focus();
          this.yearInput.nativeElement.select();
        }
      }
    }
    if (len > max) {
      const num = value.slice(0, max);
      this.form.get(control).setValue(num);
    }
  }

  ngOnInit(): void {
    let day;
    let month;
    let year;
    if (this.value) {
      day = DateTime.fromISO(this.value).day.toString().padStart(2, '0');
      month = DateTime.fromISO(this.value).month.toString().padStart(2, '0');
      year = DateTime.fromISO(this.value).year;
    }

    this.form = this.fb.group({
      day: new FormControl(day || '', [Validators.required, Validators.min(1), Validators.max(31)]),
      month: new FormControl(month || '', [Validators.required, Validators.min(1), Validators.max(12)]),
      year: new FormControl(year || '', [Validators.required, Validators.min(1900), Validators.max(DateTime.local().year)])
    });

    if (this.value) {
      this.dateChange.emit(this.form.value);
    }

    this.form.get('day').valueChanges.pipe(
      distinctUntilChanged()).subscribe((value: any) => {
      const isNumber = /^[0-9]{1,2}$/.test(value);
      if (!isNumber) {
        const newValue = value.slice(0, -1);
        this.form.get('day').setValue(newValue);
      }
    });

    this.form.get('month').valueChanges.pipe(
      distinctUntilChanged()).subscribe((value: any) => {
      const isNumber = /^[0-9]{1,2}$/.test(value);
      if (!isNumber || value === ' ') {
        const newValue = value.slice(0, -1);
        this.form.get('month').setValue(newValue);
      }
    });

    this.form.get('year').valueChanges.pipe(
      distinctUntilChanged()).subscribe((value: any) => {
      const isNumber = /^[0-9]{1,4}$/.test(value);
      if (!isNumber || value === ' ') {
        const newValue = value.slice(0, -1);
        this.form.get('year').setValue(newValue);
      }
    });


    this.form.valueChanges.pipe(
      distinctUntilChanged()).subscribe((data: any) => {
      const isDateValid = this.dateIsValid();
      if (!isDateValid) {
        this.dateChange.emit(null);
      } else {
        this.dateChange.emit(data);
      }
    });
  }


  dateIsValid(): boolean {
    try {
      if (this.form.get('year').value < 1900) {
        return false;
      }
      return DateTime.fromObject(this.form.value).isValid;
    } catch (e) {
      return false;
    }
  }

  dateChanged(): any {
    return this.form.dirty;
  }

  onInputFocus(): void {
    this.hasFocus = true;
    this.rendered.addClass(this.dobContainer.nativeElement, 'd-active');
  }

  onInputBlur(): void {
    this.hasFocus = false;
    this.rendered.removeClass(this.dobContainer.nativeElement, 'd-active');

    setTimeout(() => {

      if (!this.hasFocus && this.dateChanged()) {
        const isDateValid = this.dateIsValid();
        this.hasError = !isDateValid;
      }

    }, 300);

  }
}
