import {Component} from '@angular/core';
import {ModalComponent} from '@components/common/modal/modal.component';
import {ValueChangeEvent} from '@interfaces/form.interface';
import {ProposalModelService} from '@app/models/proposal-model.service';
import {IProposalItem} from '@interfaces/proposal.interfase';
import {ModalSize} from '@interfaces/common.interface';
import {ModalService} from '@services/modal.service';
import {
  ProposalUnbackAllComponent
} from '@components/modals/proposals/proposal-unback-all/proposal-unback-all.component';
import {ProposalBackedComponent} from '@components/modals/proposals/proposal-backed/proposal-backed.component';
import {BroadcastEvent} from '@interfaces/broadcast.interface';
import {IntercomService} from '@services/intercom.service';

@Component({
  selector: 'ark-proposal-back-unback',
  templateUrl: './proposal-back-unback.component.html',
  styleUrls: ['./proposal-back-unback.component.scss']
})
export class ProposalBackUnbackComponent {
  public busy: boolean = false;
  public back: boolean = true;
  public amount: number = 0;
  public item: IProposalItem;
  public balance: number | string = 0;
  public token: number = 0;
  protected tokenInputValue: number = 0;
  protected maxCredits = 100;

  constructor(
    protected readonly parent: ModalComponent,
    private proposalService: ProposalModelService,
    private intercom: IntercomService,
    private readonly modalService: ModalService
  ) {
    this.item = parent.data.item;
    this.amount = parent.data.amount;
    this.back = parent.data.back;
    this.setSliderData();
  }

  public close(value: string | undefined): void {
    if (!this.busy) {
      this.parent.close(value);
    }
  }

  protected setSliderData() {
    if (+this.item.tokensBackedByMember > 0.001) {
      this.back = false;
    }
    this.parent.closeButton = true;
    this.balance = this.amount < this.maxCredits ? this.amount : this.maxCredits;
    if (!this.back) {
      this.tokenInputValue = this.item.tokensBackedByMember;
    }
    if (+this.balance > 0 && +this.item.tokensBackedByMember) {
      this.token = +this.item.tokensBackedByMember / +this.balance;
    }
  }

  public tokenCalculate(data: number): void {
    this.token = data / 100;
    this.tokenInputValue = this.token;
  }

  public getToken(): string | number {
    let val = this.token ? (+this.token * +this.balance).toFixed(6) : 0;
    val = +this.balance >= val ? val : this.balance;
    return val;
  }

  public inputTokenChanged(e: Event | ValueChangeEvent): void {
    if (e instanceof InputEvent) {
      const element = e.target as HTMLInputElement;
      if (+this.balance !== 0) {
        this.tokenInputValue = +element.value / +this.balance;
      }

    } else if ('value' in e) {
      this.tokenInputValue = e.value;
    }
  }

  public setToken(e: Event | ValueChangeEvent): void {
    if (e instanceof InputEvent) {
      const element = e.target as HTMLInputElement;
      this.tokenInputValue = +element.value;
    } else if ('value' in e) {
      if (+this.balance !== 0) {
        this.tokenInputValue = e.value / +this.balance;
      }
    }
    this.token = this.tokenInputValue;
  }

  public getBalance(): number | string {
    return this.balance;
  }

  public async save(): Promise<void> {
    const amount = +this.token! * +this.balance;
    const back = +this.item.tokensBackedByMember === 0;

    if (amount >= 0) {
      const data = {
        Amount: +amount,
        CertificationRequestId: +this.item.certificationRequestId
      }
      let res;

      if (back) {
        res = await this.proposalService.backProject(data);
      } else if (+this.tokenInputValue !== 0) {
        res = await this.proposalService.updateBackProject(data);
      }

      this.busy = false;
      this.close('confirm');

      if (!back && +this.tokenInputValue === 0) {
        this.modalService.open(ProposalUnbackAllComponent, {
          style: 'dark',
          size: ModalSize.DYNAMIC,
          data: {
            item: this.item,
          }
        });
      }
      if (res && 'message' in res) {
        if (res['message'] === 'backed') {
          this.modalService.open(ProposalBackedComponent, {
            style: 'dark',
            size: ModalSize.DYNAMIC,
            data: {
              mode: 'backed'
            }
          });
        } else if (res['message'] === 'updated') {
          this.modalService.open(ProposalBackedComponent, {
            style: 'dark',
            size: ModalSize.DYNAMIC,
            data: {
              mode: 'updated'
            }
          });
        }
        this.back = !(+this.item.tokensBackedByMember > 0 && +this.tokenInputValue > 0);
        this.intercom.broadcast({
          event: BroadcastEvent.BACKED,
        });
        setTimeout(() => this.close('success'), 2000);
      }
    }
  }

  public async remove(): Promise<void> {
    this.close('delete');
    this.modalService.open(ProposalUnbackAllComponent, {
      style: 'dark',
      size: ModalSize.DYNAMIC,
      data: {
        item: this.item,
      }
    });
  }
}
