import { Component, OnInit } from '@angular/core';
import { ModalComponent } from '@components/common/modal/modal.component';
import { dropdownAnimation } from '@animations/dropdown.animations';

@Component({
  selector: 'ark-post-cover-selection',
  templateUrl: './post-cover-selection.component.html',
  styleUrls: ['./post-cover-selection.component.scss'],
  animations: [dropdownAnimation()]
})
export class PostCoverSelectionComponent implements OnInit {

  constructor(private parent: ModalComponent) { }

  ngOnInit(): void {
  }

  publish(formValue: any): void {
      this.parent.close(formValue);
  }
  cancel(): void {
    this.parent.close();
  }


}
