import {Pipe, PipeTransform} from '@angular/core';
import {UtilService} from '@services/util.service';

@Pipe({name: 'kiloformatter'})
export class KiloformatterPipe implements PipeTransform {

  constructor(private util: UtilService) {}

  transform(value: number | undefined | null, fractionDigits: number = 2): string {
    return this.util.kiloFormatter(value, fractionDigits);
  }
}
