import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalComponent } from '@app/components/common/modal/modal.component';
import { Survey } from '@app/etc/interfaces/api.survey.interface';
import { ModalSize } from '@app/etc/interfaces/common.interface';
import { ValueChangeEvent } from '@app/etc/interfaces/form.interface';
import { SurveyModelService } from '@app/models/survey-model.service';
import { ModalService } from '@app/services/modal.service';
import { SurveyActionSuccessComponent } from '../survey-action-success/survey-action-success.component';

@Component({
  selector: 'ark-survey-action',
  templateUrl: './survey-action.component.html',
  styleUrls: ['./survey-action.component.scss']
})
export class SurveyActionComponent implements OnInit {
  public edit: boolean = false;
  selectedSurvay!: Survey;
  form = this.initForm();
  loading = false;
  serverErrorMsg = null;

  constructor(
    private fb: FormBuilder,
    protected readonly parent: ModalComponent,
    private readonly modalService: ModalService,
    private surveySrv: SurveyModelService
  ) {
    this.edit = parent.data.edit;
    if (parent.data.survey) {
      this.selectedSurvay = {
        ...parent.data.survey,
        creditsGiven: parent.data.survey.creditsGiven.toString()
      }
      this.form.patchValue(this.selectedSurvay);
    }
  }

  ngOnInit(): void {
  }

  public initForm(): FormGroup {
    return this.fb.group({
      creditsGiven: [null, Validators.compose([Validators.required, Validators.pattern(/^\d+$/)])],
      title: [null, Validators.required],
      typeformUrl: [null, Validators.required]
    })
  }

  formValueChange(event: ValueChangeEvent, control: string) {
    this.form.get(control)?.setValue(event.value);
    this.form.markAsDirty();
    this.form.updateValueAndValidity();
  }


  validateForm() {
    if (this.form.get('title')?.value === null) {
      this.form.get('title')?.setValue('')
    }

    if (this.form.get('creditsGiven')?.value === null) {
      this.form.get('creditsGiven')?.setValue('')
    }
    if (this.form.get('typeformUrl')?.value === null) {
      this.form.get('typeformUrl')?.setValue('')
    }

    this.form.updateValueAndValidity();
  }

  submit() {
    this.validateForm();
    if (this.form?.valid && this.form?.dirty) {
      let surveyData;
      if (this.edit) {
        surveyData = {
          ...this.form.getRawValue(),
          surveyId: this.selectedSurvay.surveyId,
          creditsGiven: +this.form.getRawValue().creditsGiven
        }
        this.surveySrv.updateSurvey({ data: surveyData }).then(
          (res) => this.onSuccess(res),
          (error) => this.onError(error)
        );
      } else {
        surveyData = {
          ...this.form.getRawValue(),
          creditsGiven: +this.form.getRawValue().creditsGiven
        }
        this.surveySrv.createSurvey(surveyData).then(
          (res) => this.onSuccess(res),
          (error) => this.onError(error.error)
        );
      }
    }
  }

  private onError(error: any): void {
    console.error('error', error);
    this.serverErrorMsg = error.errors[0].description;
  }

  private onSuccess(res: any): void {
    this.parent.close('success');
      this.modalService.open(SurveyActionSuccessComponent, {
        style: 'dark',
        size: ModalSize.DYNAMIC,
        data: {
          edit: this.edit
        }
      });
  }


  public close(): void {
    this.parent.close();
  }

}
