import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators, ValidatorFn} from '@angular/forms';
import {distinctUntilChanged} from 'rxjs/operators';
import {Subscription} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {dropdownAnimation} from '@animations/dropdown.animations';
import {ValueChangeEvent} from '@interfaces/form.interface';
import {ThousandPipe} from '@pipes/thousand.pipe';
import {NgxTippyProps} from 'ngx-tippy-wrapper';

@Component({
  selector: 'ark-input',
  templateUrl: './input.component.html',
  animations: [dropdownAnimation()],
  styleUrls: ['./input.component.scss']
})

/**
 * @name InputComponent
 * @description Functional form component for Input Text, Password, Emails etc.
 * @property label<string> - The floating label text of the input field
 * @property name<string> - Passed as a name tag to the form element
 * @property toolTip<string> - Text to display when hovering over the lock icon. Works only if locked = true
 * @property tabIndex<number | 'auto'> - Determines the element's tab index
 * @property noTab<boolean> - Determines if the element is included in the tab order
 * @property hasFocus<boolean> - If set to true, it initializes with having focus. Use only one per page
 * @property pattern<string> - RegExp pattern for value validation
 * @property errorLabel<string> - External error label (from server events)
 * @property type<string> - The element's content type. Determines behavior and validation
 * @property disabled<boolean> - If set to true, the element is read-only
 * @property validateOn<'blur' | 'change'> - Determines if content validation will happen while typing or when focus is lost.
 * @property info<string> - The text content of the information line below the element
 * @property locked<boolean> - If true the element is read only and a lock icon appears with a tooltip on hover.
 * @property required<boolean> - If true the element will be marked as invalid if not filled
 * @property maxlength<number> - The maximum characters allowed to be typed in the element
 * @property verifying<boolean> - Toggles the verifying icon animation, useful for server queries
 * @property error<boolean> - If true forces a custom error display. Works in conjunction with errorLabel
 * @property value<string> - Sets a predefined value for the element
 * @emits valueCleared<{value:string,status:string}> - Fires whenever the user clears the element value
 * @emits valueChanged<{value:string,status:string}> - Fires whenever the element value is changed
 * @emits lostFocus<{value:string,status:string}> - Fires whenever the element loses focus
 * @implements OnInit, OnDestroy
 * @author Sotiris Varotsis
 */

export class InputComponent implements OnInit, OnDestroy {

  @ViewChild('input', {static: true})
  input!: ElementRef;

  /**
   * Use formStates to programmatically control the form element's state
   * These are used as CSS class definitions as well.
   * Copy them over to other form elements
   * @property disabled - When the element is disabled
   * @property labelRaised - When the floating label needs to be raised
   * @property focused - When the element has focused
   * @property error - When the element has an error
   * @property valid - When the value of the element is valid
   * @property selected - Used for radio and checkboxes
   */

  formStates = {
    disabled: false,
    labelRaised: false,
    focused: false,
    error: false,
    valid: false,
    selected: false
  };

  /**
   * displayIcon is only used for input fields that have additional icons
   * such as clear, validation, password reveal and spinners
   */

  displayIcon = '';

  passwordVisible = false;
  errorMessage = '';

  form: FormGroup = new FormGroup({});
  formSubscription: Subscription = new Subscription();
  statusSubscription: Subscription = new Subscription();

  formValidators: ValidatorFn[] = [];

  @Input() label = '';
  @Input() placeholder = '';
  @Input() name = '';
  @Input() toolTip = 'Cannot change content';
  @Input() tippyName = 'tippy-input';
  @Input() public toolOptions: NgxTippyProps = {
    placement: 'bottom',
    trigger: 'mouseenter focus',
    allowHTML: true,
    interactive: true,
    theme: 'arkius',
    arrow: true,
  };
  @Input() tabIndex: any = 'auto';
  @Input() noTab = false;
  @Input() hasFocus = false;
  @Input() readonly = false;
  @Input() pattern: any = '';
  @Input() type: 'text' | 'password' | 'email' | 'decimal' = 'text';
  @Input() disabled = false;
  @Input() validateOn: 'blur' | 'change' = 'change';
  @Input() info = '';
  @Input() locked = false;
  @Input() required = false;
  @Input() maxlength = 524288;
  @Input() min = -1000; // For type number
  @Input() max = -1000; // For type number
  @Input() minimumChars = -1;
  @Input() maximumChars = -1;
  @Input() isNumber: boolean = false;
  @Input() rightIcon = '';
  @Input() checkMinChar = false;

  _verifying = false;

  get verifying(): boolean {
    return this._verifying;
  }

  @Input() set verifying(value: boolean) {
    this._verifying = value;
    this.handleIcon();
  }

  _errorLabel = '';

  get errorLabel(): string {
    return this._errorLabel;
  }

  @Input() set errorLabel(value: string) {
    this._errorLabel = value;
    if (value) {
      this.errorMessage = value;
    }
  }

  _error = false;

  get error(): boolean {
    return this._error;
  }

  @Input() set error(value: boolean) {
    this._error = value;
    this.formStates.error = value;
    if (value) {
      this.form.setErrors({incorrect: true});
      this.form.markAsTouched();
    } else {
      this.form.setErrors(null);
    }
  }

  _value: string | number | null | undefined = null;
  get value(): string | number | null | undefined {
    return this._value;
  }

  @Input() set value(value: string | number | null | undefined) {
    this._value = value;
    if (this.form.controls['formValue']) {
      this.form.controls['formValue'].setValue(this.value);
      if (value) {
        this.form.controls['formValue'].markAsDirty();

        // Update the formStates in case the value comes asynchronously
        // and not during the component initialization.
        if (!this.formStates.labelRaised) {
          this.formStates.labelRaised = true;
        }
        if (!this.formStates.valid) {
          this.formStates.valid = true;
        }
      } else {
        this.form.controls['formValue'].markAsPristine();
      }
    }
  }

  @Output() valueCleared: EventEmitter<any> = new EventEmitter<any>();
  @Output() valueChanged: EventEmitter<ValueChangeEvent> = new EventEmitter<ValueChangeEvent>();
  @Output() lostFocus: EventEmitter<ValueChangeEvent> = new EventEmitter<ValueChangeEvent>();
  @Output() formErrors: EventEmitter<ValueChangeEvent> = new EventEmitter<ValueChangeEvent>();
  @Output() focus: EventEmitter<unknown> = new EventEmitter<unknown>();
  valueClearedObservable = this.valueChanged.asObservable();
  valueChangedObservable = this.valueChanged.asObservable();
  lostFocusChangedObservable = this.valueChanged.asObservable();

  constructor(private fb: FormBuilder, public translate: TranslateService) {
  }

  ngOnInit(): void {
    if (this.required) {
      this.formValidators.push(Validators.required);
    }
    if (this.type === 'email') {
      this.formValidators.push(Validators.email);
    }

    if (this.minimumChars > -1) {
      this.formValidators.push(Validators.minLength(this.minimumChars));
    }

    if (this.maximumChars > -1) {
      this.formValidators.push(Validators.maxLength(this.maximumChars));
    }

    if (this.min > -1000) {
      this.formValidators.push(Validators.min(this.min));
    }

    if (this.max > -1000) {
      this.formValidators.push(Validators.max(this.max));
    }

    if (this.isNumber) {
      this.formValidators.push(Validators.pattern(/^\d+$/));
    }

    const validators = this.formValidators;
    this.form = this.fb.group({
      formValue: new FormControl('', {validators, updateOn: (this.validateOn === 'change' ? 'change' : 'blur')})
    });

    if (this.value) {
      this.form.controls['formValue'].setValue(this.value);
      this.form.controls['formValue'].markAsDirty();
      this.formStates.labelRaised = true;
      this.formStates.valid = true;
    } else {
      this.form.controls['formValue'].markAsPristine();
    }

    if (this.locked) {
      this.disabled = true;
      this.formStates.disabled = true;
    }

    if (this.disabled) {
      this.formStates.disabled = true;
      this.tabIndex = -1;
    }

    if (this.hasFocus) {
      this.input.nativeElement.focus();
    }

    this.handleIcon();
    this.formSubscription = this.form.valueChanges.pipe(distinctUntilChanged()).subscribe(() => {
      this.errorMessage = this.errorLabel || this.translate.instant('COMPONENTS.INPUT.ERROR');
      this.formStates.error = false;
      if (this.form.controls['formValue'].errors?.['email']) {
        this.formStates.error = true;
        this.errorMessage = this.translate.instant('COMPONENTS.INPUT.ERROR_INVALID_EMAIL');
      }
      if (this.form.controls['formValue'].errors?.['required']) {
        this.formStates.error = true;
        this.errorMessage = this.translate.instant('COMPONENTS.INPUT.ERROR_REQUIRED');
      }
      if (!this.form.controls['formValue'].value && !this.required) {
        this.form.controls['formValue'].markAsPristine();
      }

      if (this.form.controls['formValue'].errors?.['maxlength']) {
        this.formStates.error = true;
        this.errorMessage = this.translate.instant(
          'COMPONENTS.INPUT.ERROR_TOO_MANY_CHARS',
          {value: new ThousandPipe().transform(this.maximumChars)}
        );
      }

      if (this.checkMinChar && this.form.controls['formValue'].errors?.['minlength']) {
        this.formStates.error = true;
        this.errorMessage = this.translate.instant(
          'COMPONENTS.INPUT.ERROR_TOO_FEW_CHARS',
          {value: new ThousandPipe().transform(this.minimumChars)}
        );
      }

      if (this.form.controls['formValue'].errors?.['min'] && this.min > -1000) {
        this.formStates.error = true;
        this.errorMessage = this.translate.instant(
          'COMPONENTS.INPUT.ERROR_MIN_VALUE',
          {value: new ThousandPipe().transform(this.min)}
        );
      }

      if (this.form.controls['formValue'].errors?.['max'] && this.max > -1000) {
        this.formStates.error = true;
        this.errorMessage = this.translate.instant(
          'COMPONENTS.INPUT.ERROR_MAX_VALUE',
          {value: new ThousandPipe().transform(this.max)}
        );
      }

      if (this.form.controls['formValue'].errors?.['pattern'] && this.isNumber) {
        this.formStates.error = true;
        this.errorMessage = this.translate.instant(
          'COMPONENTS.INPUT.ERROR_NUMBER',
          {value: new ThousandPipe().transform(this.max)}
        );
      }

      this.handleIcon();

      if (this.form.controls['formValue'].errors) {
        this.formErrors.emit({value: this.form.controls['formValue'].errors, status: 'INVALID'})
      } else {
        this.formErrors.emit({value: this.form.controls['formValue'].errors, status: 'VALID'})
      }
      this.valueChanged.emit({value: this.form.controls['formValue'].value, status: this.form.controls['formValue'].status});
    });

    this.statusSubscription = this.form.statusChanges.subscribe(status => {
      this.formStates.valid = status === 'VALID';
      this.handleIcon();
    });
  }

  disableTab(evt: any): void {
    evt.preventDefault();
  }

  togglePasswordVisibility(e: Event): void {
    e.preventDefault();
    e.stopPropagation();
    this.passwordVisible = !this.passwordVisible;
  }

  clear(e: Event): void {
    e.preventDefault();
    e.stopPropagation();
    this.input.nativeElement.value = '';
    this.form.controls['formValue'].setValue('');
    this.form.markAsPristine();
    this.form.markAsUntouched();
    this.handleIcon();
    this.valueCleared.emit();
  }

  /**
   * getFormClasses()
   * @description constructs the form class object based on the element's states
   * @returns string
   */

  getFormClasses(): string {
    const classArray = [];
    for (const [key, value] of Object.entries(this.formStates)) {
      if (value) {
        classArray.push(key);
      }
    }
    return classArray.join(' ');
  }

  handleIcon(): void {
    if (this.verifying) {
      this.displayIcon = '';
    } else {
      if (this.type === 'password') {
        this.displayIcon = 'eye';
      } else {
        if (this.locked) {
          this.displayIcon = 'lock';
        } else {
          if (this.formStates.focused) {
            if (this.form.controls['formValue'].value) {
              this.displayIcon = 'clear';
            } else {
              this.displayIcon = '';
            }
          } else {
            if (this.formStates.valid) {
              this.displayIcon = 'tick';
            } else {
              this.displayIcon = '';
            }
          }
        }
      }
    }
  }

  /**
   * @name onFocus()
   * Handles events when the element gains focus. Copy this over to your form component.
   * labelRaised and focused formStates are necessary. The rest pertains to the icons
   */

  onFocus(): void {
    this.formStates.labelRaised = true;
    this.formStates.focused = true;
    if (this.type !== 'password') {
      if (this.form.controls['formValue'].value) {
        this.displayIcon = 'clear';
      } else {
        this.displayIcon = '';
      }
    } else {
      this.displayIcon = 'eye';
    }
    this.focus.emit();
  }

  /**
   * @name onBlur()
   * Handles events when the element loses focus. Copy this over to your form component.
   * labelRaised and focused formStates are necessary as well as the lostFocus emitted event. The rest pertains to the icons
   */

  onBlur(): void {
    const value = this.form.controls['formValue'].value;
    if (!value) {
      this.formStates.labelRaised = false;
    }
    this.formStates.focused = false;
    if (this.type !== 'password') {
      if (this.formStates.valid && value) {
        this.displayIcon = 'tick';
      } else {
        this.displayIcon = '';
      }
    } else {
      this.displayIcon = 'eye';
    }

    if (this.form.controls['formValue'].errors?.['minlength']) {
      this.formStates.error = true;
      this.errorMessage = this.translate.instant('COMPONENTS.INPUT.ERROR_TOO_FEW_CHARS', {value: new ThousandPipe().transform(this.minimumChars)});
    }

    if (this.form.controls['formValue'].errors?.['maxlength']) {
      this.formStates.error = true;
      this.errorMessage = this.translate.instant('COMPONENTS.INPUT.ERROR_TOO_MANY_CHARS', {value: new ThousandPipe().transform(this.maximumChars)});
    }

    if (this.form.controls['formValue'].errors?.['min'] && this.min > -1000) {
      this.formStates.error = true;
      this.errorMessage = this.translate.instant(
        'COMPONENTS.INPUT.ERROR_MIN_VALUE',
        {value: new ThousandPipe().transform(this.min)}
      );
    }

    if (this.form.controls['formValue'].errors?.['max'] && this.max > -1000) {
      this.formStates.error = true;
      this.errorMessage = this.translate.instant(
        'COMPONENTS.INPUT.ERROR_MAX_VALUE',
        {value: new ThousandPipe().transform(this.max)}
      );
    }
    this.lostFocus.emit({value: this.form.controls['formValue'].value, status: this.form.controls['formValue'].status});
  }

  /**
   * @name ngOnDestroy()
   * Make sure to always unsubscribe your RxJs subscriptions otherwise
   * bad things will happen
   */

  ngOnDestroy(): void {
    if (this.formSubscription) {
      this.formSubscription.unsubscribe();
    }
    if (this.statusSubscription) {
      this.statusSubscription.unsubscribe();
    }
  }

}
