import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {tabAnimation} from '@animations/tab.animations';
import {UtilService} from '@services/util.service';

@Component({
  selector: 'ark-post-user-options',
  templateUrl: './post-user-options.component.html',
  styleUrls: ['./post-user-options.component.scss'],
  animations: [tabAnimation]
})
export class PostUserOptionsComponent implements OnInit {

  @Output() openMobileContextMenu: EventEmitter<any> = new EventEmitter();
  menuActive = false;
  constructor(private util: UtilService) { }

  ngOnInit(): void {
  }

  toggleMenu(): void {
    if (this.util.isMobile()) {
      this.openMobileContextMenu.emit();
    } else {
      this.menuActive = !this.menuActive;
    }
  }
}
