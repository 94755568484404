import {Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter, OnDestroy, HostListener} from '@angular/core';
import { IconImage, IconColor, IconSize } from '@interfaces/common.interface';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'ark-input-expand',
  templateUrl: './input-expand.component.html',
  styleUrls: ['./input-expand.component.scss']
})
export class InputExpandComponent implements OnInit, OnDestroy {

  @ViewChild('input', { static: true })
  input!: ElementRef;

  @Input() label = '';
  @Input() errorLabel = 'Error Message';
  @Input() type = 'text';
  @Input() iconColor = 'confirm';
  @Input() iconSize = 'medium';
  @Input() placeholder = '';
  @Input() disabled = false;

  @Output() valueCleared = new EventEmitter<any>();
  @Output() valueChanged = new EventEmitter<string>();
  @Output() expandChanged = new EventEmitter<boolean>();

  closeIcon: IconImage = 'close';
  closeIconColor: IconColor = '';
  closeIconSize: IconSize = 'tiny';

  expanded = false;

  form: FormGroup = new FormGroup({});
  formSubscription: Subscription = new Subscription();

  constructor(private fb: FormBuilder, private eRef: ElementRef) { }


  ngOnInit(): void {
    this.form = this.fb.group({
      formValue: new FormControl('', [Validators.required, Validators.email])
    });

    this.formSubscription = this.form.valueChanges.pipe(debounceTime(300), distinctUntilChanged()).subscribe((formValue: string) => {
      this.valueChanged.emit(formValue);
    });
  }

  ngOnDestroy(): void {
    if (this.formSubscription) {
      this.formSubscription.unsubscribe();
    }
  }

  toggle(): void {
    this.expanded = !this.expanded;
    if (this.expanded) {
      this.input.nativeElement.focus();
    }
    this.expandChanged.emit(this.expanded);
  }

  clear(e: Event): void {
    e.preventDefault();
    e.stopPropagation();
    this.input.nativeElement.value = '';
    this.form.markAsPristine();
    this.form.markAsUntouched();
    this.valueCleared.emit();
  }

  @HostListener('document:click', ['$event'])
  out(event: any): void {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.expanded = false;
      this.expandChanged.emit(this.expanded);
    }
  }

}
