<div class="proposal-action">
  <div class="proposal-action-steps steps" *ngIf="state === 'first'">
    <small class="proposal-action-step">
      {{'PAGES.CATEGORY_DETAILS.PROPOSALS.ACTION_MODAL.NEW.STEP1.STEP' | translate}}
    </small>
    <h2>{{edit ? ('PAGES.CATEGORY_DETAILS.PROPOSALS.ACTION_MODAL.NEW.STEP1.TITLE.EDIT' | translate) : ('PAGES.CATEGORY_DETAILS.PROPOSALS.ACTION_MODAL.NEW.STEP1.TITLE.CREATE' | translate)}}</h2>
    <div class="proposal-action-label"
         [innerHTML]="'PAGES.CATEGORY_DETAILS.PROPOSALS.ACTION_MODAL.NEW.STEP1.LABEL' | translate"></div>
    <div class="proposal-action-item">
      <ark-input
        [hasFocus]="true"
        [required]="true"
        [value]="form.get('title')?.value"
        [name]="'title'"
        (valueChanged)="formValueChange($event, 'title')"
        [label]="'PAGES.CATEGORY_DETAILS.PROPOSALS.ACTION_MODAL.NEW.STEP1.INPUTS.TITLE.LABEL' | translate"
        [placeholder]="'PAGES.CATEGORY_DETAILS.PROPOSALS.ACTION_MODAL.NEW.STEP1.INPUTS.TITLE.PLACEHOLDER' | translate"
        [rightIcon]="'alert-information'"
        [tippyName]="'title'"
        [toolTip]="'PAGES.CATEGORY_DETAILS.PROPOSALS.ACTION_MODAL.NEW.STEP1.INPUTS.TITLE.TOOLTIP' | translate"
      ></ark-input>
    </div>
    <div class="proposal-action-small">
      {{'PAGES.CATEGORY_DETAILS.PROPOSALS.ACTION_MODAL.NEW.STEP1.SMALL' | translate}}
    </div>
    <hr class="proposal-action-divider">
    <ark-dropdown-selection
      class="proposal-action-categories"
      [value]="selectedCategory"
      [selectedValue]="parentCategories"
      [selectionType]="'checkbox'"
      (selectedOptions)="selectedOptions($event)"
      (selectionChange)="moreSelected($event)"
      [items]="categories"
      [minimum]="1"
      [maximum]="3"
      [info]="'Choose up to 3 Market sectors'"
      [name]="'certificationCategoryId'"
      [label]="'PAGES.CATEGORY_DETAILS.PROPOSALS.ACTION_MODAL.NEW.STEP1.INPUTS.CATEGORY.LABEL' | translate"
    ></ark-dropdown-selection>
    <div class="proposal-action-keywords">
      <ark-dropdown-autocomplete
        [minimum]="1"
        [maximum]="15"
        [name]="'areas'"
        [label]="'PAGES.CATEGORY_DETAILS.PROPOSALS.ACTION_MODAL.NEW.STEP1.INPUTS.AREAS.LABEL' | translate"
        [placeholder]="'PAGES.CATEGORY_DETAILS.PROPOSALS.ACTION_MODAL.NEW.STEP1.INPUTS.AREAS.PLACEHOLDER' | translate"
        [errorMinError]="form.get('areas')?.errors?.['keywordsMinLength'] && areasError"
        [errorMaxError]="form.get('areas')?.errors?.['keywordsMaxLength'] && areasError"
        [keywords]="form.get('areas')?.value ? form.get('areas')?.value : []"
        (keywordsChanged)="onAreasChange($event)"
        (focus)="form.get('areas')?.markAsDirty()"
        [infoText]="'PAGES.CATEGORY_DETAILS.PROPOSALS.ACTION_MODAL.NEW.STEP1.INPUTS.AREAS.INFO' | translate"
      ></ark-dropdown-autocomplete>
    </div>

    <div class="proposal-action-keywords">
      <ark-dropdown-autocomplete
        [minimum]="1"
        [maximum]="15"
        [name]="'tags'"
        [label]="'PAGES.CATEGORY_DETAILS.PROPOSALS.ACTION_MODAL.NEW.STEP1.INPUTS.TAGS.LABEL' | translate"
        [placeholder]="'PAGES.CATEGORY_DETAILS.PROPOSALS.ACTION_MODAL.NEW.STEP1.INPUTS.TAGS.PLACEHOLDER' | translate"
        [errorMinError]="form.get('tags')?.errors?.['keywordsMinLength'] && tagsError"
        [errorMaxError]="form.get('tags')?.errors?.['keywordsMaxLength'] && tagsError"
        [keywords]="form.get('tags')?.value ? form.get('tags')?.value : []"
        (keywordsChanged)="onTagsChange($event)"
        (focus)="form.get('tags')?.markAsDirty()"
        [infoText]="'PAGES.CATEGORY_DETAILS.PROPOSALS.ACTION_MODAL.NEW.STEP1.INPUTS.TAGS.INFO' | translate"
        [rightIcon]="'alert-information'"
        [tippyName]="'tags'"
        [toolTip]="'PAGES.CATEGORY_DETAILS.PROPOSALS.ACTION_MODAL.NEW.STEP1.INPUTS.TAGS.TOOLTIP' | translate"
      ></ark-dropdown-autocomplete>
    </div>

    <div class="bottom-block">
      <div class="cta">
        <ark-button size="medium" type="primary" (click)="next('second')" [disabled]="!checkFirstStep()"
                    rightIcon="right-arrow">
          {{'PAGES.CATEGORY_DETAILS.PROPOSALS.ACTION_MODAL.NEW.STEP1.CTA' | translate}}
        </ark-button>
      </div>
      <div class="clear"></div>
    </div>
  </div>

  <div class="proposal-action-steps steps" *ngIf="state === 'second'">
    <small class="proposal-action-step">
      {{'PAGES.CATEGORY_DETAILS.PROPOSALS.ACTION_MODAL.NEW.STEP4.STEP' | translate}}
    </small>
    <h2>
      {{'PAGES.CATEGORY_DETAILS.PROPOSALS.ACTION_MODAL.NEW.STEP2.TITLE' | translate}}
    </h2>
    <div class="proposal-action-label"
         [innerHTML]="'PAGES.CATEGORY_DETAILS.PROPOSALS.ACTION_MODAL.NEW.STEP2.LABEL' | translate"></div>
    <div class="proposal-action-item">
      <ark-textarea
        [rows]="5"
        [required]="true"
        [name]="'description'"
        [checkMinChar]="true"
        [value]="form.get('description')?.value"
        (valueChanged)="formValueChange($event, 'description')"
        [minimumChars]="minChars"
        [maximumChars]="maxChars"
        [label]="'PAGES.CATEGORY_DETAILS.PROPOSALS.ACTION_MODAL.NEW.STEP2.INPUTS.DESCRIPTION.LABEL' | translate"
        [placeholder]="'PAGES.CATEGORY_DETAILS.PROPOSALS.ACTION_MODAL.NEW.STEP2.INPUTS.DESCRIPTION.PLACEHOLDER' | translate"
      ></ark-textarea>
    </div>
    <div class="proposal-action-item">
      <ark-textarea
        [rows]="5"
        [required]="true"
        [name]="'purpose'"
        [checkMinChar]="true"
        [value]="form.get('purpose')?.value"
        (valueChanged)="formValueChange($event, 'purpose')"
        [minimumChars]="minChars"
        [maximumChars]="maxChars"
        [label]="'PAGES.CATEGORY_DETAILS.PROPOSALS.ACTION_MODAL.NEW.STEP2.INPUTS.PURPOSE.LABEL' | translate"
        [placeholder]="'PAGES.CATEGORY_DETAILS.PROPOSALS.ACTION_MODAL.NEW.STEP2.INPUTS.PURPOSE.PLACEHOLDER' | translate"
      ></ark-textarea>
    </div>
    <div class="proposal-action-item">
      <ark-textarea
        [rows]="5"
        [required]="true"
        [name]="'methodology'"
        [checkMinChar]="true"
        [value]="form.get('methodology')?.value"
        (valueChanged)="formValueChange($event, 'methodology')"
        [minimumChars]="minChars"
        [maximumChars]="maxChars"
        [label]="'PAGES.CATEGORY_DETAILS.PROPOSALS.ACTION_MODAL.NEW.STEP2.INPUTS.METHODOLOGY.LABEL' | translate"
        [placeholder]="'PAGES.CATEGORY_DETAILS.PROPOSALS.ACTION_MODAL.NEW.STEP2.INPUTS.METHODOLOGY.PLACEHOLDER' | translate"
      ></ark-textarea>
    </div>
    <div class="bottom-block">
      <div class="back first" (click)="next('first')">
        <ark-icon class="back-icon" icon="left-arrow" color="dark" size="tiny"></ark-icon>
        {{'PAGES.CATEGORY_DETAILS.PROPOSALS.ACTION_MODAL.NEW.STEP2.BACK' | translate}}
      </div>
      <div class="cta">
        <ark-button size="medium" type="primary" (click)="save()" [disabled]="!checkSecondStep()">
          {{edit ? 'UPDATE PROPOSAL' : 'PAGES.CATEGORY_DETAILS.PROPOSALS.ACTION_MODAL.NEW.STEP4.CTA' | translate}}
        </ark-button>
      </div>
      <div class="clear"></div>
    </div>
  </div>
</div>
