import {Injectable} from '@angular/core';
import {Magic, MagicUserMetadata, RPCError, RPCErrorCode} from 'magic-sdk';
import {AvalancheExtension} from '@magic-ext/avalanche';
import {environment} from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class MagicService {

  private magic;

  constructor() {
    this.magic = new Magic(environment.magicKey, {
      extensions: {
        xchain: new AvalancheExtension({
          rpcUrl: environment.avalancheRpc,
          chainId: environment.avalancheChainId.toString(),
          networkId: 4
        })
      }
    });
    // this.magic = new Magic(environment.magicKey);
  }

  async login(email: string): Promise<any> {
    try {
      const didToken = await this.magic.auth.loginWithMagicLink({email, showUI: false});
      return Promise.resolve(didToken);
    } catch(err) {
      if (err instanceof RPCError) {
        console.log(err);
        return Promise.reject(err);
      }
    }
  }

  async getMetadata(): Promise<MagicUserMetadata> {
    const metadata = await this.magic.user.getMetadata();
    return metadata;
  }

  async getIdToken(): Promise<string> {
    const token = await this.magic.user.getIdToken();
    return token;
  }

  async isLoggedIn(): Promise<boolean> {
    const logged = await this.magic.user.isLoggedIn();
    return logged;
  }
  async logout(): Promise<void> {
    await this.magic.user.logout();
  }
}
