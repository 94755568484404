<div class="proposal-item-dropdown">
  <div class="more-button" *ngIf="item.canEdit || !list">
    <ark-dropdown
      (itemSelected)="moreSelected($event)"
      [items]="list ? moreItems : supportedMoreItems"
      size="xsmall"
      type="icon"
      iconContent="system-more-vertical"
      iconColor="darkTint"
      iconSize="small"
    ></ark-dropdown>
  </div>
</div>

<div class="proposals-item-wrapper" [class.author-color]="item.canEdit" [routerLink]="['/proposal', item.certificationRequestId]">
  <div class="top">
    <div class="left">
      <div class="type">
        <div class="circle"></div>
        <div class="text">LIVE</div>
      </div>
      <div class="date tags" *ngIf="!list">
        <div class="tick"><ark-icon icon="system-tick" size="tiny" color="tint"></ark-icon></div>
        <div class="text">backed {{item.backedDatetime | date: 'mediumDate'}}</div>
      </div>
    </div>
    <div class="right">
      <div class="author-tag tags" *ngIf="item.canEdit">
        <div class="text">By me</div>
      </div>
      <div class="funding" [class.push-margin]="item.canEdit || !list">
        <!-- <ark-icon icon="system-logo-new" size="medium"></ark-icon> -->
        <!-- <span>({{item.fundedPercentage * 100 | number: '1.2-2'}} %)</span> -->
        <h4 class="text">{{item.totalTokens | number: '1.2-2'}}&nbsp;({{item?.pastMonthCredits | number: '1.2-2'}}, last 30 days)</h4>
      </div>
    </div>
  </div>
  <div class="content">
    <h2>{{item.title}}</h2>
    <div class="description">{{item.description}}</div>
  </div>
  <div class="bottom">
    <div class="left">
      <div class="btm-item">
        <ark-icon icon="system-like" size="small"></ark-icon>
        <div class="text">Likes <strong>{{item.likesCount | number}}</strong></div>
      </div>
      <div class="btm-item">
        <ark-icon icon="system-posts" size="small"></ark-icon>
        <div class="text">Comments <strong>{{item.postsCount | number}}</strong></div>
      </div>
      <div class="btm-item">
        <ark-icon icon="navigation-profile" size="small"></ark-icon>
        <div class="text">Supporters <strong>{{item.totalBackers | number}}</strong></div>
      </div>
    </div>
    <div class="right">
      <ark-button type="icon" [size]="'small'" [leftIcon]="'system-right'"  [iconColor]="'dark'"></ark-button>
    </div>
  </div>
</div>
