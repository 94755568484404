import {Injectable} from '@angular/core';
import {StorageService} from './storage.service';
import {Router} from '@angular/router';
import {Storage} from '@constants/storage';
import {AuthModelService} from '@models/auth-model.service';
import {IntercomService} from '@services/intercom.service';
import {BroadcastEvent} from '@interfaces/broadcast.interface';
import {ApiAuthLoginEmailResponse, ApiAuthLoginResponse} from '@interfaces/api.auth.interface';
import {ApiUserResponse} from '@interfaces/api.user.interface';
import {MagicService} from '@services/magic.service';

@Injectable()
export class SessionService {

  constructor(
    private storage: StorageService,
    private auth: AuthModelService,
    private intercom: IntercomService,
    private magic: MagicService,
    private router: Router,
  ) {
  }

  async isLoggedIn(): Promise<any> {
    await this.storage.openDatabase(Storage.SESSION);
    const sessionPerson = await this.storage.store(Storage.SESSION);
    if (sessionPerson) {
      const token = await sessionPerson.get(Storage.SESSION_TOKEN);
      if (token) {
        return Promise.resolve(token);
      } else {
        return Promise.reject(false);
      }
    } else {
      return Promise.reject(false);
    }
  }

  async setUserData(data: ApiUserResponse): Promise<boolean> {
    const store = this.storage.store(Storage.SESSION);
    if (store) {
      await store.set(Storage.USER_DATA, data);
      return Promise.resolve(true);
    } else {
      return Promise.reject();
    }
  }

  async getUserData(): Promise<ApiUserResponse | null> {
    const store = this.storage.store(Storage.SESSION);
    if (store) {
      return await store.get(Storage.USER_DATA);
    } else {
      return null;
    }
  }

  async save(data: ApiAuthLoginResponse | ApiAuthLoginEmailResponse): Promise<boolean> {
    const store = await this.storage.store(Storage.SESSION);
    if (store) {
      await store.set(Storage.SESSION_TOKEN, data.token);
      await store.set(Storage.REFRESH_TOKEN, data.refreshToken);
      this.intercom.broadcast({
        event: BroadcastEvent.USER_LOGGED_IN
      });
      return Promise.resolve(true);
    } else {
      return Promise.reject(false);
    }
  }

  async login(walletId: string): Promise<boolean> {
    return this.auth.login(walletId).then(async (data) => {
      const store = await this.storage.store(Storage.SESSION);
      if (store) {
        await store.set(Storage.SESSION_TOKEN, data.token);
        await store.set(Storage.REFRESH_TOKEN, data.refreshToken);
        await store.set(Storage.SESSION_EXPIRES, data.expires);
        this.intercom.broadcast({
          event: BroadcastEvent.USER_LOGGED_IN
        });
        return Promise.resolve(true);
      } else {
        return Promise.reject(false);
      }
    }).catch(() => {
      return Promise.reject(false);
    });
  }

  async clearSession(): Promise<boolean> {
    const store = await this.storage.store(Storage.SESSION);
    if (store) {
      await store.clear();
      return Promise.resolve(true);
    } else {
      return Promise.reject(false);
    }
  }

  async logout(): Promise<boolean> {
    await this.clearSession();
    await this.magic.logout();
    this.intercom.broadcast({
      event: BroadcastEvent.USER_LOGGED_OUT
    });
    return true;
  }

}
