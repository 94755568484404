<div #wrapper
     class="wrapper"
     [formGroup]="form"
     [ngClass]="getFormClasses()"
     [class.expanded]="expanded"
     (click)="toggle($event)">
  <ark-icon class="search-icon" icon="system-search" color="dark" size="small"></ark-icon>
  <input #input
         class="input"
         (focus)="onFocus()"
         (blur)="onBlur()"
         (keydown.tab)="noTab ? disableTab($event) : null"
         [tabindex]="disabled ? -1 : tabIndex"
         [required]="required"
         [maxlength]="maxlength"
         formControlName="formValue">
  <div class="notch">
    <div class="lead"></div>
    <div class="main start">
      <label>{{label}}</label>
    </div>
    <div class="trail"></div>
  </div>
  <div class="icon" *ngIf="!empty">
    <ark-icon
      [class.expanded]="expanded"
      icon="system-down"
      color="dark"
      size="small"></ark-icon>
  </div>
</div>

<div class="options">
  <ark-dropdown-options
    [type]="selectionType"
    [open]="expanded"
    [options]="filteredOptions"
    [minimum]="minimum"
    (cleared)="clear()"
    (saved)="save($event)"
    (selected)="select($event)"
    (changed)="optionsChanged($event)"
    (closed)="onClosed()"></ark-dropdown-options>
</div>

<div class="error error-bottom" *ngIf="formStates.error" [@dropdownAnimation]>
  <span>{{errorMessage}}</span>
</div>
