import {animate, style, transition, trigger} from '@angular/animations';

export const tabAnimation = trigger('tabAnimation', [
  transition(':enter', [
    style({
      opacity: 0
    }),
    animate('0.3s', style({
      opacity: 1,
    }))
  ]),
  transition(':leave', [
    style({
      opacity: 1
    }),
    animate('0.3s', style({
      opacity: 0
    }))
  ])
]);
