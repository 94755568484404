import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PostComment} from '@interfaces/forum.interface';
import {ApiAccountProfileResponse} from '@interfaces/api.account.interface';

@Component({
  selector: 'ark-post-comments-section',
  templateUrl: './post-comments-section.component.html',
  styleUrls: ['./post-comments-section.component.scss']
})
export class PostCommentsSectionComponent implements OnInit {

  @Input() comments: Array<PostComment> = [];
  @Input() mobile = false;
  @Input() me!: ApiAccountProfileResponse;
  _depth = 0;
  get depth(): number {
    return this._depth;
  }
  @Input() set depth(value: number) {
    this._depth = value;
    this.nextDepth = value - 1;
  }

  @Output() replyToParent: EventEmitter<string> = new EventEmitter<string>();
  @Output() deleteComment: EventEmitter<number> = new EventEmitter<number>();
  @Output() gotoComment: EventEmitter<number> = new EventEmitter<number>();
  @Output() userVote: EventEmitter<any> = new EventEmitter<any>();

  nextDepth = 1;

  constructor() { }

  ngOnInit(): void {

  }

  parentReply(comment: string): void {
    this.replyToParent.emit(comment);
  }

  gotoCommentApply(replyId: number): void {
    this.gotoComment.emit(replyId);
  }

  applyDeleteComment(commentId: number): void {
    this.deleteComment.emit(commentId);
  }

  doVote(data: any): void {
    this.userVote.emit({vote: data.vote, commentId: data.commentId});
  }

}
