import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalComponent } from '@app/components/common/modal/modal.component';
import { InvitationStats } from '@app/etc/interfaces/api.account.interface';
import { ValueChangeEvent } from '@app/etc/interfaces/form.interface';
import { AccountModelService } from '@app/models/account-model.service';

@Component({
  selector: 'ark-send-invitation',
  templateUrl: './send-invitation.component.html',
  styleUrls: ['./send-invitation.component.scss']
})
export class SendInvitationComponent implements OnInit {
  form: FormGroup = this.initForm();
  loading = false;
  invitationStats!: InvitationStats;
  serverErrorMsg: string | null = null;

  constructor( private account: AccountModelService, protected readonly parent: ModalComponent, private fb: FormBuilder) {
    this.parent.closeButton = true;
  }

  async ngOnInit() {
    this.invitationStats = await this.account.invitationStats();
  }



  initForm(): FormGroup {
    return this.fb.group({
      email: [null, [Validators.required, Validators.email]]
    });
  }


  formValueChange(event: ValueChangeEvent, control: string) {
    this.form.get(control)?.setValue(event.value);
    this.form.markAsDirty();
    this.form.updateValueAndValidity();
  }


  validateForm() {
    if (this.form.get('email')?.value === null) {
      this.form.get('email')?.setValue('')
    }

    this.form.updateValueAndValidity();
  }

  submit() {
    this.validateForm();
    this.serverErrorMsg = null;
    if (this.form?.valid && this.form?.dirty) {
      this.loading = true;
      this.account.sendInvitation(this.form.getRawValue()).then(() => {
        this.loading = false;
        this.parent.close('success');
      }).catch((err: {code: string, description: string }) => {
        this.loading = false;
        this.serverErrorMsg = err.description;
      });
    }
  }


  public close(): void {
    this.parent.close();
  }

}
