import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot} from '@angular/router';
import {SessionService} from '@services/session.service';
import {GlobalService} from '@services/global.service';
import {NavigationService} from '@services/navigation.service';
import {IntercomService} from '@services/intercom.service';
import {BroadcastEvent} from '@interfaces/broadcast.interface';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {

  constructor(
    private router: Router,
    private navigation: NavigationService,
    private intercom: IntercomService,
    private session: SessionService,
    private global: GlobalService
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    return this.session.isLoggedIn().then(() => {
      this.intercom.broadcast({event: BroadcastEvent.USER_LOGGED_IN});
      return true;
    }).catch(() => {
      this.intercom.broadcast({event: BroadcastEvent.USER_LOGGED_OUT});
      return this.goToLogin().then(() => false);
    });
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    return this.canActivate(route, state);
  }

  goToLogin(): Promise<any> {
    this.navigation.goTo('/');
    return this.session.logout();
  }
}
