import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HTTP_INTERCEPTORS, HttpClient} from '@angular/common/http';

// Routing
import { AppRoutingModule } from './app-routing.module';

// Components
import { AppComponent } from './app.component';

// Services
import {GlobalService} from '@services/global.service';
import {StorageService} from '@services/storage.service';
import {MetamaskService} from '@services/metamask.service';
import {ApiService} from '@services/api.service';
import {JsonLoaderService} from '@services/jsonloader.service';
import {SessionService} from '@services/session.service';
import {IntercomService} from '@services/intercom.service';

// Misc
import {TokenInterceptor} from '@interceptors/token.interceptor';
import {SharedModule} from '@shared/shared.module';

// 3rd Party
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {InlineSVGModule} from 'ng-inline-svg-2';
import { NavigationComponent } from '@components/common/navigation/navigation.component';
import {ResponsiveService} from '@services/responsive.service';
import {NgClickOutsideModule} from 'ng-click-outside2';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import { ToastrModule } from 'ngx-toastr';
import { MemberSettingsComponent } from '@components/modals/navigation/member-settings/member-settings.component';
import { LoginSuccessComponent } from './components/modals/login/login-success/login-success.component';
import { LoginErrorComponent } from './components/modals/login/login-error/login-error.component';

export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, '../assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    NavigationComponent,
    MemberSettingsComponent,
    LoginSuccessComponent,
    LoginErrorComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    InfiniteScrollModule,
    NgClickOutsideModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    SharedModule,
    InlineSVGModule.forRoot(),
    ToastrModule.forRoot()
  ],
  providers: [
    ApiService,
    GlobalService,
    IntercomService,
    JsonLoaderService,
    ResponsiveService,
    MetamaskService,
    SessionService,
    StorageService,
    {provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true}
  ],
  exports: [
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
