import {Component, OnInit} from '@angular/core';
import {ModalComponent} from '@components/common/modal/modal.component';

@Component({
  selector: 'ark-proposal-action-success',
  templateUrl: './proposal-action-success.component.html',
  styleUrls: ['./proposal-action-success.component.scss']
})
export class ProposalActionSuccessComponent implements OnInit {

  public edit: boolean = false;
  constructor(protected readonly parent: ModalComponent) {
    this.edit = parent.data.edit;
  }

  ngOnInit(): void {
    setTimeout(() => this.parent.close('success'), 2000);
  }
}
