<div class="container">
  <div class="decoration-line {{data.type}}"></div>
  <div class="body">
    <div class="header">
      <h2>{{data.title}}</h2>
      <div class="subtitle"><span *ngIf="data.type === 'paid'">One-time payment</span></div>
    </div>
    <div class="content">
      <h6>You'll get...</h6>
      <div class="get-line">
        <ark-icon icon="system-tick" size="small"></ark-icon>
        <div class="text">
          <ark-icon icon="system-logo-new" size="small"></ark-icon>
          {{data.credits}} credits
        </div>
      </div>
      <div class="get-line">
        <ark-icon icon="system-tick" size="small"></ark-icon>
        <div class="text">A <a (click)="openPopup()">{{data.nft.name}}</a> badge
        </div>
      </div>
      <div class="get-line mid">
        <div class="text no-tick">(<span *ngIf="data.type === 'free'">Unlimited</span>
          <span *ngIf="data.type === 'paid'">{{data.nft.sold}} issued of {{data.nft.total}} available</span>)
        </div>
      </div>
      <div class="get-line" *ngIf="data.multiplier > 0">
        <ark-icon icon="system-tick" size="small"></ark-icon>
        <div class="text multi">x{{data.multiplier}} rewards multiplier
          <ark-icon icon="system-info"
                    size="small"
                    color="disabled"
                    ngxTippy="Earn x{{data.multiplier}} rewards for completing in-app activities"
                    tippyClassName="typo-body-xsmall"
          ></ark-icon>
        </div>
      </div>
    </div>
    <div class="footer">
      <ark-button (click)="purchase()" [loading]="purchasing" [disabled]="ctaDisabled"
                  size="large">{{ctaText}}</ark-button>
    </div>
  </div>
  <div class="ribbon" *ngIf="data.popular"><span>POPULAR</span></div>
  <div class="popup" [delayClickOutsideInit]="true" (clickOutside)="closePopup()" *ngIf="popupVisible" [@fadeDownAnimation]="">
    <div class="nft-image"></div>
    <div class="nft-content">
      <div class="nft-eyebrow">{{data.nft.total}} CREATED</div>
      <h5>{{data.nft.name}} badges</h5>
      <div class="nft-description">{{data.nft.description}}</div>
      <div class="learnMore" (click)="gotoDocs()">
        <span>Learn more</span>
        <ark-icon icon="system-external-link" color="dark" size="small"></ark-icon>
      </div>
    </div>
  </div>
</div>
