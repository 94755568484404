import { Component, OnInit, Input, ViewChild, ElementRef, HostListener } from '@angular/core';
import { dropdownAnimation } from '@animations/dropdown.animations';

@Component({
  selector: 'ark-multi-selection',
  templateUrl: './multi-selection.component.html',
  styleUrls: ['./multi-selection.component.scss'],
  animations: [dropdownAnimation()]
})
export class MultiSelectionComponent implements OnInit {

  @Input() label = 'Label';
  @Input() placeholder = 'Select options';

  @Input() items: any[] = [];
  @Input() selectedItems: any[] = [];
  @Input() displayProperty = 'name';


  @ViewChild('inputRef', { static: true })
  inputRef!: ElementRef;

  @ViewChild('containerRef', { static: true })
  containerRef!: ElementRef;


  @ViewChild('dropdownRef', { static: true })
  dropdownRef!: ElementRef;

  focus = false;
  expanded = false;
  selectedValues: string | undefined;

  constructor() { }

  ngOnInit(): void {
  }

  @HostListener('document:click', ['$event.target'])
  public onClick(target: any): void {
    if (this.expanded) {
      const clickedInsideInput = this.containerRef.nativeElement.contains(target);
      const insideDropdown = this.dropdownRef.nativeElement.contains(target);
      if (insideDropdown) {
        return;
      }

      if (!clickedInsideInput) {
        this.expanded = false;
        this.focus = false;
      }
    }

  }

  containerClick(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
    this.focus = true;
    this.expanded = true;
  }

  toggle(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
    this.expanded = !this.expanded;
  }

  select(selectedItem: any): void {
    const selectedValue = selectedItem[this.displayProperty];

    const itemExists = this.selectedItems.find(e => e.name === selectedValue);
    if (itemExists) {
      const index = this.selectedItems.indexOf(itemExists);
      this.selectedItems.splice(index, 1);
    } else {
      this.selectedItems.push(selectedItem);
    }
    this.selectedValues = this.selectedItems.map(e => e[this.displayProperty]).join(', ');
  }

  isSelected(itemValue: any): boolean {
    return this.selectedItems.find(e => e[this.displayProperty] === itemValue);
  }

  save(): void {
    this.expanded = false;
  }

  cancel(): void {
    this.selectedItems = [];
    this.selectedValues = '';
  }
}
