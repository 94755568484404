import { Injectable, ComponentRef, ComponentFactoryResolver, ApplicationRef, Injector, EmbeddedViewRef, Type } from '@angular/core';
import { ModalComponent } from '@components/common/modal/modal.component';
import {ModalConfig} from '@components/common/modal/modal.config';
import { ModalRef } from '@components/common/modal/modal-ref';
import { ModalInjector } from '@components/common/modal/modal-injector';
import { ModalSize } from '@app/etc/interfaces/common.interface';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  dialogComponentRef!: ComponentRef<ModalComponent>;

  constructor(private componentFactoryResolver: ComponentFactoryResolver, private appRef: ApplicationRef, private injector: Injector) {}

  public open(componentType: Type<any>, config: ModalConfig): ModalRef {
    const dialogRef = this.appendDialogComponentToBody(config);

    this.dialogComponentRef.instance.childComponentType = componentType;

    return dialogRef;
  }

  private appendDialogComponentToBody(config: ModalConfig): ModalRef {
    const map = new WeakMap();
    map.set(ModalConfig, config);

    const dialogRef = new ModalRef();
    map.set(ModalRef, dialogRef);

    const sub = dialogRef.afterClosed.subscribe(() => {
      this.removeDialogComponentFromBody();
      sub.unsubscribe();
    });

    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(ModalComponent);
    const componentRef = componentFactory.create(new ModalInjector(this.injector, map));

    componentRef.instance.size = config.size || ModalSize.DEFAULT;
    this.appRef.attachView(componentRef.hostView);

    const domElem = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
    document.body.appendChild(domElem);

    this.dialogComponentRef = componentRef;

    this.dialogComponentRef.instance.onClose.subscribe(() => {
      this.removeDialogComponentFromBody();
    });

    return dialogRef;
  }

  private removeDialogComponentFromBody(): void {
    this.appRef.detachView(this.dialogComponentRef.hostView);
    this.dialogComponentRef.destroy();
  }
}
