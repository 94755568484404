<div [formGroup]="form" class="wrapper" [ngClass]="getFormClasses()">
    <div #chipInputRef class="chip-input" [class.chip-input-selected]="selectedChips.length > 0">
        <ark-field-chip class="selected-chip" *ngFor="let chip of selectedChips" [label]="chip.name"
            [alwaysSelected]="true" [selected]="true" (chipRemove)="removeChip($event)">
        </ark-field-chip>
        <input #input class="input" (focus)="onFocus()"
        (blur)="onBlur()"
        (keydown.tab)="noTab ? disableTab($event) : null"
        [tabindex]="disabled ? -1 : tabIndex"
        [required]="required"
        [maxlength]="maxlength"
            formControlName="formValue">
    </div>
    <div class="notch">
      <div class="lead"></div>
      <div class="main start">
        <label>{{label | translate}}</label>
      </div>
      <div class="trail"></div>
    </div>
    <div class="icon">
      <ark-icon *ngIf="this.displayIcon === 'clear'" icon="close" size="tiny"
                color="inactive" (mousedown)="clear($event)"></ark-icon>
    </div>
</div>
<div class="container-dropdown">
  <ul class="dropdown" [@dropdownAnimation] *ngIf="filteredChips.length > 0 && expanded">
      <li *ngFor="let item of filteredChips" class="item" (click)="addChip(item)">
          <span>{{item[displayProperty]}}</span>
      </li>
  </ul>
</div>
<div class="hint" *ngIf="minimumChips > 0">
  <span [ngClass]="{'error': selectedChips.length < minimumChips}">{{ hint }}</span>
</div>
<div class="error" *ngIf="formStates.error" [@dropdownAnimation]>
  <span>{{errorMessage}}</span>
</div>
