<div class="wrapper--not-logged--created">
  <h3>
    {{'PAGES.CATEGORY_DETAILS.NOT_LOGGED.TITLE' | translate}}
  </h3>
  <div class="description">
    {{'PAGES.CATEGORY_DETAILS.NOT_LOGGED.DESCRIPTION' | translate}}
  </div>
  <ark-button size="huge" type="primary"  (click)="login()">
    {{'PAGES.CATEGORY_DETAILS.NOT_LOGGED.CTA' | translate}}
  </ark-button>
</div>
