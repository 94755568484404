import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'ark-chip',
  templateUrl: './chip.component.html',
  styleUrls: ['./chip.component.scss']
})
export class ChipComponent implements OnInit {

  @Input() label = '';
  @Input() value!: string;
  @Input() labelTag = 'NEW';
  @Input() isNew = false;
  @Input() selected = false;
  @Input() disabled = false;
  @Input() alwaysSelected = false;
  @Input() cancellable = true;

  @Output() deleteChip: EventEmitter<void> = new EventEmitter<void>();
  @Output() chipSelected = new EventEmitter<{
    selected: boolean,
    value: string
  }>();

  constructor() { }

  ngOnInit(): void {
  }

  select(): void {
    if (this.alwaysSelected) {
      return;
    }
    this.selected = !this.selected;
    this.chipSelected.emit({
      selected: this.selected,
      value: this.value
    });
  }

  onIconClick(e: Event): void {
    this.deleteChip.emit();
  }

}
