<div #wrapper *ngIf="open" class="wrapper" [@dropdownAnimation] [ngSwitch]="type">
    <ng-container *ngSwitchCase="'list'" [ngTemplateOutlet]="list"></ng-container>
    <ng-container *ngSwitchCase="'checkbox'" [ngTemplateOutlet]="checkbox"></ng-container>
</div>


<ng-template #list>
    <ul class="list">
        <li *ngFor="let option of options"
            class="list-item"
            [class.selected]="option.selected"
            (click)="selectOne(option)">
            <span>{{option.name | translate}}</span>
        </li>
    </ul>
</ng-template>


<ng-template #checkbox>
    <ul class="checkbox">
        <div class="options">
            <li class="list-item" *ngFor="let option of options"
                (click)="select(option)"
                [class.selected]="option.selected">
                <span>{{option.name | translate}}</span>
                <ark-icon
                    class="tick"
                    icon="tick"
                    size="small"
                    color="confirm"
                    [class.selected]="option.selected"></ark-icon>
            </li>
        </div>
        <div class="actions">
            <span [class.disabled]="empty" class="label" (click)="clear()">{{ 'COMPONENTS.DROPDOWN.CLEAR' | translate }}</span>
            <ark-button
                size="tiny"
                (click)="save()">{{ 'COMPONENTS.DROPDOWN.SAVE' | translate }}</ark-button>
                <!-- [disabled]="!valid" -->
        </div>
    </ul>
</ng-template>
