import { Component, OnInit } from '@angular/core';
import {ModalComponent} from '@components/common/modal/modal.component';

@Component({
  selector: 'ark-nft-error',
  templateUrl: './nft-error.component.html',
  styleUrls: ['./nft-error.component.scss']
})
export class NftErrorComponent implements OnInit {

  constructor(protected readonly parent: ModalComponent) { }

  ngOnInit(): void {
  }

  close(): void {
    this.parent.close();
  }
}
