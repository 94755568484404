<div class="post-reply-line--wrapper" *ngIf="!selfHidden" [ngClass]="{'comment': type === 'reply', 'mobile': mobile}">
  <ark-identicon size="forum" [address]="avatar || ''"></ark-identicon>
  <!-- ark-avatar [image]="avatar.imageUrl" size="forum" *ngIf="type === 'comment' && !mobile"></ark-avatar -->
  <div class="reply-container {{replyClass}}" [ngClass]="{'disabled': disable}">
    <div class="replyTo-container" *ngIf="replyTo">{{'COMPONENTS.POST_REPLY.REPLYING_TO' | translate}} <a
      href="#">@{{replyTo.firstName}} {{replyTo.lastName}}</a></div>
    <div class="text-container">
    <textarea #area
      (focus)="onFocus()"
      [disabled]="disable"
      (blur)="onBlur()"
      (ngModelChange)="onChange()"
      (keydown)="keyDown($event)"
      [(ngModel)]="content"
      [placeholder]="(type === 'comment' ? 'COMPONENTS.POST_REPLY.ADD_COMMENT' : 'COMPONENTS.POST_REPLY.ADD_REPLY') | translate"
    ></textarea>
      <div class="cta-container" (mousedown)="send($event)">
        <ark-loader-inline color='cta' *ngIf="disable"></ark-loader-inline>
        <span *ngIf="!disable">{{'COMPONENTS.POST_REPLY.SEND' | translate}}</span>
      </div>
    </div>
  </div>
</div>
