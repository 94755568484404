<div class="wrapper--chip" [class.selected]="selected" [class.disabled]="disabled" (click)="select()">
  <span *ngIf="isNew" class="label-tag">{{labelTag}}</span>
  <span class="label">{{label}}</span>
  <!-- <input *ngIf="editMode" type="text" [value]="label"> -->
<!--  <ark-icon (click)="onIconClick($event)" *ngIf="selected && cancellable" class="icon" icon="system-close" size="micro"-->
<!--            [color]="disabled ? 'light' : 'disabled'"></ark-icon>-->

  <ark-icon (click)="onIconClick($event)" *ngIf="selected && cancellable" class="icon" icon="system-close" size="micro"
            [color]="disabled ? 'disabled' : 'cta'"></ark-icon>
</div>
