import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Avatar} from '@interfaces/common.interface';
import {SessionService} from '@services/session.service';
import {dropdownAnimation} from '@animations/dropdown.animations';
import {PostOptionsMenu} from '@constants/option-menus';
import {BroadcastEvent} from '@interfaces/broadcast.interface';
import {IntercomService} from '@services/intercom.service';
import {UtilService} from '@services/util.service';
import {ApiForumCommentsResponse, CertificationTypeId} from '@interfaces/api.forum.interface';
import {ForumModelService} from '@models/forum-model.service';
import {ApiAccountProfileResponse} from '@interfaces/api.account.interface';
import {AccountModelService} from '@models/account-model.service';
import Timeout = NodeJS.Timeout;

@Component({
  selector: 'ark-post-container',
  templateUrl: './post-container.component.html',
  styleUrls: ['./post-container.component.scss'],
  animations: [dropdownAnimation()]
})
export class PostContainerComponent implements OnInit {

  @Input() post!: ApiForumCommentsResponse;
  @Input() certificationType: CertificationTypeId = CertificationTypeId.PROPOSAL;
  replyDisabled = false;
  commentsLoading = false;
  me?: ApiAccountProfileResponse;
  avatar: Avatar = {
    imageUrl: '',
    verified: false
  };
  isMobile = false;
  postOptionsMenu = JSON.parse(JSON.stringify(PostOptionsMenu));
  footerVisible = false;
  contentHidden = false;
  undoTimeout?: Timeout;

  constructor(private session: SessionService,
              private account: AccountModelService,
              private intercom: IntercomService,
              private forum: ForumModelService,
              private router: Router,
              private util: UtilService) {
    this.intercom.on(BroadcastEvent.REPLY_SUBMITTED, (data: any) => { //TODO: Proper interface
      if (data.type === 'post' && data.post && data.post.metadata.id === this.post.metadata.id) {
        this.submitReply(data.message).then();
      }
    });
  }

  async ngOnInit(): Promise<void> {
    this.me = await this.account.profile();
    this.isMobile = window.innerWidth <= 867;
  }

  async submitReply(message: string, reply = false): Promise<void> {
    this.replyDisabled = true;
    const comment = await this.forum.postComment(this.certificationType, this.post.metadata.id, message);
    comment.new = true;
    this.post.metadata.commentsDisplayed++;
    this.post.metadata.commentsTotal++;
    this.post.metadata.commentsExpanded = true;
    if (reply) {
      this.post.comments.push(comment);
    } else {
      this.post.comments.unshift(comment);
    }
    this.replyDisabled = false;
  }

  async applyDeleteComment(commentId: number): Promise<void> {
    await this.forum.deleteComment(commentId);
    this.post.metadata.commentsDisplayed--;
    this.post.metadata.commentsTotal--;
    this.post.comments = this.post.comments.filter(el => el.id !== commentId);
  }

  gotoCommentApply(commentId: number): void {
    for (const comment of this.post.comments) {
      if (comment.id === commentId) {
        const el = document.getElementById(`comment-${commentId}`);
        if (el) {
          el.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'nearest'
          });
          comment.new = true;
          setTimeout(() => {
            comment.new = false;
          }, 5000);
        }
      }
    }
  }

  toggleComments(): void {
    if (this.util.isMobile()) {
      this.intercom.broadcast({
        event: BroadcastEvent.SHOW_POST_COMMENTS,
        data: {
          post: this.post
        }
      });
    } else {
      this.post.metadata.commentsExpanded = !this.post.metadata.commentsExpanded;
    }
  }

  loadMoreComments(): void {
    this.commentsLoading = true;
    // TODO: Implement comment loading
  }

  hideFooterTimeout(): void {
    this.undoTimeout = setTimeout(() => {
      this.footerVisible = false;
    }, 5000);
  }

  footerAction(action: any): void {

  }

  async doVote(data: any): Promise<void> {
    if (data.vote) {
      await this.forum.likeComment(data.commentId);
      for (const comment of this.post.comments) {
        if (comment.id === data.commentId) {
          comment.votes.likes++;
          comment.votes.myVote = 1;
        }
      }
    } else {
      await this.forum.unlikeComment(data.commentId);
      for (const comment of this.post.comments) {
        if (comment.id === data.commentId) {
          comment.votes.likes--;
          comment.votes.myVote = 0;
        }
      }
    }
  }

  async onViewPost(): Promise<void> {
    this.router.navigate([`/chat/edit/${this.post.metadata.id}`]).then();
  }

  triggerMobileReply(): void {
    this.intercom.broadcast({
      event: BroadcastEvent.SHOW_REPLY_LINE,
      data: {
        type: 'post',
        post: this.post
      }
    });
  }
}
