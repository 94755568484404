import {Component, Input, OnInit} from '@angular/core';
import {PostMetaData} from '@interfaces/forum.interface';
import {ModalSize, OptionsMenu, OptionsMenuItemIdentifier} from '@interfaces/common.interface';
import {ModalService} from '@services/modal.service';
import {PostHideComponent} from '@app/components/modals/forum/post-hide/post-hide.component';
import {ModalConfig} from '@components/common/modal/modal.config';
import {IntercomService} from '@services/intercom.service';
import {BroadcastEvent} from '@interfaces/broadcast.interface';

@Component({
  selector: 'ark-post-container-header',
  templateUrl: './post-container-header.component.html',
  styleUrls: ['./post-container-header.component.scss']
})
export class PostContainerHeaderComponent implements OnInit {

  @Input() metadata!: PostMetaData;
  @Input() menu!: OptionsMenu;

  modalConfig: ModalConfig = {
    size: ModalSize.DYNAMIC,
    style: 'dark'
  };

  constructor(private modal: ModalService,  private intercom: IntercomService) {
    this.intercom.on(BroadcastEvent.DRAWER_ACTION, (data: any) => { //TODO: Proper interface
      if (this.metadata.id === data.post.id) {
        this.itemClicked(data.action);
      }
    });
  }

  ngOnInit(): void {
  }

  openMobileContextMenu(): void {
    this.intercom.broadcast({
      event: BroadcastEvent.SHOW_DRAWER,
      data: {
        menu: this.menu,
        metadata: this.metadata
      }
    });
  }

  itemClicked(item: OptionsMenuItemIdentifier): void {
    switch (item) {
      case 'post.hide':
        this.modal.open(PostHideComponent, this.modalConfig);
        break;
    }
  }
}
