<div [formGroup]="form" class="wrapper--input">
    <ark-icon class="search-icon" icon="search" color="dark" size="small"></ark-icon>
    <input #input class="input" [class.error]="form.dirty && form.invalid" [type]="type" [placeholder]="placeholder"
        formControlName="formValue" required>
    <label [class.input-error-label]="form.dirty && form.invalid">{{label}}</label>
    <ark-icon *ngIf="form?.valid" class="input-icon-tick" [icon]="tickIcon" [size]="tickIconSize"
        [color]="tickIconColor"></ark-icon>
    <ark-icon class="input-icon-close" [icon]="closeIcon" [size]="closeIconSize" [color]="closeIconColor"
        (mousedown)="clear($event)"></ark-icon>
</div>
<div class="wrapper--input-error" *ngIf="form.dirty && form.invalid && form.touched">
    <span class="input-error-label">{{errorLabel}}</span>
</div>