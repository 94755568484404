<div class="wrapper-survey-action">
  <h2>{{edit? 'Update' : 'Create'}} survey</h2>
  <div class="form">
  <ark-input
  [required]="true"
  [value]="form.get('title')?.value"
  [label]="'Title'"
  (valueChanged)="formValueChange($event, 'title')"
></ark-input>
<ark-input
[required]="true"
[value]="form.get('creditsGiven')?.value"
[label]="'Credits given'"
[isNumber]="true"
(valueChanged)="formValueChange($event, 'creditsGiven')"
></ark-input>
<ark-input
[required]="true"
[value]="form.get('typeformUrl')?.value"
[label]="'Typeform URL'"
info="(ex: https://mzwnbuke7he.typeform.com/to/{form_id}#user_id=xxxxx)"
(valueChanged)="formValueChange($event, 'typeformUrl')"
></ark-input>
<div class="error-msg" *ngIf="serverErrorMsg"><ark-icon icon="alert-error" size="small" color="error"></ark-icon> <p class="text">{{serverErrorMsg}}</p></div>
</div>

  <div class="actions">
    <ark-button [link]="true" type="secondary" (click)="close()">
      Cancel
    </ark-button>
    <ark-button (click)="submit()" [loading]="loading">
      {{edit? 'UPDATE' : 'CREATE'}}
    </ark-button>
  </div>
</div>
