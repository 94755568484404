import {Injectable} from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import {StorageService} from '@services/storage.service';
import {SessionService} from '@services/session.service';
import {Storage} from '@constants/storage';
import {BehaviorSubject, from, Observable, throwError} from 'rxjs';
import {catchError, filter, mergeMap, retry, switchMap, take} from 'rxjs/operators';
import {ApiService} from '@services/api.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private storage: StorageService,
    private session: SessionService,
    private api: ApiService
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.storage.dbPromise) {
      const store = this.storage.store(Storage.SESSION);
      if (store) {
        return from(store.get(Storage.SESSION_TOKEN))
          .pipe(
            mergeMap(data => {
              if (data) {
                request = this.addHeaders(request, data);
                return next.handle(request).pipe(catchError(error => {
                    if (error instanceof HttpErrorResponse && (error.status === 401 || error.status === 0) ) {
                      /* if (error.error.msg === 'invalid refresh token') {
                        return throwError(error);
                      } */
                      return this.handle401Error(request, next);
                    } else {
                      return throwError(error);
                    }
                  })
                );
              } else {
                request = this.addHeaders(request, null);
                return next.handle(request).pipe(
                  retry(1)
                );
              }
            }),
            catchError(err => throwError(err))
            );
      } else {
        request = this.addHeaders(request, null);
        return next.handle(request).pipe(
          retry(1)
        );
      }
    } else {
      request = this.addHeaders(request, null);
      return next.handle(request).pipe(
        retry(1)
      );
    }
  }

  addHeaders(request: any, data: any): any {
    if (data) {
      if (request.body instanceof Blob) {
        request = request.clone({
          setHeaders: {
            Accept: 'application/json, text/plain, */*'
          }
        });
      } else {
        request = this.addAuthHeader(request, data);
      }
    } else {
      if (request.body instanceof FormData) {
        request = request.clone({
          setHeaders: {
            Accept: 'application/json, text/plain, */*',
          }
        });
      } else {
        request = request.clone({
          setHeaders: {
            Accept: 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          }
        });
      }
    }
    return request;
  }

  addAuthHeader(request: any, data: any): any {
    if (request.body instanceof FormData) {
      return request.clone({
        setHeaders: {
          Authorization: `Bearer ${data}`,
          Accept: 'application/json, text/plain, */*'
        }
      });
    } else {
      return request.clone({
        setHeaders: {
          Authorization: `Bearer ${data}`,
          Accept: 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        }
      });
    }
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);
      const store = this.storage.store(Storage.SESSION);
      if (store) {
        return from(store.get(Storage.REFRESH_TOKEN))
          .pipe(
            mergeMap(refreshToken => {
              if (refreshToken) {
                return this.api.auth.refreshToken.get(refreshToken).pipe(
                  switchMap((result: any) => {
                    this.isRefreshing = false;
                    this.refreshTokenSubject.next(result.token);
                    this.session.save(result).then();
                    return next.handle(this.addHeaders(request, result.token));
                  }),
                  catchError(err => {
                    this.isRefreshing = false;
                    this.session.logout().then();
                    return throwError(err);
                  })
                );
              } else {
                return from(this.session.logout());
              }
            }),
            catchError(err => {
              this.isRefreshing = false;
              this.session.logout().then();
              return throwError(err);
            })
          );
      } else {
        return this.refreshTokenSubject.pipe(
          filter(token => token != null),
          take(1),
          switchMap(sessionToken => {
            return next.handle(this.addHeaders(request, sessionToken));
          })
        );
      }
    } else {
      return this.refreshTokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(sessionToken => {
          return next.handle(this.addHeaders(request, sessionToken));
        })
      );
    }
  }

}
