import { Component, OnInit } from '@angular/core';
import { ModalComponent } from '@app/components/common/modal/modal.component';

@Component({
  selector: 'ark-post-delete',
  templateUrl: './post-delete.component.html',
  styleUrls: ['./post-delete.component.scss']
})
export class PostDeleteComponent implements OnInit {

  constructor(private parent: ModalComponent) { }

  ngOnInit(): void {
  }

  submit(): void {
    this.parent.close();
  }
  cancel(): void {
    this.parent.close();
  }
}
