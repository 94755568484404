<div [formGroup]="form" class="wrapper" [ngClass]="getFormClasses()">
  <textarea #input
            class="input"
            [cols]="columns"
            [rows]="rows"
            (focus)="onFocus()"
            (blur)="onBlur()"
            (keydown.tab)="noTab ? disableTab($event) : null"
            [tabindex]="disabled ? -1 : tabIndex"
            [required]="required"
            [maxlength]="maxlength"
            [placeholder]="formStates.labelRaised ? placeholder : ''"
            formControlName="formValue"></textarea>
  <div class="notch">
    <div class="lead"></div>
    <div class="main start">
      <label>{{label}}</label>
    </div>
    <div class="trail"></div>
  </div>
</div>
<div class="info" *ngIf="info">
  <span>{{info}}</span>
</div>
<div class="hint">
  <span [ngClass]="{'error': minimumChars && charsTyped < minimumChars}" *ngIf="minimumChars > -1 && charsTyped === 0">
    {{ 'ON_BOARDING.PROFILE.BIO.MINIMUM_CHARS' | translate: {'value': minimumChars | thousand} }}
    </span>
  <span [ngClass]="{'error': maximumChars && charsTyped > maximumChars}" *ngIf="maximumChars > -1 && charsTyped > 0">{{ charsTyped | thousand }}
    / {{maximumChars | thousand}}</span>
</div>
<div class="error" *ngIf="formStates.error" [@dropdownAnimation]>
  <span>{{errorMessage}}</span>
</div>
