import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'ark-field-chip',
  templateUrl: './field-chip.component.html',
  styleUrls: ['./field-chip.component.scss']
})
export class FieldChipComponent implements OnInit {

  @Input() label = 'Label';
  @Input() labelTag = 'NEW';
  @Input() isNew = false;
  @Input() selected = false;
  @Input() disabled = false;
  @Input() alwaysSelected = false;

  @Output() chipSelected = new EventEmitter<{
    selected: boolean,
    value: string
  }>();
  @Output() chipRemove = new EventEmitter<{
    value: string
  }>();

  constructor() { }

  ngOnInit(): void {
  }

  select(): void {
    if (this.alwaysSelected) {
      return;
    }
    this.selected = !this.selected;
    this.chipSelected.emit({
      selected: this.selected,
      value: this.label
    });
  }

  remove(): void {
    this.chipRemove.emit({
      value: this.label
    });
  }

}
