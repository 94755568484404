import { Component, OnInit } from '@angular/core';
import { ModalComponent } from '@components/common/modal/modal.component';

@Component({
  selector: 'ark-post-delete-comment',
  templateUrl: './post-delete-comment.component.html',
  styleUrls: ['./post-delete-comment.component.scss']
})
export class PostDeleteCommentComponent implements OnInit {

  constructor(private parent: ModalComponent) { }

  ngOnInit(): void {
  }

  submit(): void {
    this.parent.close(true);
  }
  cancel(): void {
    this.parent.close(false);
  }
}
