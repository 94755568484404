import { Component, OnInit } from '@angular/core';
import {ModalComponent} from '@components/common/modal/modal.component';
import {IntercomService} from '@services/intercom.service';
import {BroadcastEvent} from '@interfaces/broadcast.interface';

@Component({
  selector: 'ark-proposal-backing-removed',
  templateUrl: './proposal-backing-removed.component.html',
  styleUrls: ['./proposal-backing-removed.component.scss']
})
export class ProposalBackingRemovedComponent implements OnInit {

  constructor(protected readonly parent: ModalComponent, private intercom: IntercomService,) { }

  ngOnInit(): void {
    this.intercom.broadcast({
      event: BroadcastEvent.BACKED,
    });
    setTimeout(() => {
      this.parent.close();
    }, 1800);
  }
}
