import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ark-profile-edit-success',
  templateUrl: './profile-edit-success.component.html',
  styleUrls: ['./profile-edit-success.component.scss']
})
export class ProfileEditSuccessComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
