import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';


@Component({
  selector: 'ark-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.mobile.scss', './search.component.scss']
})
export class SearchComponent implements OnInit, OnDestroy {
@Input() placeholder!: string;
@Output() search = new EventEmitter<string>();
searchControl = new FormControl();
controlSubscription: Subscription = new Subscription();

  constructor() { }

  ngOnInit(): void {
   this.controlSubscription = this.searchControl.valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged()
    ).subscribe(query => {
      const emittedQuery = query ? query : null;
      this.search.emit(emittedQuery);
    });
  }

  ngOnDestroy(): void {
    if (this.controlSubscription) {
      this.controlSubscription.unsubscribe();
    }
  }

}
