import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'ark-vote',
  templateUrl: './vote.component.html',
  styleUrls: ['./vote.component.scss']
})
export class VoteComponent implements OnInit {
  @Input() direction: 'positive' | 'negative' = 'positive';
  @Input() votes: number | undefined = 0;
  @Input() voted = false;
  @Input() entity: 'post' | 'comment' = 'post';
  @Input() id = '';
  @Output() userVote: EventEmitter<boolean> = new EventEmitter();
  icon = '';
  constructor() { }

  ngOnInit(): void {
    this.icon = this.direction === 'positive' ? 'system-like' : 'system-dislike';
  }

  vote() {
    this.userVote.emit(!this.voted);
  }

}
