import {Component, ElementRef, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PostComment} from '@interfaces/forum.interface';
import {SessionService} from '@services/session.service';
import * as luxon from 'luxon';
import {CommentsOptionsMenu} from '@constants/option-menus';
import {OptionsMenuItemIdentifier} from '@interfaces/common.interface';
import {IntercomService} from '@services/intercom.service';
import {BroadcastEvent} from '@interfaces/broadcast.interface';
import {ApiForumCommentAddRequest} from '@interfaces/api.forum.interface';
import {ApiAccountProfileResponse} from '@interfaces/api.account.interface';

const dateTime = luxon.DateTime;

@Component({
  selector: 'ark-post-comment',
  templateUrl: './post-comment.component.html',
  styleUrls: ['./post-comment.component.scss']
})
export class PostCommentComponent implements OnInit {

  @Input() comment!: PostComment;
  @Input() comments!: Array<PostComment>;
  @Input() mobile = false;
  @Input() me!: ApiAccountProfileResponse;
  @Input() depth = 1;
  @Output() replyToParent: EventEmitter<string> = new EventEmitter<string>();
  @Output() deleteComment: EventEmitter<number> = new EventEmitter<number>();
  @Output() gotoComment: EventEmitter<number> = new EventEmitter<number>();
  @Output() userVote: EventEmitter<any> = new EventEmitter<any>();

  replyVisible = false;
  replyDisabled = false;
  commentsLoading = false;
  replyId = 0;
  commentsOptionsMenu = JSON.parse(JSON.stringify(CommentsOptionsMenu));

  constructor(private session: SessionService,
              private elRef: ElementRef,
              private intercom: IntercomService) {
    this.intercom.on(BroadcastEvent.REPLY_SUBMITTED, (data: any) => { //TODO: Proper interface
      this.replyVisible = false;
      if (data.type === 'comment' && data.comment && data.comment.id === this.comment.id) {
        if (!this.mobile) {
          this.submitReply(data.message);
        }
      }
    });
  }

  async ngOnInit(): Promise<void> {
    if (this.comment.body.substr(0, 4) === '[::@') {
      const bodyParts = this.comment.body.split('::]');
      const nameParts = bodyParts[0].split('::');
      const username = nameParts[1];
      this.replyId = parseInt(nameParts[2]);
      const body = bodyParts[1];

      this.comment.body = `<span class="atMention">${username}</span> ${body}`;

    }
    for (const item of this.commentsOptionsMenu.items) {
      switch (item.identifier) {
        //case OptionsMenuItemIdentifier.COMMENT_EDIT:
        case OptionsMenuItemIdentifier.COMMENT_DELETE:
          item.visible = this.comment.author && this.comment.author.walletId === `${this.me.walletId}`;
          break;
      }
    }

  }

  ngAfterViewInit(): void {
    const mention = this.elRef.nativeElement.querySelector('.atMention');
    if (mention) {
      mention.addEventListener('click', (e: any) => {
        this.gotoComment.emit(this.replyId);
      });
    }
  }

  doVote(vote: boolean): void {
    this.userVote.emit({vote, commentId: this.comment.id});
  }

  submitReply(message: string): void {
    this.replyDisabled = true;
    setTimeout(() => {
      /* if (this.depth > -1) {
        this.addReply(comment);
      } else {
        this.replyToParent.emit(comment);
      } */
      this.replyToParent.emit(message);
      this.replyDisabled = false;
      this.replyVisible = false;
    }, 200);
  }

  applyDeleteComment(): void {
    this.deleteComment.emit(this.comment.id);
  }

  addReply(comment: string, atMention = false): void {
    this.comment.commentsExpanded = true;
    if (!this.comment.comments) {
      this.comment.comments = [];
    }
    if (atMention) {
      comment = `[::@${this.comment.author.firstName} ${this.comment.author.lastName}::${this.comment.id}::] ${comment}`;
    }
    this.intercom.broadcast({
      event: BroadcastEvent.HIDE_REPLY_LINE
    });
    this.submitReply(comment);
  }

  loadMoreComments(): void {
    this.commentsLoading = true;
  }

  toggleReplyLine() {
    this.replyVisible = !this.replyVisible;

    this.intercom.broadcast({
      event: this.replyVisible ? BroadcastEvent.SHOW_REPLY_LINE : BroadcastEvent.HIDE_REPLY_LINE
    });
  }


  triggerMobileReply(): void {
    this.intercom.broadcast({
      event: BroadcastEvent.SHOW_REPLY_LINE,
      data: {
        type: 'comment',
        post: this.comment
      }
    });
  }
}
