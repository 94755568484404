import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ChipGroupDirection, RadioChipGroupOptions, ValueChangeEvent } from '@interfaces/form.interface';

@Component({
  selector: 'ark-chip-group',
  templateUrl: './chip-group.component.html',
  styleUrls: ['./chip-group.component.scss']
})
export class ChipGroupComponent implements OnInit {

  _options: RadioChipGroupOptions = {
    items: []
  };

  get options(): RadioChipGroupOptions {
    return this._options;
  }

  @Input() set options(value: RadioChipGroupOptions) {
    this._options = value;
    this.checkedValue = this.options.items.filter(el => el.checked).map( el => el.value)[0] || null;
  }

  @Input() direction: ChipGroupDirection = 'row';
  @Output() valueChanged: EventEmitter<ValueChangeEvent> = new EventEmitter<ValueChangeEvent>();

  checkedValue: any = null;

  constructor() { }

  ngOnInit(): void {

  }

  selectOption(value: any): void {
    this.checkedValue = value === this.checkedValue ? null : value;
    this.valueChanged.emit({value: this.checkedValue, status: 'VALID'});
  }


}
