<div class="products-action">
  <div class="products-action-slider">
    <h2>{{back ? ('PAGES.CATEGORY_DETAILS.PROPOSALS.ACTION_MODAL.NEW.BACKING.BACK.TITLE' | translate) : ('PAGES.CATEGORY_DETAILS.PROPOSALS.ACTION_MODAL.NEW.BACKING.EDIT.TITLE' | translate)}}</h2>
    <div class="products-action-slider-balance" *ngIf="!back">
      {{'PAGES.CATEGORY_DETAILS.PROPOSALS.ACTION_MODAL.NEW.BACKING.EDIT.BALANCE' | translate}}
      &nbsp;&nbsp;&nbsp;&nbsp;
      <span>{{ +item.tokensBackedByMember.toFixed(2)}}</span>
    </div>
    <div class="products-action-slider-balance">
      {{'PAGES.CATEGORY_DETAILS.PROPOSALS.ACTION_MODAL.NEW.BACKING.BACK.BALANCE' | translate}}
      <div><ark-icon icon="display"></ark-icon></div>
      <span>{{(+balance).toFixed(2) }}</span>
    </div>
    <div class="products-action-slider-content">
      {{'PAGES.CATEGORY_DETAILS.PROPOSALS.ACTION_MODAL.NEW.BACKING.CONTENT' | translate}}
    </div>
    <div class="products-action-slider-extra">
      {{'PAGES.CATEGORY_DETAILS.PROPOSALS.ACTION_MODAL.NEW.BACKING.EXTRA' | translate}}
    </div>
    <div class="products-action-slider-action">
      <ark-slider  [current]="(token || 0) * 100" (dragEnd)="tokenCalculate($event)" [textHidden]="true"></ark-slider>
      <div class="products-action-slider-action-percentage">
        <ark-icon icon="display"></ark-icon>
        <ark-slider-display
          [value]="getToken()"
          (valueChanged)="inputTokenChanged($event)"
          (lostFocus)="setToken($event)"
          (keyUpEnter)="setToken($event)"
          (keyUpTab)="setToken($event)"
          [maxValue]="getBalance()"
        ></ark-slider-display>
      </div>
    </div>
  </div>
  <div class="back-controls">
<!--    <div class="back-controls-remove" *ngIf="!back">-->
<!--      <div class="delete" (click)="remove()">-->
<!--        <ark-icon icon="system-trash" color="error" size="small"></ark-icon>-->
<!--        <span>{{'PAGES.CATEGORY_DETAILS.PROPOSALS.ACTION_MODAL.NEW.BACKING.EDIT.REMOVE' | translate}}</span>-->
<!--      </div>-->
<!--    </div>-->
    <span (click)="close('confirm')">{{'PAGES.CATEGORY_DETAILS.PROPOSALS.ACTION_MODAL.NEW.BACKING.CANCEL' | translate}}</span>
    <ark-button size="large" type="primary"  (click)="save()">{{'PAGES.CATEGORY_DETAILS.PROPOSALS.ACTION_MODAL.NEW.BACKING.CTA' | translate}}</ark-button>
  </div>
</div>
