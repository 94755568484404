<div class="discussion-form-wrapper">
  <div class="page-body">
    <h3>{{edit ? ('PAGES.CATEGORY_DETAILS.DISCUSSION.FORM.HEADER_UPDATE' | translate) : ('PAGES.CATEGORY_DETAILS.DISCUSSION.FORM.HEADER_CREATE' | translate)}}</h3>
    <span class="discussion-form-label">
      {{ "PAGES.CATEGORY_DETAILS.DISCUSSION.FORM.LABEL" | translate }}
    </span>
    <div class="form">
      <ark-input
        [required]="true"
        [value]="form.get('title')?.value"
        [name]="'title'"
        [label]="'PAGES.CATEGORY_DETAILS.DISCUSSION.FORM.TITLE' | translate"
        [placeholder]="'PAGES.CATEGORY_DETAILS.DISCUSSION.FORM.TITLE' | translate"
        (valueChanged)="formValueChange($event, 'title')"
      ></ark-input>
      <ark-dropdown-selection
        [value]="selectedCategory"
        [selectedValue]="parentCategories"
        [selectionType]="'checkbox'"
        (selectionChange)="moreSelected($event)"
        (selectedOptions)="selectedOptions($event)"
        [label]="'PAGES.CATEGORY_DETAILS.DISCUSSION.FORM.CATEGORY' | translate"
        [name]="'certificationCategoryId'"
        [hintLeft]="'Select a sector for your new idea.'"
        [info]="'Choose up to 3 market sectors'"
        [minimum]="1"
        [maximum]="3"
        [items]="categories"
      ></ark-dropdown-selection>
      <!-- <small class="discussion-form-small-text">
        {{ "PAGES.CATEGORY_DETAILS.DISCUSSION.FORM.CATEGORY_LABEL" | translate }}
      </small> -->
      <ark-textarea
        [rows]="6"
        [required]="true"
        [minimumChars]="minChars"
        [maximumChars]="maxChars"
        [name]="'description'"
        [checkMinChar]="true"
        [value]="form.get('description')?.value"
        [label]="'PAGES.CATEGORY_DETAILS.DISCUSSION.FORM.DESCRIPTION' | translate"
        [placeholder]="'PAGES.CATEGORY_DETAILS.DISCUSSION.FORM.DESCRIPTION' | translate"
        (valueChanged)="formValueChange($event, 'description')"
      ></ark-textarea>
      <div class="form-actions">
<!--        <ark-button [link]="true" type="secondary" (click)="close('confirm')">-->
<!--          {{ "PAGES.CATEGORY_DETAILS.DISCUSSION.FORM.CANCEL" | translate }}-->
<!--        </ark-button>-->
        <ark-button (click)="submit()" [loading]="busy" [disabled]="!checkStep()">
          {{edit ? ('PAGES.CATEGORY_DETAILS.DISCUSSION.FORM.CTA_UPDATE' | translate) : ('PAGES.CATEGORY_DETAILS.DISCUSSION.FORM.CTA' | translate)}}
        </ark-button>
      </div>
      <div class="error" *ngIf="serverError">{{ "PAGES.CATEGORY_DETAILS.DISCUSSION.FORM.SERVER_ERROR" | translate }}</div>
    </div>
  </div>
</div>
