<div class="wrapper--button {{type}} {{size}}" [class.disabled]="disabled" [ngClass]="{'link': link}">
  <ng-container *ngIf="!loading">
    <ark-icon *ngIf="leftIcon" [icon]="leftIcon" [size]="iconSize" [color]="iconColor" class="left-icon"></ark-icon>
    <div class="label">
      <ng-content></ng-content>
    </div>
    <ark-icon *ngIf="rightIcon" [icon]="rightIcon" [size]="iconSize" [color]="iconColor"></ark-icon>
  </ng-container>
  <ng-container *ngIf="loading">
    <ark-loader-inline [color]="loaderColor" class="loader"></ark-loader-inline>
  </ng-container>
</div>
