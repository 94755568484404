<div class="settings-form-wrapper">
  <h2 class="h2">Please select your <br> notification settings:</h2>

  <div class="form">
    <div class="settings-block">
      <ark-checkbox class="settings-checkbox"
                    [selected]="settings.emailNotifications"
                    (valueChanged)="emailChanged($event)"
                    [name]="'emailNotifications'"
      ></ark-checkbox>
      <span class="settings-content">Emails</span>
    </div>
    <div class="settings-block">
      <ark-checkbox class="settings-checkbox"
                    [selected]="settings.appNotifications"
                    (valueChanged)="appChanged($event)"
                    [name]="'appNotifications'"
      ></ark-checkbox>
      <span class="settings-content">In app notifications</span>
    </div>
    <div class="settings-block">
      <ark-checkbox class="settings-checkbox"
                    [selected]="settings.promotions"
                    (valueChanged)="promotionChanged($event)"
                    [name]="'promotions'"
      ></ark-checkbox>
      <span class="settings-content">Promotions</span>
    </div>

    <div class="error-msg" *ngIf="serverErrorMsg">
      <ark-icon icon="alert-error" size="small" color="error"></ark-icon>
      <p class="text">{{this.serverErrorMsg}}</p></div>
  </div>
  <div class="actions">
    <ark-button (click)="submit()" [loading]="loading">
      SAVE
    </ark-button>
  </div>
</div>
