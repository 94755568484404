<div class="post-publish--header" *ngIf="isMobile">
  <ark-icon class="icon" icon="system-left" size="small" (click)="onCancel()"></ark-icon>
  <h5 class="title">Publish</h5>
</div>
<div class="post-publish--body">
  <div class="left-column">
    <h3 class="label">{{ 'MODALS.POST_COVER_SELECTION.LEFT_LABEL' | translate}}</h3>
    <div class="img-selection" *ngIf="!imgFilePath" (click)="openSelectFile()">
      <div class="plus-icon">
        <ark-icon icon="system-plus" size="small" color="cta"></ark-icon>
      </div>
      <div class="text">{{'MODALS.POST_COVER_SELECTION.IMG_SELECTION' | translate}}</div>
      <input #fileButton (change)="fileChangeEvent($event)" id="file-input" type="file" name="name"
      style="display: none;"/>
    </div>
    <div class="img-display" *ngIf="imgFilePath">
      <img [src]="imgFilePath" [alt]="form.get('converImage')?.get('alt')?.value || 'COMMON.DEFAULT_ALT' | translate">
      <ark-button type="icon" size="tiny" leftIcon="system-trash" iconSize="small" iconColor="dark" (click)="removeFile()"></ark-button>
    </div>
    <div class="note" [innerHTML]="'MODALS.POST_COVER_SELECTION.NOTE' | translate"></div>
  </div>
  <div class="right-column">
    <h3>{{ 'MODALS.POST_COVER_SELECTION.RIGHT_LABEL' | translate}}</h3>
    <div class="description">{{ 'MODALS.POST_COVER_SELECTION.CATEGORY_SELECTION_DESCR' | translate}}</div>
    <div class="category-selection-info">
      <span *ngIf="validCategories">{{ 'MODALS.POST_COVER_SELECTION.MINIMUM_CATEGORY' | translate: {'value' : 1}
        }}</span>
      <span class="error" *ngIf="!validCategories">{{ 'MODALS.POST_COVER_SELECTION.CATEGORY_ERROR_LABEL' | translate:
        {'value' : 1} }}</span>
    </div>
    <div class="description">{{ 'MODALS.POST_COVER_SELECTION.VALUES_SELECTION_DESCR' | translate}}</div>
    <div class="values-selection">
      <ark-chip-selection [label]="'MODALS.POST_COVER_SELECTION.SEARCH_VALUES'" [selectedChips]="form?.value?.values"
        (updated)="updateSelectedChips($event)" [hint]="'MODALS.POST_COVER_SELECTION.MINIMUM_VALUES' | translate: {'value' : 1}" [error]="chipError"
        [errorLabel]="'MODALS.POST_COVER_SELECTION.VALUES_ERROR_LABEL' | translate: {'value' : 1}" [minimumChips]="1"
        [chips]="[{'name': 'Arkius'}, {'name': 'Value'}, {'name': 'Genome'}, {'name': 'Library'}]">
      </ark-chip-selection>
    </div>

  </div>
</div>
<div class="post-publish--footer">
  <div class="cancel" (click)="onCancel()">{{ 'MODALS.POST_COVER_SELECTION.CANCEL' | translate}}</div>
  <ark-button rightIcon="message-send" (click)="onPublish($event)">{{ 'MODALS.POST_COVER_SELECTION.PUBLISH' | translate}}
  </ark-button>
</div>
