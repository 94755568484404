import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot} from '@angular/router';
import {SessionService} from '@services/session.service';
import {GlobalService} from '@services/global.service';
import {NavigationService} from '@services/navigation.service';

@Injectable({
  providedIn: 'root'
})
export class NologinGuard implements CanActivate, CanActivateChild {

  constructor(
    private router: Router,
    private navigation: NavigationService,
    private session: SessionService,
    private global: GlobalService
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    return this.session.isLoggedIn().then(() => {
      this.goToLogin().then(() => false);
    }).catch(() => {
      return true;
    });
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    return this.canActivate(route, state);
  }

  goToLogin(): Promise<any> {
    this.navigation.goTo('/dashboard');
    return Promise.resolve();
  }
}
