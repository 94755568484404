import { Component, OnInit } from '@angular/core';
import {Nft} from '@interfaces/api.nft.interface';
import {MembershipItem} from '@interfaces/membership.interface';
import {ModalComponent} from '@components/common/modal/modal.component';

@Component({
  selector: 'ark-nft-confirm',
  templateUrl: './nft-confirm.component.html',
  styleUrls: ['./nft-confirm.component.scss']
})
export class NftConfirmComponent implements OnInit {
  nft!: Nft;
  membership!: MembershipItem;
  constructor(protected readonly parent: ModalComponent) { }

  ngOnInit(): void {
    this.nft = this.parent.data.nft;
    this.membership = this.parent.data.membership;
  }

  close(confirm = false): void {
    this.parent.close(confirm);
  }

  gotoStripe(): void {
    window.open('https://stripe.com', '_blank');
  }
}
