import { Injectable } from '@angular/core';
import { ApiService } from '@app/services/api.service';
import {INotificationResponse} from '@interfaces/notifications.interfase';


@Injectable({
  providedIn: 'root'
})
export class NotificationsModelService {

  constructor(private api: ApiService) { }

  public notificationsList(): Promise<INotificationResponse> {
    return new Promise(async (resolve, reject) => {
      this.api.notifications.list().subscribe({ next: async (response) => {
          resolve(response);
        }, error: (error) => {
          reject(error);
        }});
    });
  }

  public notificationMarkAsRead(id: number): Promise<any> {
    return new Promise(async (resolve, reject) => {
      this.api.notifications.read(id).subscribe({ next: async (response) => {
          resolve(response);
        }, error: (error) => {
          reject(error);
        }});
    });
  }

  public notificationDelete(id: number): Promise<any> {
    return new Promise(async (resolve, reject) => {
      this.api.notifications.delete(id).subscribe({ next: async (response) => {
          resolve(response);
        }, error: (error) => {
          reject(error);
        }});
    });
  }
}
