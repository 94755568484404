import {animate, AnimationTriggerMetadata, style, transition, trigger} from '@angular/animations';

export function fadeDownAnimations(): AnimationTriggerMetadata {
  return trigger(
    'fadeDownAnimation',
    [
      transition(
        ':enter',
        [
          style({ transform: 'translateY(-10%)', opacity: 0}),
          animate('0.3s ease-out',
            style({ transform: 'translateY(0)', opacity: 1}))
        ]
      ),
      transition(
        ':leave',
        [
          style({ transform: 'translateY(0)', opacity: 1 }),
          animate('0.3s ease-in',
            style({ transform: 'translateY(-10%)', opacity: 0 }))
        ]
      )
    ]
  );
}
