import { Injectable } from '@angular/core';
import { Category } from '@app/etc/interfaces/api.category.interface';
import { ApiService } from '@app/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class CategoryModelService {

  constructor(private api: ApiService) { }

  public async categoryList(): Promise<Category[]> {
    return new Promise((resolve, reject) => {
      this.api.category.list.get().subscribe((response: Category[]) => {
        resolve(response);
      }, (error) => {
        reject(error);
      });
    });
  }

  public async categoryById(id: number): Promise<Category> {
    return new Promise((resolve, reject) => {
      this.api.category.getById.get(id).subscribe((response: Category) => {
        resolve(response);
      }, (error) => {
        reject(error);
      });
    });
  }

  public async createCategory(data:Category): Promise<any> {
    return new Promise((resolve, reject) => {
      this.api.category.create.post(data).subscribe((response: any) => {
        resolve(response);
      }, (error) => {
        reject(error);
      });
    });
  }

  public async updateCategory(data: Category): Promise<any> {
    return new Promise((resolve, reject) => {
      this.api.category.update.put(data).subscribe((response: any) => {
        resolve(response);
      }, (error) => {
        reject(error);
      });
    });
  }

  public async deletecategory(id: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.api.category.delete.delete(id).subscribe((response: any) => {
        resolve(response);
      }, (error) => {
        reject(error);
      });
    });
  }

}
