<div class="wrapper--login--error">
  <ark-icon size="huge" icon="alert-warning"></ark-icon>
  <h3>
    Sign in failed
  </h3>
  <div class="description">
    Close this window and try again
  </div>
  <div class="cta">
    <ark-button size="medium" type="primary" (click)="close()">
      CLOSE TAB
    </ark-button>
  </div>
</div>
