import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { UtilService } from '@app/services/util.service';
import {PostComment} from '@interfaces/forum.interface';

@Component({
  selector: 'ark-post-publish',
  templateUrl: './post-publish.component.html',
  styleUrls: ['./post-publish.component.scss', './post-publish.component.mobile.scss']
})
export class PostPublishComponent implements OnInit {
  @ViewChild('fileButton', {static: false}) fileElement!: ElementRef;
  _post! : PostComment;
  @Input() set post(value: PostComment){
    this._post = value;
  };
  get post(): PostComment {
    return this._post;
  }

  @Output() cancel = new EventEmitter<any>();
  @Output() publish = new EventEmitter<any>();

  form: FormGroup = this.initForm();
  validForm: boolean = false;
  validCategories: boolean = true;
  uploadError = false;
  imgFilePath = ''
  chipError = false;
  isMobile = false;

  constructor(private fb: FormBuilder, private util: UtilService) { }

  ngOnInit(): void {
    this.isMobile = window.innerWidth <= 867;
  }

  initForm(): FormGroup {
    return this.fb.group({
      categories: new FormControl([]),
      values: new FormControl([]),
      coverImage: new FormGroup({
        url: new FormControl(null),
        alt: new FormControl(null)
      })
    });
  }

  updateSelectedChips(e: { name: string }): void {
    this.checkFormValidity();
  }

  checkFormValidity() {
    this.validForm = this.form.get('categories')?.value.length >= 1 && this.form.get('values')?.value.length >= 1;
  }

  onPublish(e: Event): void {
    if (this.validForm) {
      this.publish.emit(this.form.getRawValue());
    this.resetForm();
    } else {
      this.chipError = true;
      this.validCategories = false;
    }
  }
  onCancel(): void {
    this.cancel.emit();
    this.resetForm();
  }

  openSelectFile(): void {
    if (!this.imgFilePath) {
      this.uploadError = false;
      this.fileElement.nativeElement.click();
    }
  }

  removeFile(): void{
      this.uploadError = false;
      this.imgFilePath = '';
      const coverImg = this.form.get('coverImage')
      coverImg?.get('alt')?.setValue(null);
      coverImg?.get('url')?.setValue(null);

  }

  public fileChangeEvent(fileInput: any): void {
    if (fileInput.target.files && fileInput.target.files[0]) {
      const file = fileInput.target.files[0];
      const coverImg = this.form.get('coverImage')
      coverImg?.get('alt')?.setValue(file.name);

      const reader = new FileReader();
      reader.onload = async (e: any) => {
        coverImg?.get('url')?.setValue(e.target.result);
        this.imgFilePath = reader.result as string;
      };

      reader.onerror = () => {
        this.uploadError = true;
      };

      reader.readAsDataURL(file);
    }
  }

  resetForm() {
    this.removeFile();
    this.validCategories = true;
    this.validForm = false;
    this.form.updateValueAndValidity();
  }
}
