import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ModalComponent} from '@components/common/modal/modal.component';
import {CategoryModelService} from '@models/category-model.service';
import {DiscussionModelService} from '@app/models/discussion-model.service';
import {DropDownOption, ValueChangeEvent} from '@interfaces/form.interface';
import {IDiscussion} from '@interfaces/discussion.interface';
import {Category, IEntityCategory} from '@interfaces/api.category.interface';
import {ModalSize} from '@interfaces/common.interface';
import {ModalService} from '@services/modal.service';
import {
  DiscussionActionSuccessComponent
} from '@components/modals/discussion/discussion-action-success/discussion-action-success.component';
import {
  DiscussionActionErrorComponent
} from '@components/modals/discussion/discussion-action-error/discussion-action-error.component';
import {IntercomService} from '@services/intercom.service';
import {BroadcastEvent} from '@interfaces/broadcast.interface';

@Component({
  selector: 'ark-discussion-action',
  templateUrl: './discussion-action.component.html',
  styleUrls: ['./discussion-action.component.scss']
})
export class DiscussionActionComponent implements OnInit {
  public form!: FormGroup;
  public categoryId: number | string = 0;
  public category: IEntityCategory[] = [];
  public edit: boolean = false;
  public item?: IDiscussion;
  public busy: boolean = false;
  public serverError = null;

  public readonly minChars = 100;
  public readonly maxChars = 500;

  public categories: any[] = [];
  public selectedCategory: string = '';
  public parentCategories: DropDownOption [] = [];
  protected entityCategories: IEntityCategory[] = [];

  protected selectedEntityCategories: DropDownOption [] = [];

  public disabled = false;

  constructor(
    private fb: FormBuilder,
    protected readonly parent: ModalComponent,
    private suggestionService: DiscussionModelService,
    private categoryService: CategoryModelService,
    private cRef: ChangeDetectorRef,
    private proposalSrv: DiscussionModelService,
    private intercom: IntercomService,
    private readonly modalService: ModalService,
  ) {
    this.categoryId = parent.data.categoryId;
    this.edit = parent.data.edit;
    this.entityCategories = this.parent.data.entityCategories;

    this.categoryService.categoryList().then((categories: Category[]) => {
      this.category = DiscussionActionComponent.getCategory(categories, +this.categoryId);
      this.categories = DiscussionActionComponent.getSelectData(categories, +this.categoryId);
      this.parentCategories = this.transformEntityCategories();
      this.selectedEntityCategories = this.parentCategories;
    });

    this.parent.closeButton = true;

    if (this.edit) {
      this.item = this.parent.data.item;
    }

    this.form = this.initForm();
  }

  ngOnInit(): void {
  }

  public initForm(): FormGroup {
    return this.fb.group({
      title: [this.item ? this.item.title : null, Validators.required],
      description: [this.item ? this.item.description : null, Validators.compose([Validators.required, Validators.minLength(100), Validators.maxLength(500)])],
      certificationCategoryId: [this.categoryId, Validators.required],
      certificationProposalId: [this.item ? this.item.certificationProposalId : null],
      data: '',
    });
  }

  formValueChange(event: ValueChangeEvent, control: string) {
    this.form.get(control)?.setValue(event.value);
    this.form.markAsDirty();
    this.form.updateValueAndValidity();
  }

  public validateForm() {
    if (this.form.get('description')?.value === null) {
      this.form.get('description')?.setValue('');
    }
    if (this.form.get('title')?.value === null) {
      this.form.get('title')?.setValue('');
    }

    this.form.updateValueAndValidity();
  }


  public checkStep(): boolean {
    return this.form.get('title')?.value
      && (this.selectedEntityCategories.length > 0 && this.selectedEntityCategories.length <= 3)
      && this.form.get('description')?.value
      && this.form.get('description')?.value.length  >= this.minChars
      && this.form.get('description')?.value.length  <= this.maxChars;
  }

  public async submit(): Promise<void> {
    this.validateForm();

    let data = this.form.value;
    let entityCategories: string[] = [];
    this.selectedEntityCategories.forEach(item => entityCategories.push('' + item.value));
    data['entityCategoryList'] = entityCategories;

    if (this.form.valid && (this.selectedEntityCategories.length > 0 && this.selectedEntityCategories.length <= 3)) {
      this.busy = true;
      if (this.edit) {
        this.proposalSrv.updateProposal(this.form.value).then(
          (res) => this.onSuccess(res),
          (error) => this.onError(error)
        );
      } else {
        this.proposalSrv.createProposal(this.form.value).then(
          (res) => this.onSuccess(res),
          (error) => this.onError(error)
        );
      }
    }
  }

  private onError(error: any): void {
    this.serverError = error;
    this.busy = false;
    console.error('error', error)
    this.parent.close();
    setTimeout(() => {
      this.modalService.open(DiscussionActionErrorComponent, {
        style: 'dark',
        size: ModalSize.DYNAMIC,
      });
    }, 100);
  }

  private onSuccess(res: any): void {
    this.intercom.broadcast({
      event: this.edit ? BroadcastEvent.ENTITY_UPDATED: BroadcastEvent.ENTITY_CREATED,
    });
    this.serverError = null;
    this.busy = false;
    this.parent.close();
    setTimeout(() => {
      this.modalService.open(DiscussionActionSuccessComponent, {
        style: 'dark',
        size: ModalSize.DYNAMIC,
        data: {
          edit: this.edit
        }
      });
    }, 200);
  }

  public moreSelected(item: DropDownOption): void {
    this.categoryId = '' + item;
    this.disabled = +this.categoryId === 0;

    this.categories.forEach((i) => {
      if (+i.value === +item) {
        this.selectedCategory = i.name;
      }
    });
    this.cRef.detectChanges();
  }

  protected static getSelectData(categories: Category[], categoryId: number): DropDownOption[] {
    let options: DropDownOption[] = [];

    categories.forEach((category) => {
      let option: DropDownOption = {name: category.name, value: '' + category.certificationCategoryId}


      option['selected'] = +category!.certificationCategoryId! === categoryId;
      options.push(option);
    });

    return options;
  }

  protected static getCategory(categories: Category[], categoryId: number): IEntityCategory[] {
    let _categories: IEntityCategory[] = [];
    categories.forEach(category => {
      if (+category.certificationCategoryId! === +categoryId) {
        _categories = [{
          certificationCategoryId: +categoryId,
          certificationCategoryName: category.name
        }];
      }
    })
    return _categories;
  }

  protected transformEntityCategories() {
    if (this.entityCategories.length === 0 && this.categoryId !== 0 && this.category.length > 0) {
      this.entityCategories = this.category;
    }
    let result: DropDownOption[] = []
    this.entityCategories.forEach(item => {
      const el: DropDownOption = {
        value: item.certificationCategoryId,
        name: item.certificationCategoryName,
        selected: true
      }
      result.push(el);
    })
    return result;
  }

  selectedOptions(event: DropDownOption[]) {
    this.selectedEntityCategories = event;
  }
}
