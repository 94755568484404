import {Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {dropdownAnimation} from '@animations/dropdown.animations';
import { ValueChangeEvent } from '@interfaces/form.interface';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { InputComponent } from '../input/input.component';


@Component({
  selector: 'ark-chip-selection',
  templateUrl: './chip-selection.component.html',
  styleUrls: ['./chip-selection.component.scss'],
  animations: [dropdownAnimation()]
})
export class ChipSelectionComponent extends InputComponent implements OnInit, OnDestroy {

  valueChangedSubscription: Subscription = new Subscription();

  @Input() minimumChips = 0;
  @Input() chips: any[] = [];
  @Input() selectedChips: any[] = [];
  @Input() displayProperty = 'name';
  @Input() hint: string = '';
  @Output() chipAdded = new EventEmitter<any>();
  @Output() chipRemoved = new EventEmitter<any>();
  @Output() updated = new EventEmitter<any>();

  @ViewChild('chipInputRef', {static: true})
  chipInputRef!: ElementRef;

  expanded = false;
  filteredChips: any[] = [];

  constructor(
    fb: FormBuilder,
    translate: TranslateService) {
    super(fb, translate);
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.valueChangedSubscription = this.valueChangedObservable.pipe(distinctUntilChanged()).subscribe((e: ValueChangeEvent) => {
      this.searchChips(e.value);
    });
  }

  override ngOnDestroy(): void {
    if (this.valueChangedSubscription) {
      this.valueChangedSubscription.unsubscribe();
    }
    super.ngOnDestroy();
  }

  @HostListener('document:click', ['$event.target'])
  public onClick(target: any): void {
    const clickedInsideChipInput = this.chipInputRef.nativeElement.contains(target);
    if (!clickedInsideChipInput) {
      this.expanded = false;
    }
  }

  override onFocus(): void {
    this.searchChips(this.form.get('formValue')?.value);
    this.expanded = true;
    super.onFocus();
  }

  override onBlur(): void {
    super.onBlur();
    if (this.selectedChips.length > 0) {
      this.formStates.labelRaised = true;
    }

    this.validateSelectedChips();
    this.validateRequiredChips();
  }

  searchChips(term: string): void {
    this.filteredChips = [];
    this.chips.forEach((item) => {
      const name: string = item[this.displayProperty];
      const selectedChip = this.selectedChips.find(e => e.name === name);
      if (name.toLowerCase().indexOf(term.toLowerCase()) > -1 && !selectedChip) {
        this.filteredChips.push(item);
      }
    });
    if (this.selectedChips.length <= 0 && this.filteredChips.length <= 0) {
      this.filteredChips = this.chips.slice(0, 5);
    }
  }

  addChip(selectedChip: any): void {
    this.selectedChips.push(selectedChip);
    if (this.selectedChips.length > 0) {
      this.formStates.labelRaised = true;
    }
    this.chipAdded.emit(selectedChip);
    this.updated.emit(this.selectedChips);
    this.searchChips(selectedChip[this.displayProperty]);
    this.validateSelectedChips();
  }

  removeChip(selectedChip: { value: string }): void {
    const chip = this.selectedChips.find(e => e.name === selectedChip.value);
    const index = this.selectedChips.indexOf(chip);
    this.selectedChips.splice(index, 1);
    this.chipRemoved.emit(chip);
    this.updated.emit(this.selectedChips);
    this.expanded = false;
    if (this.selectedChips.length === 0) {
      this.formStates.labelRaised = false;
    }
    this.validateSelectedChips();
  }

  validateSelectedChips(): void {
    if (this.selectedChips.length < this.minimumChips && this.minimumChips > 0 && this.selectedChips.length >= 0) {
      this.formStates.error = true;
      this.errorMessage = this.errorLabel || this.translate.instant( 'COMPONENTS.INPUT.ERROR_TOO_FEW_CHIPS', { value: this.minimumChips });
    } else {
      this.formStates.error = false;
      this.errorMessage = '';
    }
  }

  validateRequiredChips(): void {
    if (this.required) {
      if (this.selectedChips.length === 0) {
        this.formStates.error = true;
        this.errorMessage = this.errorLabel || this.translate.instant('COMPONENTS.INPUT.ERROR_REQUIRED');
      } else {
        this.formStates.error = false;
        this.errorMessage = '';
      }
    }
  }
}
