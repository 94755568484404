<div class="comment-wrapper">
  <div class="highlighter" *ngIf="comment.new"></div>
  <div class="comment-content">
    <ark-post-author [menu]="commentsOptionsMenu"
                     [author]="comment.author"
                     [metadata]="comment"
                     [comment]="comment.author && comment.author.walletId === me.walletId"
                     (deleteComment)="applyDeleteComment()"
                     [date]="comment.createDate"></ark-post-author>
    <div class="comment-text" [innerHTML]="comment.body | nl2br"></div>
    <ark-post-action-bar [metadata]="comment"
                         [votes]="comment.votes"
                         [comment]="true"
                         [expanded]="comment.commentsExpanded"
                         (userVote)="doVote($event)"
                         (toggleExpand)="comment.commentsExpanded = $event"
                         (toggleReply)="toggleReplyLine()"
    ></ark-post-action-bar>
    <ark-post-reply-line *ngIf="replyVisible" [replyTo]="comment.author"
                         (triggerMobileReply)="triggerMobileReply()"
                         [disable]="replyDisabled"
                         (replySubmitted)="addReply($event, true)"
                         type="reply"></ark-post-reply-line>
    <div class="comments-inline" *ngIf="comment.comments && comment.commentsExpanded && depth > -1">
      <ark-post-comments-section
        [depth]="depth"
        [mobile]="mobile"
        [comments]="comment.comments"
        (replyToParent)="addReply($event, true)"
      ></ark-post-comments-section>
    </div>
    <ark-post-more-comments [metadata]="comment"
                            (click)="loadMoreComments()"
                            type="reply"
                            [loading]="commentsLoading"
                            *ngIf="comment.commentsDisplayed < comment.commentsTotal
                            && comment.commentsExpanded">
    </ark-post-more-comments>
  </div>
</div>
