import {animate, AnimationTriggerMetadata, style, transition, trigger} from '@angular/animations';

export function slideInSameAnimations(): AnimationTriggerMetadata {
  return trigger(
    'slideInSameAnimation',
    [
      transition(
        ':enter',
        [
          style({ transform: 'translateX(100%)'}),
          animate('0.6s ease-out',
            style({ transform: 'translateX(0)'}))
        ]
      ),
      transition(
        ':leave',
        [
          style({ transform: 'translateX(0)' }),
          animate('0.3s ease-in',
            style({ transform: 'translateX(-100%)' }))
        ]
      )
    ]
  );
}
