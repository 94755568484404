import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {InputComponent} from '../input/input.component';
import {TranslateService} from '@ngx-translate/core';
import {distinctUntilChanged} from 'rxjs/operators';
import {Subscription} from 'rxjs';
import {ValueChangeEvent} from '@app/etc/interfaces/form.interface';

@Component({
  selector: 'ark-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss']
})
export class TextareaComponent extends InputComponent implements OnInit, OnDestroy {

  @Input() rows = 10;
  @Input() columns = 30;
  charsTyped = 0;

  valueChangedSubscription: Subscription = new Subscription();

  constructor(
    fb: FormBuilder,
    translate: TranslateService) {
    super(fb, translate);
  }

  override ngOnInit(): void {
    super.ngOnInit();

    this.valueChangedSubscription = this.valueChangedObservable.pipe(distinctUntilChanged()).subscribe((e: ValueChangeEvent) => {
      this.charsTyped = e.value.length;
    });
  }

  override ngOnDestroy(): void {
    if (this.valueChangedSubscription) {
      this.valueChangedSubscription.unsubscribe();
    }
    super.ngOnDestroy();
  }
}
