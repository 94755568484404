import {animate, AnimationTriggerMetadata, style, transition, trigger} from '@angular/animations';

export function fadeInAnimations(): AnimationTriggerMetadata {
  return trigger(
    'fadeInAnimation',
    [
      transition(
        ':enter',
        [
          style({ opacity: 0}),
          animate('0.3s linear',
            style({ opacity: 1}))
        ]
      ),
      transition(
        ':leave',
        [
          style({ opacity: 1 }),
          animate('0.3s linear',
            style({ opacity: 0 }))
        ]
      )
    ]
  );
}
