import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'ark-spinner1',
  templateUrl: './spinner1.component.html',
  styleUrls: ['./spinner1.component.scss']
})
export class Spinner1Component implements OnInit {

  @Input() size: 'xs' |'small' | 'medium' | 'large' | 'xl' = 'large';

  constructor() { }

  ngOnInit(): void {
  }

}
