<div class="wrapper" [ngSwitch]="step">
    <form class="step step-{{step}}">
        <div *ngSwitchCase="1" [ngTemplateOutlet]="step1"></div>
        <div *ngSwitchCase="2" [ngTemplateOutlet]="step2"></div>
        <div *ngSwitchCase="3" [ngTemplateOutlet]="step3"></div>
    </form>
</div>

<ng-template #step1>
    <div class="header">
        <h3>{{ 'MODALS.POST_HIDE.STEP_1.TITLE' | translate }}</h3>
        <div class="subtitle">{{ 'MODALS.POST_HIDE.STEP_1.DESCRIPTION' | translate }}</div>
    </div>
    <div class="body">

    </div>
    <div class="footer">
        <ark-button (click)="next()" rightIcon="right" iconColor="light" [disabled]="!hasValues">
            <div>{{ 'MODALS.POST_HIDE.STEP_1.CTA' | translate }}</div>
        </ark-button>
    </div>
</ng-template>

<ng-template #step2>
    <div class="header">
        <h3>{{ 'MODALS.POST_HIDE.STEP_2.TITLE' | translate }}</h3>
    </div>
    <div class="body">

        <div class="input" *ngIf="form?.value?.reason === 'other'">
            <ark-input (valueChanged)="descriptionChanged($event)" [label]="'Details'" [required]="true"></ark-input>
        </div>
    </div>
    <div class="footer">
        <ark-button (click)="hidePost()">
            <div>{{ 'MODALS.POST_HIDE.STEP_2.CTA' | translate }}</div>
        </ark-button>
    </div>
</ng-template>

<ng-template #step3>
    <ark-icon icon="tick" color="cta" size="huge" circle="hollow"></ark-icon>
    <div class="header">
        <h3>{{ 'MODALS.POST_HIDE.STEP_3.TITLE' | translate }}</h3>
        <div class="subtitle">{{ 'MODALS.POST_HIDE.STEP_3.DESCRIPTION' | translate }}</div>
    </div>
</ng-template>
