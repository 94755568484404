<div class="wrapper--proposal-backed">
  <ark-icon size="huge" icon="alert-success" color="dark"></ark-icon>
  <h3>
    <span *ngIf="mode === 'backed'">
      {{'PAGES.CATEGORY_DETAILS.PROPOSALS.ACTION_MODAL.NEW.BACKED.TITLE' | translate}}
    </span>
    <span *ngIf="mode === 'updated'">
      {{'PAGES.CATEGORY_DETAILS.PROPOSALS.ACTION_MODAL.NEW.BACKING_UPDATED.TITLE' | translate}}
    </span>
  </h3>
  <div class="description">
    {{'PAGES.CATEGORY_DETAILS.PROPOSALS.ACTION_MODAL.NEW.BACKED.DESCRIPTION' | translate}}
  </div>
</div>
