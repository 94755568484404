<div class="invitation-form-wrapper">
  <h2 class="h2">Request invitation</h2>
  <div class="form">
    <ark-input
      [required]="true"
      [value]="form.get('email')?.value"
      [name]="'Email'"
      [label]="'Email' | translate"
      (valueChanged)="formValueChange($event, 'email')"
    ></ark-input>
    <div class="error-msg" *ngIf="serverErrorMsg"><ark-icon icon="alert-error" size="small" color="error"></ark-icon> <p class="text">{{this.serverErrorMsg}}</p></div>
  </div>
  <div class="actions">
    <ark-button [link]="true" type="secondary" (click)="close()">
      Cancel
    </ark-button>
    <ark-button (click)="submit()" [loading]="loading">
      REQUEST
    </ark-button>
  </div>
</div>
