<div class="wrapper--dropdown" [ngClass]="{'expanded': expanded}">
  <div class="dropdown-top {{size}} type-{{type}}" (click)="toggleExpand()" [ngClass]="{'show-background': showBackground, 'show-border': showBorder}">
    <ng-container [ngSwitch]="type">
      <ng-container *ngSwitchCase="'box'">
        <div class="dropdown-label">{{ label | translate}}</div>
        <div class="dropdown-selected">
          <ark-icon *ngIf="selected.icon" [icon]="selected.icon" size="medium" color="dark"></ark-icon>
          <span>{{ selected.label | translate }}</span>
        </div>
        <ark-icon [icon]="iconContent" [size]="iconSize" [color]="iconColor" class="dropdown-arrow"></ark-icon>
      </ng-container>
      <ng-container *ngSwitchCase="'icon'">
        <ark-icon [icon]="iconContent" [size]="iconSize" [color]="iconColor"></ark-icon>
      </ng-container>
    </ng-container>
  </div>
  <div class="dropdown-bottom">
    <div class="dropdown-item" *ngFor="let item  of items" [ngClass]="{'selected': item.id === selected.id}" [ngClass]="{'disabled': item.disabled}"
         (click)="selectItem(item)">
      <ark-icon *ngIf="item.icon" [icon]="item.icon" size="medium" [color]="item.color ? item.color : 'dark'"></ark-icon>
      <span class="color-{{item.color}}">{{ item.label | translate }}</span>
    </div>
  </div>
</div>
