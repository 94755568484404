import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'ark-loader-inline',
  templateUrl: './loader-inline.component.html',
  styleUrls: ['./loader-inline.component.scss']
})
export class LoaderInlineComponent implements OnInit {

  @Input() color: 'light' | 'cta' | 'dark' = 'cta';

  constructor() { }

  ngOnInit(): void {
  }

}
