import { Component, OnInit } from '@angular/core';
import {ModalComponent} from '@components/common/modal/modal.component';
import {AccountModelService} from '@models/account-model.service';
import { Settings } from '@app/etc/interfaces/api.account.interface';

@Component({
  selector: 'ark-member-settings',
  templateUrl: './member-settings.component.html',
  styleUrls: ['./member-settings.component.scss']
})
export class MemberSettingsComponent implements OnInit {
  public serverErrorMsg: string | null = null;
  public loading = false;
  public settings: Settings = {
    emailNotifications: true,
    promotions: true,
    appNotifications: true,
  };

  constructor(private account: AccountModelService, protected readonly parent: ModalComponent) {
    this.parent.closeButton = true;
  }

  async ngOnInit(): Promise<void> {
    this.settings = await this.account.getSettings();
  }

  submit() {
    this.serverErrorMsg = null;
    this.loading = true;
    this.account.postSettings(this.settings).then((res) => {
      this.loading = false;
      this.parent.close('success');
    }).catch((err: {code: string, description: string }) => {
      this.loading = false;
      this.serverErrorMsg = err.description;
    });
  }

  public emailChanged(event: any): void {
    this.settings.emailNotifications = event.value;
  }

  public promotionChanged(event: any): void {
    this.settings.promotions = event.value;
  }

  public appChanged(event: any): void {
    this.settings.appNotifications = event.value;
  }

  public close(): void {
    this.parent.close();
  }
}
