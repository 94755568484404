import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {InputComponent} from '@components/form/input/input.component';
import {FormBuilder} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {DropDownOption, DropDownType, ValueChangeEvent} from '@interfaces/form.interface';
import {distinctUntilChanged} from 'rxjs/operators';

@Component({
  selector: 'ark-dropdown-selection',
  templateUrl: './dropdown-selection.component.html',
  styleUrls: ['./dropdown-selection.component.scss']
})

export class DropdownSelectionComponent extends InputComponent implements OnInit {

  public expanded = false;
  protected localSelectedValue!: DropDownOption | string;
localSelectedValues: DropDownOption[] = [];

  protected options: DropDownOption[] = [];
  public filteredOptions: DropDownOption[] = [];

  public selectedAssigned: any;

  @Input() hintLeft = '';
  @Input() maximum = -1;
  @Input() minimum = 2;
  @Input() selectionType: DropDownType = 'list';
  @Output() selectionChange = new EventEmitter<DropDownOption>();
  @Output() selectedOptions = new EventEmitter<DropDownOption[]>();

  constructor(
    fb: FormBuilder,
    translate: TranslateService) {
    super(fb, translate);
  }

  private _searchable = false;

  get searchable(): boolean {
    return this._searchable;
  }

  @Input() set searchable(v: boolean) {
    this._searchable = v;
    if (v) {
      this.filteredOptions = [];
    }
  }

  private _items: DropDownOption[] = [];

  get items(): DropDownOption[] {
    return this._items;
  }

  @Input() set items(v: DropDownOption[]) {
    this._items = v;
    if (v) {
      this.options = v.map(item => {
        const newItem = {
          ...item,
          name: this.translate.instant(item.name)
        };
        return Object.assign({}, newItem);
      });

      if (this.options.length > 0 && this.selectedAssigned) {
        this.setSelectedValue(this.selectedAssigned);
      }

      if (!this.searchable) {
        this.filteredOptions = this.options;
      }
    }
  }

  get selectedValue(): any {
    if (this.selectionType === 'list') {
      return this.localSelectedValue;
    }
    if (this.selectionType === 'checkbox') {
      return this.localSelectedValues;
    }
  }

  @Input() set selectedValue(value: any) {
    this.setSelectedValue(value);
  }

  protected setSelectedValue(value: any): void {
    this.selectedAssigned = value;
    if (this.options.length === 0) {
      return;
    }

    if (value) {
      if (this.selectionType === 'list') {
        let selected: string = '';
        this.options.forEach(e => {
          if (!isNaN(Number(value))) {
            selected = '' + value;
          } else if (typeof value === 'string') {
            selected = value;
          } else if ('value' in value) {
            selected = value.value;
          }
          e.selected = '' + e.value === '' + selected;
          this.localSelectedValue = e;
          return e;
        });
        if (selected) {
          this.value = this.options.find((e) => '' + e.value === '' + selected)?.name;
        }
      }

      if (this.selectionType === 'checkbox') {
        let _value: Array<string> = [];

        if (typeof value === 'string' || value instanceof String) {
          const _val = value.replace(/\s+/g, '');
          _value = _val.split(',');
        } else if (Array.isArray(value)) {
          if (value.length >= 0) {
            let _values: (string | DropDownOption)[] = [];
            value.forEach((item) => {
              if ('name' in item && 'value' in item) {
                _values.push('' + item.value);
              } else if (typeof value === 'string' || value instanceof String || !isNaN(Number(item))) {
                _values.push('' + value);
              }
            });

            this.options.forEach(item => {
              if (_values.includes('' + item.value)) {
                _value.push('' + item.value);
              }
            })
          }
        }

        let _values: Array<string> = [];

        this.localSelectedValues = [];

        _value.forEach((item) => {
          this.options.forEach((i) => {
            if (item === i.value) {
              _values.push(i.name);
              i.selected = true;
              this.localSelectedValues.push(i);
            }
          });
        })

        this.localSelectedValue =  this.localSelectedValues[this.localSelectedValues.length - 1]

        this.value = _values.join(', ');
      }
    } else {
      this.localSelectedValues = [];
      this.value = '';
      this.localSelectedValue = '';
      this.options.map(i => i.selected = false);
    }
  }

  get empty(): boolean {
    return this.filteredOptions.length <= 0;
  }

  override ngOnInit(): void {
    super.ngOnInit();
    if (this.searchable) {
      this.valueChangedObservable.pipe(distinctUntilChanged()).subscribe((event: ValueChangeEvent) => {
        if (event.value) {
          this.formStates.focused = true;
          this.filteredOptions = this.options.filter(e => e.name.toLowerCase().indexOf(event.value.toLowerCase()) > -1);
          this.expanded = !this.empty;
        } else {
          this.formStates.error = false;
          this.filteredOptions = [...this.options];
        }
      });
    }
  }

  exist(value: string): DropDownOption | undefined {
    return this.options.find(e => e.value === value);
  }

  toggle(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
    this.expanded = !this.expanded && !this.empty;
  }

  select(option: DropDownOption): void {
    if (this.selectionType === 'list') {
      this.localSelectedValue = option;
      this.selectedValue = option.value;
      this.filteredOptions = [...this.options];
      this.selectedOptions.emit(this.localSelectedValues);
    }
    this.selectionChange.emit(option.value);
  }

  optionsChanged(options: DropDownOption[]): void {
    if (this.selectionType === 'checkbox') {
      this.selectedValue = options.map(e => e.value).join(', ');
      this.filteredOptions = [...this.options];
      this.selectedOptions.emit(this.localSelectedValues);
    }

    this.value = options.map(e => e.name).join(', ');
    if (!this.value) {
      this.formStates.labelRaised = false;
    }
  }

  override clear(): void {
    this.value = null;
    this.formStates.labelRaised = false;
  }

  save(options: DropDownOption[]): void {
    this.value = options.map(e => e.name).join(', ');
  }

  onClosed(): void {
    this.expanded = false;
  }
}
