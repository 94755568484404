import { Component, OnInit } from '@angular/core';
import {ModalComponent} from '@components/common/modal/modal.component';
import {NavigationService} from '@services/navigation.service';

@Component({
  selector: 'ark-login-success',
  templateUrl: './login-success.component.html',
  styleUrls: ['./login-success.component.scss']
})
export class LoginSuccessComponent implements OnInit {

  constructor(protected readonly parent: ModalComponent, private navigation: NavigationService) { }

  ngOnInit(): void {
  }

  public close(): void {
    this.parent.close();
    this.navigation.goTo('dashboard');
  }
}
