import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {OptionsMenu, OptionsMenuItemIdentifier} from '@interfaces/common.interface';

@Component({
  selector: 'ark-options-menu',
  templateUrl: './options-menu.component.html',
  styleUrls: ['./options-menu.component.scss']
})
export class OptionsMenuComponent implements OnInit {

  @Input() options!: OptionsMenu;
  @Output() itemClicked: EventEmitter<OptionsMenuItemIdentifier> = new EventEmitter<OptionsMenuItemIdentifier>();

  constructor() { }

  ngOnInit(): void {
  }

  itemClick(item: OptionsMenuItemIdentifier): void {
    this.itemClicked.emit(item);
  }

}
