<div class="wrapper--slider" #slider (touchstart)="knobDragStart($event)" (mousedown)="knobDragStart($event)">
  <div class="bar " #bar>
    <div class="fill--current {{fillColor}}" [style.left]="currentLeft + '%'" [style.width]="fillCurrentPercentage + '%'"></div>
    <div class="notch" *ngIf="showKnob" #notch [style.left]="notchValue + '%'"></div>
  </div>
  <div class="knob" #knob *ngIf="showKnob"
       [ngClass]="{'active': knobDragActive}"
       [style.transform]="'translateX('+ knobPositionX + 'px)'">
    <div class="knob--data" [class.text-hidden]="textHidden">
      {{percentageDisplay}}
    </div>
  </div>
</div>
