<div class="wrapper---chip-group direction-{{direction}}">
    <div class="chip" *ngFor="let item of options.items; let i = index;">
        <ark-chip
            [label]="item.label"
            [value]="item.value"
            [selected]="checkedValue === item.value"
            [value]="item.value"
            (chipSelected)="selectOption(item.value)"
            [cancellable]="false">
        </ark-chip>
    </div>
</div>