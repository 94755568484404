export interface MembershipItem {
  title: string;
  type: MembershipType;
  currencySymbol: string;
  price: number;
  freeDisabled: boolean;
  stripePriceId: string;
  stripeProductId: string;
  credits: number;
  id: number;
  nft: {
    name: string;
    sold: number;
    total: number;
    description: string;
  }
  multiplier: number;
  popular: boolean;
  current: boolean;
}

export enum MembershipType {
  'FREE' = 'free',
  'PAID' = 'paid'
}
