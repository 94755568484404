import { Injectable } from '@angular/core';
import { ApiService } from '@app/services/api.service';
import {IProposal, IProposalItem, IRequest, StatisticsMine, Track} from '@interfaces/proposal.interfase';


@Injectable({
  providedIn: 'root'
})
export class ProposalModelService {

  constructor(private api: ApiService) { }

  public proposalList(apiRequest: any): Promise<IProposal> {
    return new Promise(async (resolve, reject) => {
      this.api.request.list.get(apiRequest).subscribe({ next: async (response) => {
          resolve(response);
        }, error: (error) => {
          reject(error);
        }});
    });
  }

  public proposalShow(id: number): Promise<IProposalItem[]> {
    return new Promise(async (resolve, reject) => {
      this.api.request.show.get(id).subscribe({ next: async (response) => {
          resolve(response);
        }, error: (error) => {
          reject(error);
        }});
    });
  }

  public proposalDelete(id: number): Promise<IProposalItem[]> {
    return new Promise(async (resolve, reject) => {
      this.api.request.delete(id).subscribe({ next: async (response) => {
          resolve(response);
        }, error: (error) => {
          reject(error);
        }});
    });
  }


  public async createProposal(data: any): Promise<any> {
    return new Promise(async (resolve, reject) => {
      this.api.request.create.post(data).subscribe({ next: async (response) => {
          resolve(response);
        }, error: (error) => {
          reject(error);
        }});
    });
  }

  public async updateProposal(data: any): Promise<any> {
    return new Promise(async (resolve, reject) => {
      this.api.request.update.put(data).subscribe({ next: async (response) => {
          resolve(response);
        }, error: (error) => {
          reject(error);
        }});
    });
  }

  public async all(data: any): Promise<IRequest> {
    return new Promise(async (resolve, reject) => {
      this.api.request.all(data).subscribe({ next: async (response) => {
          resolve(response);
        }, error: (error) => {
          reject(error);
        }});
    });
  }

  public async backProject(data: any): Promise<any> {
    return new Promise(async (resolve, reject) => {
      this.api.request.back(data).subscribe({ next: async (response) => {
          resolve(response);
        }, error: (error) => {
          reject(error);
        }});
    });
  }

  public async unbackAllProject(data: any): Promise<any> {
    return new Promise(async (resolve, reject) => {
      this.api.request.removeBack(data).subscribe({ next: async (response) => {
          resolve(response);
        }, error: (error) => {
          reject(error);
        }});
    });
  }

  public async updateBackProject(data: any): Promise<any> {
    return new Promise(async (resolve, reject) => {
      this.api.request.updateBack(data).subscribe({ next: async (response) => {
          resolve(response);
        }, error: (error) => {
          reject(error);
        }});
    });
  }

  public async likeProject(id: number): Promise<void> {
    return new Promise(async (resolve, reject) => {
      this.api.request.like(id).subscribe({ next: async (response) => {
          resolve(response);
        }, error: (error) => {
          reject(error);
        }});
    });
  }


  public async dislikeProject(id: number): Promise<void> {
    return new Promise(async (resolve, reject) => {
      this.api.request.dislike(id).subscribe({ next: async (response) => {
          resolve(response);
        }, error: (error) => {
          reject(error);
        }});
    });
  }

  public statisticsMine(): Promise<StatisticsMine> {
    return new Promise(async (resolve, reject) => {
      this.api.request.statistics.get().subscribe({ next: async (response) => {
          resolve(response);
        }, error: (error) => {
          reject(error);
        }});
    });
  }

  public track(data: Track): Promise<any> {
    return new Promise(async (resolve, reject) => {
      this.api.request.track(data).subscribe({ next: async (response) => {
          resolve(response);
        }, error: (error) => {
          reject(error);
        }});
    });
  }
}
