import {Injectable} from '@angular/core';
import {
  AdServedBatchOptions, BlockchainResponse,
  CampaignEditOptions,
  CampaignOptions,
  DynamicCertificationOptions,
  EditCertificationOptions,
  EditEntityOptions,
  EntityOptions,
  FundReceiver, MemberType,
  MultiwalletTransferOptions,
  StaticCertificationOptions
} from '@interfaces/metamask.interface';
import {IntercomService} from '@services/intercom.service';
import {BroadcastEvent} from '@interfaces/broadcast.interface';
import {environment} from '@env/environment';

declare let window: any;

const arkius = require('@arkius/blockchain-lib');
const cert = arkius.certification;
const camp = arkius.campaign;
const mark = arkius.marketplace;
const token = arkius.token;
const mgr = arkius.manager;
const ent = arkius.entity;
const mw = arkius.multiwallet;
const member = arkius.roles.member;
const certifier = arkius.roles.certifier;
const caster = arkius.roles.seeker;
const util = arkius.util;

@Injectable({
  providedIn: 'root'
})

export class MetamaskService {
  gasReq = 16000000;

  public roles = {
    member: {
      acceptOwnership: (index = 0, gasReq = this.gasReq): Promise<BlockchainResponse> => member.acceptOwnership(index, gasReq),
      allMembers: (): Promise<Array<number>> => member.allMembers(),
      balance: (account: string, id: number): Promise<number> => member.balance(account, id),
      balanceBatch: (accounts: Array<string>, ids: Array<number>): Promise<BlockchainResponse> => member.balanceBatch(accounts, ids),
      burn: (account: string, value: number, index = 0, gasReq = this.gasReq): Promise<any> =>
        member.burn(account, value, index, gasReq),
      certifierAddress: (): Promise<string> => member.certifierAddress(),
      contractUri: (): Promise<string> => member.contractUri(),
      creator: (id: number): Promise<string> => member.creator(id),
      id: (account: string): Promise<number> => member.id(account),
      mint: (uri: string, index = 0, gasReq = this.gasReq): Promise<BlockchainResponse> =>
        member.mint(uri, index, gasReq),
      name: (): Promise<string> => member.name(),
      nominateNewOwner: (account: string, index = 0, gasReq = this.gasReq): Promise<BlockchainResponse> =>
        member.nominateNewOwner(account, index, gasReq),
      owner: (): Promise<string> => member.owner(),
      isOwner: (): Promise<string> => member.isOwner(),
      renounceOwnership: (index = 0, gasReq = this.gasReq): Promise<BlockchainResponse> =>
        member.renounceOwnership(index, gasReq),
      casterAddress: (): Promise<string> => member.seekerAddress(),
      setCertifierToken: (address: string, index = 0, gasReq = this.gasReq): Promise<BlockchainResponse> =>
        member.setCertifierToken(address, index, gasReq),
      setContractUri: (uri: string, index = 0, gasReq = this.gasReq): Promise<BlockchainResponse> =>
        member.setContractUri(uri, index, gasReq),
      setCasterToken: (address: string, index = 0, gasReq = this.gasReq): Promise<BlockchainResponse> =>
        member.setSeekerToken(address, index, gasReq),
      setTokenURIPrefix: (uri: string, index = 0, gasReq = this.gasReq): Promise<BlockchainResponse> =>
        member.setTokenURIPrefix(uri, index, gasReq),
      symbol: (): Promise<string> => member.symbol(),
      tokenURIPrefix: (): Promise<string> => member.tokenURIPrefix(),
      uri: (id: number): Promise<string> => member.uri(id)
    },
    certifier: {
      acceptOwnership: (index = 0, gasReq = this.gasReq): Promise<BlockchainResponse> => certifier.acceptOwnership(index, gasReq),
      allCertifiers: (): Promise<Array<number>> => certifier.allCertifiers(),
      balance: (account: string, id: number): Promise<number> => certifier.balance(account, id),
      balanceBatch: (accounts: Array<string>, ids: Array<number>): Promise<BlockchainResponse> => certifier.balanceBatch(accounts, ids),
      burn: (account: string, value: number, index = 0, gasReq = this.gasReq): Promise<BlockchainResponse> =>
        certifier.burn(account, value, index, gasReq),
      contractUri: (): Promise<string> => certifier.contractUri(),
      creator: (id: number): Promise<string> => certifier.creator(id),
      id: (account: string): Promise<number> => certifier.id(account),
      membershipAddress: (account: string): Promise<number> => certifier.membershipAddress(account),
      mint: (uri: string, index = 0, gasReq = this.gasReq): Promise<BlockchainResponse> =>
        certifier.mint(uri, index, gasReq),
      name: (): Promise<string> => certifier.name(),
      nominateNewOwner: (account: string, index = 0, gasReq = this.gasReq): Promise<BlockchainResponse> =>
        certifier.nominateNewOwner(account, index, gasReq),
      owner: (): Promise<string> => certifier.owner(),
      renounceOwnership: (index = 0, gasReq = this.gasReq): Promise<BlockchainResponse> =>
        certifier.renounceOwnership(index, gasReq),
      setContractUri: (uri: string, index = 0, gasReq = this.gasReq): Promise<BlockchainResponse> =>
        certifier.setContractUri(uri, index, gasReq),
      setTokenURIPrefix: (uri: string, index = 0, gasReq = this.gasReq): Promise<BlockchainResponse> =>
        certifier.setTokenURIPrefix(uri, index, gasReq),
      symbol: (): Promise<string> => certifier.symbol(),
      tokenURIPrefix: (): Promise<string> => certifier.tokenURIPrefix(),
      updateMembership: (address: string, index = 0, gasReq = this.gasReq): Promise<BlockchainResponse> =>
        certifier.updateMembership(address, index, gasReq),
      uri: (id: number): Promise<string> => certifier.uri(id)
    },
    caster: {
      acceptOwnership: (index = 0, gasReq = this.gasReq): Promise<BlockchainResponse> => caster.acceptOwnership(index, gasReq),
      allCasters: (): Promise<Array<number>> => caster.allSeekers(),
      balance: (account: string, id: number): Promise<number> => caster.balance(account, id),
      balanceBatch: (accounts: Array<string>, ids: Array<number>): Promise<BlockchainResponse> => caster.balanceBatch(accounts, ids),
      burn: (account: string, value: number, index = 0, gasReq = this.gasReq): Promise<BlockchainResponse> =>
        caster.burn(account, value, index, gasReq),
      contractUri: (): Promise<string> => caster.contractUri(),
      creator: (id: number): Promise<string> => caster.creator(id),
      id: (account: string): Promise<number> => caster.id(account),
      membershipAddress: (account: string): Promise<number> => caster.membershipAddress(account),
      mint: (uri: string, index = 0, gasReq = this.gasReq): Promise<BlockchainResponse> =>
        caster.mint(uri, index, gasReq),
      name: (): Promise<string> => caster.name(),
      nominateNewOwner: (account: string, index = 0, gasReq = this.gasReq): Promise<BlockchainResponse> =>
        caster.nominateNewOwner(account, index, gasReq),
      owner: (): Promise<string> => caster.owner(),
      renounceOwnership: (index = 0, gasReq = this.gasReq): Promise<BlockchainResponse> =>
        caster.renounceOwnership(index, gasReq),
      setContractUri: (uri: string, index = 0, gasReq = this.gasReq): Promise<BlockchainResponse> =>
        caster.setContractUri(uri, index, gasReq),
      setTokenURIPrefix: (uri: string, index = 0, gasReq = this.gasReq): Promise<BlockchainResponse> =>
        caster.setTokenURIPrefix(uri, index, gasReq),
      symbol: (): Promise<string> => caster.symbol(),
      tokenURIPrefix: (): Promise<string> => caster.tokenURIPrefix(),
      updateMembership: (address: string, index = 0, gasReq = this.gasReq): Promise<BlockchainResponse> =>
        caster.updateMembership(address, index, gasReq),
      uri: (id: number): Promise<string> => caster.uri(id)
    }
  };

  public mw = {
    acceptOwnership: (index = 0, gasReq = this.gasReq): Promise<BlockchainResponse> => mw.acceptOwnership(index, gasReq),
    accessible: (address: string): Promise<boolean> => mw.accessible(address),
    campaignContract: (): Promise<any> => mw.campaignContract(),
    claim: (type: FundReceiver, index = 0, gasReq = this.gasReq): Promise<BlockchainResponse> =>
      mw.claim(type, index, gasReq),
    claimableBalance: (type: FundReceiver, account: string): Promise<number> =>
      mw.claimableBalance(type, account),
    deMultiwallet: (account: string, type: FundReceiver, value: number, index = 0, gasReq = this.gasReq): Promise<BlockchainResponse> =>
      mw.deMultiwallet(account, type, value, index, gasReq),
    governanceTokenAddress: (): Promise<string> => mw.governanceTokenAddress(),
    multiwallet: (account: string, type: FundReceiver, value: number, index = 0, gasReq = this.gasReq): Promise<BlockchainResponse> =>
      mw.multiwallet(account, type, value, index, gasReq),
    balance: (type: FundReceiver, account: string): Promise<number> =>
      mw.multiwalletBalance(type, account),
    transfer: (options: MultiwalletTransferOptions): Promise<BlockchainResponse> =>
      mw.multiwalletTransfer(options.fromAccount, options.fromType, options.toAccount, options.toType, options.value,
        options.index = 0, options.gasReq = this.gasReq),
    nominateNewOwner: (account: string, index = 0, gasReq = this.gasReq): Promise<BlockchainResponse> =>
      mw.nominateNewOwner(account, index, gasReq),
    owner: (): Promise<string> => mw.owner(),
    renounceOwnership: (index = 0, gasReq = this.gasReq): Promise<BlockchainResponse> =>
      mw.renounceOwnership(index, gasReq),
    setAllowance: (address: string, value: number, index = 0, gasReq = this.gasReq): Promise<BlockchainResponse> =>
      mw.setAllowance(address, value, index, gasReq),
    setCampaignContract: (address: string, index = 0, gasReq = this.gasReq): Promise<BlockchainResponse> =>
      mw.setCampaignContract(address, index, gasReq),
    setGovernanceToken: (address: string, index = 0, gasReq = this.gasReq): Promise<BlockchainResponse> =>
      mw.setGovernanceToken(address, index, gasReq),
    unMultiwallet: (account: string, type: FundReceiver, value: number, index = 0, gasReq = this.gasReq): Promise<BlockchainResponse> =>
      mw.unMultiwallet(account, type, value, index, gasReq)
  };

  public entity = {
    all: (): Promise<any> => ent.allEntities(),
    campaignContract: (): Promise<string> => ent.campaignContract(),
    certificationContract: (): Promise<string> => ent.certificationContract(),
    create: (options: EntityOptions, index = 0, gasReq = this.gasReq): Promise<BlockchainResponse> => {
      const timestamp = (+new Date() / 1000).toFixed();
      return ent.createEntity(timestamp, options.title, options.type, options.description, options.metadata, options.account,
        index = 0, gasReq = this.gasReq);
    },
    delete: (entityId: number, account: string, index = 0, gasReq = this.gasReq): Promise<BlockchainResponse> =>
      ent.deleteEntity(entityId, account, index, gasReq),
    edit: (options: EditEntityOptions): Promise<any> =>
      ent.editEntity(options.entityId, options.title, options.description, options.metadata, options.account,
        options.index = 0, this.gasReq),
    exists: (entityId: number): Promise<boolean> => ent.entityExists(entityId),
    get: (entityId: number): Promise<any> => ent.getEntity(entityId),
    owner: (): Promise<string> => ent.owner(),
    casterContract: (): Promise<string> => ent.seekerContract(),
    casterEntities: (account: string): Promise<any> => ent.seekerEntities(account),
    setCampaignContractAddress: (address: string, index = 0, gasReq = this.gasReq): Promise<BlockchainResponse> =>
      ent.setCampaignContractAddress(address, index, gasReq),
    setCertification: (address: string, index = 0, gasReq = this.gasReq): Promise<BlockchainResponse> =>
      ent.setCertification(address, index, gasReq),
    updateCasterContract: (address: string, index = 0, gasReq = this.gasReq): Promise<BlockchainResponse> =>
      ent.updateSeekerContract(address, index, gasReq)
  };

  public manager = {
    acceptOwnership: (index = 0, gasReq = this.gasReq): Promise<any> => mgr.acceptOwnership(index, gasReq),
    burnToken: (account: string, value: number, index = 0, gasReq = this.gasReq): Promise<BlockchainResponse> =>
      mgr.burnToken(account, value, index, gasReq),
    buy: (minReceived: number | string, amount: number | string, index = 0, gasReq = this.gasReq): Promise<BlockchainResponse> =>
      mgr.buy(minReceived, amount, index, gasReq),
    calculateBuyAmount: (amount: string): Promise<number> => mgr.calculateBuyAmount(amount),
    decimals: (): Promise<number> => mgr.decimals(),
    giveSupply: (reserveSupply: number, initialSupply: number, index = 0, gasReq = this.gasReq): Promise<BlockchainResponse> =>
      mgr.giveSupply(reserveSupply, initialSupply, index, gasReq),
    govAddress: (): Promise<string> => mgr.govAddress(),
    initialSupplyGiven: (): Promise<boolean> => mgr.initialSupplyGiven(),
    membershipTokenAddress: (): Promise<string> => mgr.membershipTokenAddress(),
    mintToken: (account: string, value: number, index = 0, gasReq = this.gasReq): Promise<BlockchainResponse> =>
      mgr.mintToken(account, value, index, gasReq),
    nominateNewOwner: (account: string, index = 0, gasReq = this.gasReq): Promise<BlockchainResponse> =>
      mgr.nominateNewOwner(account, index, gasReq),
    owner: (): Promise<string> => mgr.owner(),
    renounceOwnership: (index = 0, gasReq = this.gasReq): Promise<BlockchainResponse> =>
      mgr.renounceOwnership(index, gasReq),
    tokenAddress: (): Promise<string> => mgr.tokenAddress(),
    updateGovernanceAddress: (address: string, index = 0, gasReq = this.gasReq): Promise<BlockchainResponse> =>
      mgr.updateGovernanceAddress(address, index, gasReq),
    updateMembershipTokenAddress: (address: string, index = 0, gasReq = this.gasReq): Promise<BlockchainResponse> =>
      mgr.updateMembershipTokenAddress(address, index, gasReq),
    updateTokenAddress: (address: string, index = 0, gasReq = this.gasReq): Promise<BlockchainResponse> =>
      mgr.updateTokenAddress(address, index, gasReq)
  };

  public token = {
    acceptOwnership: (index = 0, gasReq = this.gasReq): Promise<BlockchainResponse> => token.acceptOwnership(index, gasReq),
    allowanceOf: (owner: string, spender: string): Promise<number> => token.allowanceOf(owner, spender),
    approve: (spender: string, value: number | string, index = 0, gasReq = this.gasReq): Promise<any> =>
      token.approve(spender, value, index, gasReq),
    balanceOf: (account: string): Promise<number> => token.balanceOf(account),
    decimals: (): Promise<number | string> => token.decimals(),
    decreaseAllowance: (spender: string, amount: number, index = 0, gasReq = this.gasReq): Promise<BlockchainResponse> =>
      token.decreaseAllowance(spender, amount, index, gasReq),
    increaseAllowance: (spender: string, amount: number, index = 0, gasReq = this.gasReq): Promise<BlockchainResponse> =>
      token.increaseAllowance(spender, amount, index, gasReq),
    lastSnapshot: (account: string): Promise<number> => token.lastSnapshot(account),
    lockedBalanceOf: (account: string): Promise<number> => token.lockedBalanceOf(account),
    name: (): Promise<string> => token.name(),
    nominateNewOwner: (account: string, index = 0, gasReq = this.gasReq): Promise<BlockchainResponse> =>
      token.nominateNewOwner(account, index, gasReq),
    owner: (): Promise<string> => token.owner(),
    renounceOwnership: (index = 0, gasReq = this.gasReq): Promise<any> =>
      token.renounceOwnership(index, gasReq),
    setAddressValidation: (address: string, value: boolean, index = 0, gasReq = this.gasReq): Promise<BlockchainResponse> =>
      token.setAddressValidation(address, value, index, gasReq),
    setTokenManager: (address: string, index = 0, gasReq = this.gasReq): Promise<any> =>
      token.setTokenManager(address, index, gasReq),
    snapshot: (account: string, id: number): Promise<number> => token.snapshot(account, id),
    symbol: (): Promise<string> => token.symbol(),
    tokenManager: (): Promise<string> => token.tokenManager(),
    totalSupply: (): Promise<number> => token.totalSupply(),
    transfer: (recipient: string, amount: number, index = 0, gasReq = this.gasReq): Promise<BlockchainResponse> =>
      token.transfer(recipient, amount, index, gasReq),
    transferFrom: (sender: string, recipient: string, amount: number, index = 0, gasReq = this.gasReq): Promise<BlockchainResponse> =>
      token.transferFrom(sender, recipient, amount, index, gasReq),
    transferFromUnlock: (sender: string, recipient: string, amount: number, index = 0, gasReq = this.gasReq): Promise<BlockchainResponse> =>
      token.transferFromUnlock(sender, recipient, amount, index, gasReq),
    transferUnlock: (recipient: string, amount: number, index = 0, gasReq = this.gasReq): Promise<BlockchainResponse> =>
      token.transferUnlock(recipient, amount, index, gasReq),
    unlockedBalanceOf: (account: string): Promise<number> => token.unlockedBalanceOf(account),
    validateAddress: (account: string): Promise<boolean> => token.ValidateAddress(account),
    writeSnapshot: (index = 0, gasReq = this.gasReq): Promise<BlockchainResponse> => token.writeSnapshot(index, gasReq)
  };

  public marketplace = {
    acceptOwnership: (index = 0, gasReq = this.gasReq): Promise<BlockchainResponse> => mark.acceptOwnership(index, gasReq),
    adClickedBatch: (adIds: Array<number>, index = 0, gasReq = this.gasReq): Promise<BlockchainResponse> =>
      mark.adClickedBatch(adIds, index, gasReq),
    adExpiredBatch: (adIds: Array<number>, numerator: number,
                     denominator: number, index = 0, gasReq = this.gasReq): Promise<BlockchainResponse> =>
      mark.adExpiredBatch(adIds, numerator, denominator, index, gasReq),
    ads: (adId: number): Promise<any> => mark.ads(adId),
    adServedBatch: (options: AdServedBatchOptions): Promise<BlockchainResponse> =>
      mark.adServedBatch(options.members, options.campaignIds, options.certifiers, options.certifierWeights,
        options.index = 0, options.gasReq = this.gasReq),
    allAdIds: (): Promise<any> => mark.allAdIds(),
    campaignAddress: (): Promise<any> => mark.campaignAddress(),
    checkEarnedRewards: (adId: number, type: FundReceiver, reciever: string): Promise<BlockchainResponse> =>
      mark.checkEarnedRewards(adId, type, reciever),
    devfundAddress: (): Promise<any> => mark.devfundAddress(),
    governanceTokenAddress: (): Promise<any> => mark.governanceTokenAddress(),
    multiwalletContract: (): Promise<any> => mark.MultiWalletContract(),
    nominateNewOwner: (account: string, index = 0, gasReq = this.gasReq): Promise<BlockchainResponse> =>
      mark.nominateNewOwner(account, index, gasReq),
    owner: (): Promise<any> => mark.owner(),
    pbcLicenseAddress: (): Promise<any> => mark.pbcLicenseAddress(),
    precision: (): Promise<any> => mark.precision(),
    renounceOwnership: (index = 0, gasReq = this.gasReq): Promise<BlockchainResponse> =>
      mark.renounceOwnership(index, gasReq),
    rewardShare: (recipient: FundReceiver): Promise<any> => mark.rewardShare(recipient),
    totalShare: (): Promise<any> => mark.totalShare(),
    treasuryAddress: (): Promise<any> => mark.treasuryAddress(),
    updateCampaignAddress: (address: string, index = 0, gasReq = this.gasReq): Promise<BlockchainResponse> =>
      mark.updateCampaignAddress(address, index, gasReq),
    updateDevfundAddress: (address: string, index = 0, gasReq = this.gasReq): Promise<BlockchainResponse> =>
      mark.updateDevfundAddress(address, index, gasReq),
    updateMultiwalletContract: (address: string, index = 0, gasReq = this.gasReq): Promise<BlockchainResponse> =>
      mark.updateMultiwalletContract(address, index, gasReq),
    updatePBCAddress: (address: string, index = 0, gasReq = this.gasReq): Promise<BlockchainResponse> =>
      mark.updatePBCAddress(address, index, gasReq),
    updateRewardShare: (type: FundReceiver, share: number, index = 0, gasReq = this.gasReq): Promise<BlockchainResponse> =>
      mark.updateRewardShare(type, share, index, gasReq),
    updateTokenAddress: (address: string, index = 0, gasReq = this.gasReq): Promise<BlockchainResponse> =>
      mark.updateTokenAddress(address, index, gasReq),
    updateTreasuryAddress: (address: string, index = 0, gasReq = this.gasReq): Promise<BlockchainResponse> =>
      mark.updateTreasuryAddress(address, index, gasReq),
  };

  public campaign = {
    acceptOwnership: (index = 0, gasReq = this.gasReq): Promise<BlockchainResponse> => camp.acceptOwnership(index, gasReq),
    add: (options: CampaignOptions): Promise<BlockchainResponse> => {
      const timestamp = (+new Date() / 1000).toFixed();
      return camp.addCampaign(timestamp, options.title, options.content, options.imageUrl, options.descriptionUrl, options.metadata,
        options.target, options.amountPerAd, 0, this.gasReq)
    }
     ,
    all: (): Promise<any> => camp.all(),
    checkPaused: (campaignId: number): Promise<any> => camp.checkPaused(campaignId),
    delete: (campaignId: number, index = 0, gasReq = this.gasReq): Promise<BlockchainResponse> =>
      camp.deleteCampaign(campaignId, index, gasReq),
    edit: (options: CampaignEditOptions): Promise<any> =>
      camp.editCampaign(options.campaignId, options.target, options.title, options.content, options.imageUrl,
        options.descriptionUrl, options.metadata, options.index = 0, options.gasReq = this.gasReq),
    entityContract: (): Promise<any> => camp.entityContract(),
    fund: (campaignIds: Array<string | number>, replenishes: Array<number | string>, index = 0, gasReq = this.gasReq): Promise<BlockchainResponse> =>
      camp.fundCampaign(campaignIds, replenishes, index, gasReq),
    get: (campaignId: number): Promise<any> => camp.getCampaign(campaignId),
    marketPlace: (): Promise<any> => camp.marketPlace(),
    multiwalletContract: (): Promise<any> => camp.multiwalletContract(),
    nominateNewOwner: (account: string, index = 0, gasReq = this.gasReq): Promise<BlockchainResponse> =>
      camp.nominateNewOwner(account, index, gasReq),
    owner: (): Promise<any> => camp.owner(),
    renounceOwnership: (index = 0, gasReq = this.gasReq): Promise<BlockchainResponse> =>
      camp.renounceOwnership(index, gasReq),
    casterCampaigns: (account: string): Promise<any> => camp.seekerCampaigns(account),
    casterTokenContract: (): Promise<any> => camp.seekerTokenContract(),
    setPaused: (campaignId: number, value: boolean, index = 0, gasReq = this.gasReq): Promise<BlockchainResponse> =>
      camp.setPaused(campaignId, value, index, gasReq),
    tokenContract: (): Promise<any> => camp.tokenContract(),
    updateEntity: (address: string, index = 0, gasReq = this.gasReq): Promise<BlockchainResponse> =>
      camp.updateEntity(address, index, gasReq),
    updateMarketplaceController: (address: string, index = 0, gasReq = this.gasReq): Promise<BlockchainResponse> =>
      camp.updateMarketplaceController(address, index, gasReq),
    updateMultiwallet: (address: string, index = 0, gasReq = this.gasReq): Promise<BlockchainResponse> =>
      camp.updateMultiwallet(address, index, gasReq),
    updateCasterToken: (address: string, index = 0, gasReq = this.gasReq): Promise<BlockchainResponse> =>
      camp.updateSeekerToken(address, index, gasReq),
    updateToken: (address: string, index = 0, gasReq = this.gasReq): Promise<BlockchainResponse> =>
      camp.updateToken(address, index, gasReq),
    withdrawFunds: (campaignId: number, value: number, index = 0, gasReq = this.gasReq): Promise<BlockchainResponse> =>
      camp.withdrawFunds(campaignId, value, index, gasReq)
  };

  public certification = {
    acceptOwnership: (index = 0, gasReq = this.gasReq): Promise<BlockchainResponse> => cert.acceptOwnership(index, gasReq),
    all: (): Promise<any> => cert.allCertifications(),
    applied: (certificationId: number): Promise<any> => cert.appliedCertifications(certificationId),
    apply: (certificationId: number, entityId: number, index = 0, gasReq = this.gasReq): Promise<BlockchainResponse> => {
      return cert.applyCertification(certificationId, entityId, index, gasReq);
    },
    exists: (certificationId: number): Promise<boolean> => cert.certificationExists(certificationId),
    certifications: (certificationId: number): Promise<any> => cert.certifications(certificationId),
    score: (certificationId: number, entityId: number): Promise<any> =>
      cert.certificationScore(certificationId, entityId),
    certifiedEntities: (certificationId: number): Promise<any> => cert.certifiedEntities(certificationId),
    certifierAddress: (): Promise<any> => cert.certifierAddress(),
    createDynamic: (options: DynamicCertificationOptions, index = 0, gasReq = this.gasReq): Promise<BlockchainResponse> => {
      const timestamp = (+new Date() / 1000).toFixed();
      return cert.createDynamicCertification(timestamp, options.type, options.metadata,
        options.title, options.description, options.apiLink, index, gasReq);
    },
    createStatic: (options: StaticCertificationOptions, index = 0, gasReq = this.gasReq): Promise<BlockchainResponse> => {
      const timestamp = (+new Date() / 1000).toFixed();
      return cert.createStaticCertification(timestamp, options.type, options.metadata, options.title, options.description, index, gasReq);
      // return cert.createStaticCertification(1635483980, 2, 'metadata sample', 'title', 'des', index, gasReq);
    },
    delete: (ids: Array<number>, index = 0, gasReq = this.gasReq): Promise<BlockchainResponse> =>
      cert.deleteCertification(ids, index, gasReq),
    dynamicLink: (certificationId: number): Promise<any> => cert.dynamicCertificateLink(certificationId),
    dynamic: (certificationId: number): Promise<any> => cert.dynamicCertification(certificationId),
    edit: (options: EditCertificationOptions): Promise<BlockchainResponse> => {
      return cert.editCertification(options.id, options.title, options.description,
        options.metadata, options.index, options.gasReq);
    },
    entityAddress: (): Promise<any> => cert.entityAddress(),
    entityCertifications: (entityId: number): Promise<any> => cert.entityCertifications(entityId),
    membershipAddress: (): Promise<any> => cert.membershipAddress(),
    nominateNewOwner: (address: string, index = 0, gasReq = this.gasReq): Promise<BlockchainResponse> =>
      cert.nominateNewOwner(address, index, gasReq),
    owner: (): Promise<any> => cert.owner(),
    renounceOwnership: (index = 0, gasReq = this.gasReq): Promise<BlockchainResponse> =>
      cert.renounceOwnership(index, gasReq),
    static: (certificationId: number): Promise<any> => cert.staticCertification(certificationId),
    subscribe: (ids: Array<number | string>, index = 0, gasReq = this.gasReq): Promise<BlockchainResponse> =>
      cert.subscribeCertification(ids, index, gasReq),
    subscribers: (certificationId: number): Promise<any> => cert.subscribers(certificationId),
    unsubscribe: (ids: Array<number | string>, index = 0, gasReq = this.gasReq): Promise<BlockchainResponse> =>
      cert.unsubscribeCertification(ids, index, gasReq),
    updateCertifierContract: (address: string, index = 0, gasReq = this.gasReq): Promise<BlockchainResponse> =>
      cert.updateDynamicContract(address, index, gasReq),
    updateDynamicEntity: (certificationId: number, link: string, index = 0, gasReq = this.gasReq): Promise<BlockchainResponse> =>
      cert.updateDynamicEntity(certificationId, link, index, gasReq),
    updateEntityContract: (address: string, index = 0, gasReq = this.gasReq): Promise<BlockchainResponse> =>
      cert.updateEntityContract(address, index, gasReq),
    updateMembershipContract: (address: string, index = 0, gasReq = this.gasReq): Promise<BlockchainResponse> =>
      cert.updateMembershipContract(address, index, gasReq),
    updateStaticEntity: (certificationId: number, entityIds: Array<number>,
                         scores: Array<number>, index = 0, gasReq = this.gasReq): Promise<BlockchainResponse> =>
      cert.updateStaticEntity(certificationId, entityIds, scores, index, gasReq),
  };

  public util = util;
  public ent = ent;

  constructor(private intercom: IntercomService) {
    this.setEventHandlers();
  }

  setEventHandlers(): void {
    if (this.isMetamaskEnabled()) {
      const web3 = this.util.getWeb3();
      web3.givenProvider.on('accountsChanged', (accounts: Array<string>) => {
        if (!accounts.length) {
          this.intercom.broadcast({
            event: BroadcastEvent.METAMASK_DISCONNECTED
          });
        } else {
          this.intercom.broadcast({
            event: BroadcastEvent.METAMASK_CONNECTED,
            data: accounts[0]
          });
        }
      });
    }
  }

  getNFTUri(memberType: MemberType, walletAddress: string): string {
    // return `${environment.apiBaseUrl}nft/${memberType}/${walletAddress}`;
    // TODO: Get a proper NFT URI when the BE is ready
    return `https://c4p4t4q5ahlk5akbqbchqhm3rnqktyshkn5jqdhposuq5ivvracq.arweave.net/Fx_J8h0B1q6BQYBEeB2bi2Cp4kdTepgM73SpDqK1iAU`;
  }

  isMetamaskEnabled(): boolean {
    const web3 = this.util.getWeb3();
    return web3.givenProvider !== null;
  }

  async connect(): Promise<any> {
    return await this.util.connectMetamask();
  }

  async getAccount(): Promise<any> {
    return this.util.getAccount();
  }

  async getAccountAddress(): Promise<any> {
    return this.util.getAccountAddress();
  }

  async getNetwork(): Promise<any> {
    const web3 = this.util.getWeb3();
    return await web3.eth.net.getNetworkType();
  }

  async isNetworkSelected(): Promise<boolean> {
    const web3 = this.util.getWeb3();
    const response = await web3.currentProvider.request({
      method: 'eth_chainId'
    });
    if (environment.network === 'dev') {
      if (parseInt(response, 16) === 43113) {
        return Promise.resolve(true);
      } else {
        return Promise.resolve(false);
      }
    } else {
      if (parseInt(response, 16) === 43114) {
        return Promise.resolve(true);
      } else {
        return Promise.resolve(false);
      }
    }
  }

  async addAvalancheNetwork(): Promise<any> {
    if (environment.network === 'dev') {
      return arkius.util.addAvalancheTestNet();
    } else {
      return arkius.util.addAvalancheMainNet();
    }
  }

  async disconnect(): Promise<void> {

  }

  // Membership

  async isMember(address: string): Promise<boolean> {
    const memberId = await this.roles.member.id(address);
    return !!parseInt(memberId.toString(), 10);
  }

  async getMemberNftId(address: string): Promise<number> {
    const memberId = await this.roles.member.id(address);
    return parseInt(memberId.toString(), 10);
  }

  async isCertifier(address: string): Promise<boolean> {
    const memberId = await this.roles.certifier.id(address);
    return !!parseInt(memberId.toString(), 10);
  }

  async isCaster(address: string): Promise<boolean> {
    const memberId = await this.roles.caster.id(address);
    return !!parseInt(memberId.toString(), 10);
  }

  async createMember(address: string): Promise<any> {
    try {
      const receipt: BlockchainResponse = await this.roles.member.mint(this.getNFTUri('member', address));
      return receipt.events['TransferSingle'].returnValues['id'];
    } catch (e) {
      return Promise.reject(e);
    }
  }

  async revokeMember(address: string): Promise<any> {
    try {
      return await this.roles.member.burn(address, 1);
    } catch (e) {
      return Promise.reject(e);
    }
  }

  async createCertifier(address: string): Promise<any> {
    try {
      return await this.roles.certifier.mint(this.getNFTUri('certifier', address));
    } catch (e) {
      return Promise.reject(e);
    }
  }

  async revokeCertifier(address: string): Promise<any> {
    try {
      return await this.roles.certifier.burn(address, 1);
    } catch (e) {
      return Promise.reject(e);
    }
  }

  async createCaster(address: string): Promise<any> {
    try {
      return await this.roles.caster.mint(this.getNFTUri('caster', address));
    } catch (e) {
      return Promise.reject(e);
    }
  }

  async revokeCaster(address: string): Promise<any> {
    try {
      return await this.roles.caster.burn(address, 1);
    } catch (e) {
      return Promise.reject(e);
    }
  }

  async sign(nonce: string, address: string): Promise<string> {
    const web3 = arkius.util.getWeb3();
    const response = await web3.currentProvider.request({
      method: 'personal_sign',
      params: [
        nonce,
        address
      ]
    });
    if (response) {
      return Promise.resolve(response);
    } else {
      return Promise.reject();
    }
  }

  // General

  getReceiptId(receipt: BlockchainResponse): string {
    return receipt.events[Object.keys(receipt.events)[0]].returnValues['id'].toString();
  }

  async createProduct(productOptions: EntityOptions): Promise<any> {
    try {
      const receipt: BlockchainResponse = await this.entity.create(productOptions);
      return receipt.events['CreateEntity'].returnValues['id'];
    }
    catch (e) {
      return Promise.reject(e);
    }
  }


  async updateProduct(productOptions: EditEntityOptions): Promise<any> {
    try {
      const receipt: BlockchainResponse = await this.entity.edit(productOptions);
      return receipt.events['EditEntity'].returnValues['id'];
    }
    catch (e) {
      return Promise.reject(e);
    }
  }
}
