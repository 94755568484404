<div class="page-toolbar">
  <div class="toolbar-left">
    <ark-input-expand *ngIf="showSearch" [label]="'Search'" iconColor="dark" iconSize="large" (valueChanged)="onSearchTextChange($event)">
    </ark-input-expand>
  </div>
  <div class="toolbar-right">
    <div class="toolbar-cou" *ngIf="pageView === 'proposals'">
      <span>{{'PAGES.CATEGORY_DETAILS.FILTER_BAR.SORT_BY.LABEL' | translate}}</span>
      <ark-dropdown [showBorder]="false" [showBackground]="false" [items]="sortBy"
        (itemSelected)="setOrderFilter($event)" iconSize="tiny" size="small"></ark-dropdown>
    </div>
    <div class="toolbar-cou" *ngIf="pageView !== 'proposals'">
      <span>{{'PAGES.CATEGORY_DETAILS.FILTER_BAR.ORDER_BY.LABEL' | translate}}</span>
      <ark-dropdown [showBorder]="false" [showBackground]="false" [items]="orderBy"
        (itemSelected)="setOrderFilter($event)" iconSize="tiny" size="small"></ark-dropdown>
    </div>
  </div>
</div>
