import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PostComment, PostMetaData, PostVotes} from '@interfaces/forum.interface';
import {UtilService} from '@services/util.service';

@Component({
  selector: 'ark-post-action-bar',
  templateUrl: './post-action-bar.component.html',
  styleUrls: ['./post-action-bar.component.scss']
})
export class PostActionBarComponent implements OnInit {

  @Input() metadata!: PostMetaData | PostComment;
  @Input() votes!: PostVotes;
  @Input() expanded: boolean | undefined = false;
  @Input() comment = false;
  @Input() displayVotes = true;
  @Output() toggleExpand: EventEmitter<boolean> = new EventEmitter();
  @Output() userVote: EventEmitter<boolean> = new EventEmitter();
  @Output() toggleReply: EventEmitter<null> = new EventEmitter();

  constructor(public util: UtilService) { }

  ngOnInit(): void {
    this.expanded = this.metadata.commentsExpanded;
  }

  reply(): void {
    this.toggleReply.emit();
  }

  doVote(vote: boolean): void {
    this.userVote.emit(vote);
  }

  toggleComments(): void {
    this.expanded = !this.expanded;
    this.toggleExpand.emit(this.expanded);
  }
}
