<div class="onboardingContainer">
  <div class="left">
    <div class="onboardingImage oi-1" *ngIf="currentStep === 1" [@fadeInAnimation]=""></div>
    <div class="onboardingImage oi-2" *ngIf="currentStep === 2" [@fadeInAnimation]=""></div>
    <div class="onboardingImage oi-3" *ngIf="currentStep === 3" [@fadeInAnimation]=""></div>
    <div class="onboardingImage oi-1 hidden"></div>
    <div class="onboardingImage oi-2 hidden"></div>
    <div class="onboardingImage oi-3 hidden"></div>
  </div>
  <div class="right">
    <div class="close">
      <ark-icon (click)="close()" icon="system-close" size="small" *ngIf="hasCompleted" [@fadeInAnimation]=""></ark-icon>
    </div>
    <div class="content">
      <div class="card" *ngIf="currentStep === 1" [@slideInSameAnimation]="">
        <h3>Welcome {{firstName}}</h3>
        <div>
          As a new Values Movement member, you’re joining a global community of conscientious buyers who are ready to create and deliver change.<br><br>
          <strong>What is really important to you?</strong><br>
          Your values matter! Get ready to share your ideas and discuss what’s truly important with others.
        </div>
      </div>
      <div class="card" *ngIf="currentStep === 2" [@slideInSameAnimation]="">
        <h3>Certifications reflect our values</h3>
        <div>
          Help build a new values-driven economy by sharing ideas, discovering new ones, and supporting Certifications as they come to life.<br><br>
          <strong>Here's how...</strong><br>
          1. Start a discussion on a Certification of interest<br>
          2. Choose which ideas are best<br>
          3. Back those ideas using VALU credits
        </div>
      </div>
      <div class="card" *ngIf="currentStep === 3" [@slideInSameAnimation]="">
        <h3>Member benefits</h3>
        <div>
          All packages include VALU credits you use within the Values Movement to back projects that matter to you. Additionally, you choose a unique piece of art in the form of an NFT, which will appear in your profile and also act as a rewards multiplier.<br><br>
          <strong>What is an NFT?</strong><br>
          NFTs (short for Non-Fungible Tokens) are digital items that you own. Proof of ownership is stored in your Magic wallet.
          <div class="learnMore" (click)="learnMore()">
            <span>Learn more</span>
            <ark-icon icon="system-external-link" color="dark" size="tiny"></ark-icon>
          </div>
        </div>
      </div>
    </div>
    <div class="pagination">
      <div class="arrow" (click)="prev()">
        <ark-icon icon="system-left" size="tiny" [color]="currentStep === 1 ? 'disabled' : 'dark'"></ark-icon>
      </div>
      <div class="numbering">{{currentStep}} / 3</div>
      <div class="arrow" (click)="next()">
        <ark-icon icon="system-right" size="tiny" [color]="currentStep === 3 ? 'disabled' : 'dark'"></ark-icon>
      </div>
    </div>
  </div>
</div>
