import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import { ProposalActionComponent } from '@app/components/modals/proposals/proposal-action/proposal-action.component';
import { DropDownItem, ModalSize } from '@app/etc/interfaces/common.interface';
import { ModalService } from '@app/services/modal.service';
import {IProposalItem} from '@interfaces/proposal.interfase';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import {ProposalModelService} from '@models/proposal-model.service';
import {IntercomService} from '@services/intercom.service';
import {BroadcastEvent} from '@interfaces/broadcast.interface';

@Component({
  selector: 'ark-proposals-item',
  templateUrl: './proposals-item.component.html',
  styleUrls: ['./proposals-item.component.scss']
})
export class ProposalsItemComponent implements OnInit {

  @Input()
  public item!: IProposalItem;
  @Input()
  public list: boolean = true;

  public status: string = '';

  public moreItems: DropDownItem[] = [
    {
      label: 'Edit',
      id: 'edit',
      icon: 'system-edit'
    },
    {
      label: 'Share',
      id: 'share',
      icon: 'message-share'
    },
    {
      label: 'Delete',
      id: 'delete',
      icon: 'system-trash',
      color: 'error'
    }
  ];

  public supportedMoreItems: DropDownItem[] = [
    {
      label: 'Edit backing',
      id: 'edit-backing',
      icon: 'system-undo'
    },
    {
      label: 'Share',
      id: 'share',
      icon: 'message-share'
    }
  ];


  constructor(
    private modalService: ModalService,
    protected readonly toastService: ToastrService,
    private proposalService: ProposalModelService,
    private intercom: IntercomService,
    private translate: TranslateService,
  ) {
  }

  async ngOnInit(): Promise<void> {
    this.status = this.item.locked
      ? 'PAGES.CATEGORY_DETAILS.PROPOSALS.ITEM.DETAILS.STATUS_LOCKED'
      : 'PAGES.CATEGORY_DETAILS.PROPOSALS.ITEM.DETAILS.STATUS';
  }

  public moreSelected(item: DropDownItem): void {
    switch (item.id) {
      case 'delete':
        this.proposalService.proposalDelete(this.item.certificationRequestId).then(() => {
          this.intercom.broadcast({event: BroadcastEvent.PROPOSAL_DELETED,});
        });
        // this.toastService.info(this.translate.instant('PAGES.CAMPAIGNS.ITEM.TOAST_MSG.CAMPAIGN_ID'), '', {positionClass: 'toast-bottom-center'});
        break;
      case 'share':
        const str = window.location.protocol + '//' + window.location.host + '/proposal/' + this.item.certificationRequestId;
        navigator.clipboard.writeText(str).then(
          () => {
            console.info('copied', this.item.certificationRequestId);
            this.toastService.info('Link copied', '', {positionClass: 'toast-bottom-center'});
          }
        );
        break;
      case 'edit-backing':
        // this.toastService.info(this.translate.instant('PAGES.CAMPAIGNS.ITEM.TOAST_MSG.METADATA'), '', {positionClass: 'toast-bottom-center'});
      break;
      case 'edit':
            this.modalService.open(ProposalActionComponent, {
              style: 'dark',
              size: ModalSize.DYNAMIC,
              data: {
                categoryId: this.item.certificationCategoryId,
                edit: true,
                item: this.item,
                entityCategories: this.item.entityCategories
              }
            })
        break;
      default:
        break;
    }
  }
}
