import { Component } from '@angular/core';
import {ModalComponent} from '@components/common/modal/modal.component';

@Component({
  selector: 'ark-proposal-backed',
  templateUrl: './proposal-backed.component.html',
  styleUrls: ['./proposal-backed.component.scss']
})
export class ProposalBackedComponent {

  public mode: string = 'backed';
  constructor(protected readonly parent: ModalComponent) {
    this.mode = parent.data.mode;
  }
}
