<div class="navigation-wrapper" *ngIf="!navigationHidden">
  <div class="left" (click)="dashboard()">
    <div class="logo"></div>
  </div>
  <div class="right">
    <div class="nft" *ngIf="nft">
      <div class="text typo-body-regular"><strong>Package</strong> {{selectedPackage}}</div>
      <ark-button type="secondary" size="small" (click)="gotoPackages()">CHANGE</ark-button>
    </div>
    <ark-button class="invitation-request" size="medium" *ngIf="!loggedIn" (click)="requestInvitation()">Request invitation</ark-button>
    <ark-button size="small" *ngIf="!loggedIn" (click)="logOut()">LOG IN</ark-button>

    <div class="menu" *ngIf="!menuHidden && loggedIn">
      <div class="menu-item" routerLink="/admin/surveys" *ngIf="navigationAdmin">
        <ark-icon icon="system-posts" color="error" strokeWidth="3" size="large"></ark-icon>
        <div class="description typo-body-xsmall">Surveys</div>
      </div>
      <div class="menu-item" routerLink="/admin/invitations" *ngIf="navigationAdmin">
        <ark-icon icon="system-email" color="error" strokeWidth="3" size="large"></ark-icon>
        <div class="description typo-body-xsmall">Invitations</div>
      </div>
      <div class="menu-item" routerLink="/admin/categories" *ngIf="navigationAdmin">
        <ark-icon icon="system-flag" color="error" strokeWidth="1" size="large"></ark-icon>
        <div class="description typo-body-xsmall">Categories</div>
      </div>
      <div class="menu-item" (click)="dashboard()">
        <ark-icon icon="navigation-home" color="dark" size="large"></ark-icon>
        <div class="description typo-body-xsmall">Home</div>
      </div>
      <div class="menu-item" (click)="supported()">
        <ark-icon icon="navigation-supported" color="dark" size="large"></ark-icon>
        <div class="description typo-body-xsmall">Supported</div>
      </div>
      <div class="menu-item" (click)="notifications()">
        <ark-icon icon="system-notifications" *ngIf="notificationsCount == 0" color="dark" size="large"></ark-icon>
        <ark-icon icon="system-notifications-alert" *ngIf="notificationsCount > 0" color="dark" size="large"></ark-icon>
        <div class="description typo-body-xsmall">{{notificationsCount}}</div>
      </div>
      <div class="menu-item" (click)="toggleProfile()">
        <ark-icon icon="navigation-profile-black" color="dark" size="large"></ark-icon>
        <div class="description typo-body-xsmall">Profile</div>
      </div>
      <div class="profile-arrow-wrapper" (click)="toggleProfile()">
        <ark-icon class="profile-arrow" icon="navigation-arrow-down" color="dark" size="tiny"></ark-icon>
        <div class="profile-popup" [delayClickOutsideInit]="true" (clickOutside)="openProfile = false"
             *ngIf="openProfile">
          <div class="popup-item" routerLink="/profile">
            <ark-icon icon="navigation-profile-black" color="dark" size="medium"></ark-icon>
            <div class="item-text typo-body-regular">My profile</div>
          </div>
          <a class="popup-item" download id='nft-img' [href]="nftUrl">
            <ark-icon icon="system-download" color="dark" size="medium"></ark-icon>
            <div class="item-text typo-body-regular">Download member badge</div>
          </a>
          <a class="popup-item" href="mailto:hello@valuesmovement.org">
            <ark-icon icon="navigation-contact" color="dark" size="medium"></ark-icon>
            <div class="item-text typo-body-regular">Get in touch</div>
          </a>
          <a class="popup-item" href="javascript:void 0" (click)="settings()">
            <ark-icon icon="profile-settings" color="dark" size="medium"></ark-icon>
            <div class="item-text typo-body-regular">Settings</div>
          </a>
          <div class="hr"></div>
          <div class="popup-item" (click)="logOut()">
            <ark-icon icon="profile-log-out" color="dark" size="medium"></ark-icon>
            <div class="item-text typo-body-regular">Log out</div>
          </div>
          <div class="links typo-body-small">
            <a href="https://www.valuesmovement.org/privacy-policy" target="_blank">Privacy Policy</a>
            · <a href="https://www.valuesmovement.org/terms-of-use" target="_blank">Terms of Use</a>
            <br>©2022 Values Movement
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
