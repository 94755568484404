<div class="wrapper--value-modal">
    <div class="content" *ngIf="label && description">
        <span class="label">{{label}}</span>
        <span class="description">{{description}}</span>
    </div>
    <div class="value-modal-controls">
        <div class="icon" [class.disabled]="disabled" (click)="decrease()">
            <ark-icon [icon]="minusIcon" [color]="minusIconColor" [size]="minusIconSize"></ark-icon>
        </div>
        <span class="value">{{value}}</span>
        <div class="icon" [class.disabled]="disabled" (click)="increase()">
            <ark-icon [icon]="plusIcon" [color]="plusIconColor" [size]="plusIconSize"></ark-icon>
        </div>
    </div>
</div>