export enum ViewTypes {
  All = 'ALL',
  Subscribed = 'SUBSCRIBED',
  Reviewed = 'REVIEWED',
  Created = 'CREATED'
}

export enum OrderByTypes {
  TitleAz = 'TITLE_A_Z',
  TitleZa = 'TITLE_Z_A',
  DescriptionAz = 'DESCRIPTION_A_Z',
  DescriptionZa = 'DESCRIPTION_Z_A'
}


export enum SortByTypes {
  MostRecent = 'createdDateTime',
  MostSupporters = 'totalBackers',
  MostFunded = 'totalTokens',
  ClosestGoal = 'fundedPercentage',
  Ranking = 'ranking',
  LastSupported = 'lastSupport',
  PastMonthCredits = 'pastMonthCredits',
}
