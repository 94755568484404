import {Component, OnInit} from '@angular/core';
import {ModalComponent} from '@components/common/modal/modal.component';

@Component({
  selector: 'ark-discussion-action-error',
  templateUrl: './discussion-action-error.component.html',
  styleUrls: ['./discussion-action-error.component.scss']
})
export class DiscussionActionErrorComponent implements OnInit {

  constructor(protected readonly parent: ModalComponent) {
    this.parent.closeButton = true;
  }

  ngOnInit(): void {
    setTimeout(() => this.parent.close('success'), 2000);
  }
}
