import {PostAuthor, PostComment, PostMetaData, PostVotes} from '@interfaces/forum.interface';

/* ====[Posts]============================================================== */

/*     ----[Request]-------------------------------------------------------- */


/*     ----[/Request]------------------------------------------------------- */



/*     ----[Response]------------------------------------------------------- */

/**
 * ApiForumCommentsResponse
 * - API Response interface for a Post's comments
 *
 * @interface ApiForumCommentsResponse
 *
 * @property metadata {PostsMetaData} - The accompanying response metadata
 * @property comments {Array<PostComment>} - The array of comments based on date of creation
 */
export interface ApiForumCommentsResponse {
  metadata: PostMetaData;
  comments: Array<PostComment>;
}

/*     ----[/Response]------------------------------------------------------ */

/* ====[/Posts]============================================================= */





/* ====[Comments]=========================================================== */

/*     ----[Request]-------------------------------------------------------- */

/**
 * ApiForumCommentAddRequest
 * - API Request interface to post a comment
 *
 * @interface ApiForumCommentAddRequest
 *
 * @property body {string} - The textual content of the reply
 */
export interface ApiForumCommentAddRequest {
  body: string;
}

/*     ----[/Request]------------------------------------------------------- */



/*     ----[Response]------------------------------------------------------- */

/**
 * ApiForumCommentsResponse
 * - API Response interface for a comments request
 *
 * @interface ApiForumCommentsAddResponse
 *
 * @property author {PostAuthor} - The accompanying response metadata
 * @property comments {Array<PostComment>} - The array of sorted comments
 */
export interface ApiForumCommentsAddResponse {
  author: PostAuthor;
  body: string;
  comments?: Array<PostComment>;
  commentsDisplayed: number;
  commentsExpanded?: boolean
  commentsTotal: number;
  createDate: string;
  id: number;
  lastEditDate: string;
  replyToAuthor?: PostAuthor;
  votes: PostVotes;
  new?: boolean;
}

/*     ----[/Response]------------------------------------------------------ */

/* ====[/Comments]========================================================== */


/* ====[Common]============================================================= */

export interface ApiForumPagination {
  pageNumber?: number;
  pageSize?: number;
  searchStr?: string;
  orderBy?: {
    field: string;
    order: 'ASC' | 'DESC';
  }
}

/**
 * PostParent
 * - Parent Post/Comment Interface
 *
 * @interface PostParent
 *
 * @property id {string} - The id of the parent entity. Could be post or comment
 * @property type {CommentParentType} - The type of parent, post or comment
 * @property authorId {string} - (optional) The author of the parent post, used if a user replies directly to the author
 */
export interface PostParent {
  id: string;
  type: CommentParentType;
  authorId?: string;
}

export enum CommentParentType {
  'COMMENT' = 'comment',
  'POST' = ''
}


export enum CertificationTypeId {
  'SUGGESTION' = 1,
  'PROPOSAL' = 2
}

/* ====[/Common]============================================================ */
