// Module Imports
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {InlineSVGModule} from 'ng-inline-svg-2';
import {NgClickOutsideModule} from 'ng-click-outside2';
import {NgxTippyModule} from 'ngx-tippy-wrapper';
import {Nl2BrPipeModule} from 'nl2br-pipe';
import {RouterModule} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';

// Directive Imports
import {CountUpDirective} from '@directives/countup.directive';
import {EllipsisDirective} from '@directives/ellipsis.directive';
import {ModalInsertionDirective} from '@directives/modal-insertion.directive';

// Pipe Imports
import {KiloformatterPipe} from '@pipes/kiloformatter.pipe';
import {TimeAgoPipe} from '@pipes/timeago.pipe';
import {ThousandPipe} from '@pipes/thousand.pipe';

// Form Components Imports

import {CardCheckboxComponent} from '@components/form/card-checkbox/card-checkbox.component';
import {CardRadioComponent} from '@components/form/card-radio/card-radio.component';
import {CheckboxComponent} from '@components/form/checkbox/checkbox.component';
import {ChipComponent} from '@components/form/chip/chip.component';
import {ChipGroupComponent} from '@components/form/chip-group/chip-group.component';
import {ChipSelectionComponent} from '@components/form/chip-selection/chip-selection.component';
import {DateComponent} from '@components/form/date/date.component';
import {DropdownComponent} from '@components/form/dropdown/dropdown.component';
import {DropdownOptionsComponent} from '@components/form/dropdown-options/dropdown-options.component';
import {DropdownSelectionComponent} from '@components/form/dropdown-selection/dropdown-selection.component';
import {FieldChipComponent} from '@components/form/field-chip/field-chip.component';
import {InputComponent} from '@components/form/input/input.component';
import {InputExpandComponent} from '@components/form/input-expand/input-expand.component';
import {InputSearchComponent} from '@components/form/input-search/input-search.component';
import {DropdownAutocompleteComponent} from '@components/form/dropdown-autocomplete/dropdown-autocomplete.component';
import {MultiSelectionComponent} from '@components/form/multi-selection/multi-selection.component';
import {RadioComponent} from '@components/form/radio/radio.component';
import {RadioGroupComponent} from '@components/form/radio-group/radio-group.component';
import {SearchComponent} from '@components/form/search/search.component';
import {SearchSelectionComponent} from '@components/form/search-selection/search-selection.component';
import {SwitchComponent} from '@components/form/switch/switch.component';
import {TextareaComponent} from '@components/form/textarea/textarea.component';
import {ValueModalComponent} from '@components/form/value-modal/value-modal.component';

// Forum Components Imports

import {PostActionBarComponent} from '@components/forum/post-action-bar/post-action-bar.component';
import {PostAuthorComponent} from '@components/forum/post-author/post-author.component';
import {PostCommentComponent} from '@components/forum/post-comment/post-comment.component';
import {PostCommentsSectionComponent} from '@components/forum/post-comments-section/post-comments-section.component';
import {PostContainerComponent} from '@components/forum/post-container/post-container.component';
import {PostContainerHeaderComponent} from '@components/forum/post-container-header/post-container-header.component';
import {PostMoreCommentsComponent} from '@components/forum/post-more-comments/post-more-comments.component';
import {PostPublishComponent} from '@components/forum/post-publish/post-publish.component';
import {PostReplyLineComponent} from '@components/forum/post-reply-line/post-reply-line.component';
import {PostUserOptionsComponent} from '@components/forum/post-user-options/post-user-options.component';
import {
  PostCoverSelectionComponent
} from '@app/components/modals/forum/post-cover-selection/post-cover-selection.component';
import {PostDeleteComponent} from '@app/components/modals/forum/post-delete/post-delete.component';
import {
  PostDeleteCommentComponent
} from '@app/components/modals/forum/post-delete-comment/post-delete-comment.component';
import {PostHideComponent} from '@app/components/modals/forum/post-hide/post-hide.component';

// Components
import {AvatarComponent} from '@components/common/avatar/avatar.component';
import {BoxComponent} from '@components/common/box/box.component';
import {ButtonComponent} from '@components/common/button/button.component';
import {
  DiscussionActionComponent
} from '@components/modals/discussion/discussion-action/discussion-action.component';
import {IconComponent} from '@components/common/icon/icon.component';
import {IdenticonComponent} from '@components/common/identicon/identicon.component';
import {LoaderInlineComponent} from '@components/common/loader-inline/loader-inline.component';
import {MarketSectorItemComponent} from '@components/core/market-sector-item/market-sector-item.component';
import {MembershipItemComponent} from '@components/core/membership-item/membership-item.component';
import {ModalComponent} from '@components/common/modal/modal.component';
import {NftConfirmComponent} from '@components/modals/nft-confirm/nft-confirm.component';
import {NftItemComponent} from '@components/core/nft-item/nft-item.component';
import {NftErrorComponent} from '@components/modals/nft-error/nft-error.component';
import {NotLoggedComponent} from '@components/modals/not-logged/not-logged.component';
import {OnboardingComponent} from '@components/modals/membership/onboarding/onboarding.component';
import {OptionsMenuComponent} from '@components/common/options-menu/options-menu.component';
import {OptionsMenuItemComponent} from '@components/common/options-menu-item/options-menu-item.component';
import {SpinnerComponent} from '@components/common/spinner/spinner.component';
import {PaginationComponent} from '@components/common/pagination/pagination.component';
import {ProposalBackUnbackComponent} from '@components/modals/proposals/proposal-back-unback/proposal-back-unback.component';
import {ProposalActionComponent} from '@components/modals/proposals/proposal-action/proposal-action.component';
import {ProposalsItemComponent} from '@app/components/proposals/proposals-item/proposals-item.component';
import {ProposalUnbackAllComponent} from '@components/modals/proposals/proposal-unback-all/proposal-unback-all.component';
import {SliderComponent} from '@components/common/slider/slider.component';
import {SliderDisplayComponent} from '@components/common/slider-display/slider-display.component';
import {VoteComponent} from '@components/common/vote/vote.component';
import { SendInvitationComponent } from '@app/components/modals/invitation/send-invitation/send-invitation.component';
import { SendInvitationSuccessComponent } from '@app/components/modals/invitation/send-invitation-success/send-invitation-success.component';
import {Spinner1Component} from '@components/common/spinner1/spinner1.component';
import {ShareComponent} from '@pages/social/share/share.component';
import { TabsFilterBarComponent } from '@app/components/tabs-filter-bar/tabs-filter-bar.component';

// Modals
import { ProfileEditComponent } from '@app/components/modals/profile/profile-edit/profile-edit.component';
import { ProfileEditSuccessComponent } from '@app/components/modals/profile/profile-edit-success/profile-edit-success.component';

import {RequestInvitationComponent} from '@components/modals/invitation/request-invitation/request-invitation.component';
import {
  RequestInvitationSuccessComponent
} from '@components/modals/invitation/request-invitation-success/request-invitation-success.component';
import { SurveyActionComponent } from '@app/components/modals/surveys/survey-action/survey-action.component';
import { SurveyActionSuccessComponent } from '@app/components/modals/surveys/survey-action-success/survey-action-success.component';
import { SurveyDeleteComponent } from '@app/components/modals/surveys/survey-delete/survey-delete.component';
import { SurveyDetailsComponent } from '@app/components/modals/surveys/survey-details/survey-details.component';

// Common

const MODULES = [CommonModule, FormsModule, HttpClientModule, InfiniteScrollModule, InlineSVGModule, ReactiveFormsModule, RouterModule,
  TranslateModule, NgxTippyModule, Nl2BrPipeModule, NgClickOutsideModule];
const DIRECTIVES = [CountUpDirective, EllipsisDirective, ModalInsertionDirective];
const PIPES = [KiloformatterPipe, TimeAgoPipe, ThousandPipe];
const FORUM = [PostActionBarComponent, PostAuthorComponent, PostCommentComponent, PostCommentsSectionComponent,
  PostContainerComponent, PostContainerHeaderComponent, PostMoreCommentsComponent,
  PostPublishComponent, PostReplyLineComponent, PostUserOptionsComponent, PostCoverSelectionComponent,
  PostDeleteComponent, PostDeleteCommentComponent, PostHideComponent];
const MODALS = [DiscussionActionComponent, ProposalActionComponent, ProposalBackUnbackComponent,
  NotLoggedComponent, ProposalUnbackAllComponent, NftConfirmComponent, NftErrorComponent, ProfileEditComponent, ProfileEditSuccessComponent, RequestInvitationComponent,
  RequestInvitationSuccessComponent, SendInvitationComponent, SendInvitationSuccessComponent, SurveyActionComponent,
  SurveyActionSuccessComponent, SurveyDeleteComponent, SurveyDetailsComponent];
const FORMS = [CardCheckboxComponent, CardRadioComponent, CheckboxComponent, ChipComponent, ChipGroupComponent,
  ChipSelectionComponent, DateComponent, DropdownComponent, DropdownOptionsComponent, DropdownSelectionComponent,
  FieldChipComponent, InputComponent, InputExpandComponent, InputSearchComponent, DropdownAutocompleteComponent,
  MultiSelectionComponent, RadioComponent, RadioGroupComponent, SearchComponent, SearchSelectionComponent,
  SwitchComponent, TextareaComponent, ValueModalComponent];
const COMPONENTS = [AvatarComponent, BoxComponent, ButtonComponent, IconComponent, IdenticonComponent, LoaderInlineComponent,
  MarketSectorItemComponent, MembershipItemComponent, ModalComponent, NftItemComponent, OnboardingComponent,
  OptionsMenuItemComponent, OptionsMenuComponent, SliderComponent, SliderDisplayComponent, SpinnerComponent, VoteComponent,
  PaginationComponent, ProposalsItemComponent, Spinner1Component, ShareComponent, TabsFilterBarComponent];
const DECLARATIONS = [...DIRECTIVES, ...PIPES, ...FORMS, ...FORUM, ...COMPONENTS, ...MODALS];
const IMPORTS = [...MODULES];
const EXPORTS = [...MODULES, ...DIRECTIVES, ...PIPES, ...FORMS, ...COMPONENTS, ...MODALS];

@NgModule({
  declarations: DECLARATIONS,
  imports: IMPORTS,
  exports: [
    EXPORTS,
    PostContainerComponent
  ]
})
export class SharedModule {
}
