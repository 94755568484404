import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AccountModelService} from '@models/account-model.service';
import {ModalComponent} from '@components/common/modal/modal.component';
import {ValueChangeEvent} from '@interfaces/form.interface';

@Component({
  selector: 'ark-request-invitation',
  templateUrl: './request-invitation.component.html',
  styleUrls: ['./request-invitation.component.scss']
})
export class RequestInvitationComponent implements OnInit {

  form: FormGroup = this.initForm();
  loading = false;

  serverErrorMsg: string | null = null;

  constructor(
    private account: AccountModelService,
    protected readonly parent: ModalComponent,
    private fb: FormBuilder
  ) {
    this.parent.closeButton = true;
  }

  async ngOnInit() {

  }

  initForm(): FormGroup {
    return this.fb.group({
      email: [null, [Validators.required, Validators.email]]
    });
  }

  formValueChange(event: ValueChangeEvent, control: string) {
    this.form.get(control)?.setValue(event.value);
    this.form.markAsDirty();
    this.form.updateValueAndValidity();
  }


  validateForm() {
    if (this.form.get('email')?.value === null) {
      this.form.get('email')?.setValue('')
    }

    this.form.updateValueAndValidity();
  }

  submit() {
    this.validateForm();
    this.serverErrorMsg = null;
    if (this.form?.valid && this.form?.dirty) {
      this.loading = true;
      const data = {
        email: this.form.get('email')?.value
      }
      this.account.requestInvitation(data).then(() => {
        console.info('request', data);
        this.loading = false;
        this.parent.close('success');
      }).catch((err: {code: string, description: string }) => {
        this.loading = false;
        this.serverErrorMsg = err.description;
      });
    }
  }


  public close(): void {
    this.parent.close();
  }

}
