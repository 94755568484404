import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MembershipItem, MembershipType} from '@interfaces/membership.interface';
import {fadeDownAnimations} from '@animations/fadedown.animations';

@Component({
  selector: 'ark-membership-item',
  templateUrl: './membership-item.component.html',
  styleUrls: ['./membership-item.component.scss'],
  animations: [fadeDownAnimations()]
})
export class MembershipItemComponent implements OnInit {
  @Input() data!: MembershipItem;
  @Output() purchaseItem: EventEmitter<number> = new EventEmitter<number>();
  ctaText = '';
  ctaDisabled = false;
  purchasing = false;
  popupVisible = false;
  constructor() { }

  ngOnInit(): void {
    this.ctaText = this.data.current ? 'CURRENT PACKAGE' : (this.data.type === MembershipType.PAID && this.data.nft.sold === this.data.nft.total ? 'SOLD OUT' : 'SELECT');
    this.ctaText = (this.data.freeDisabled && this.data.type === MembershipType.FREE) ? '1-TIME USE ONLY' : this.ctaText;
    this.ctaDisabled = (this.data.freeDisabled && this.data.type === MembershipType.FREE) || this.data.current || (this.data.type === MembershipType.PAID && this.data.nft.sold === this.data.nft.total);
  }

  purchase(): void {
    if (!this.ctaDisabled && !this.purchasing) {
      this.purchasing = true;
      this.purchaseItem.emit(this.data.id);
    }
  }

  openPopup(): void {
    this.popupVisible = true;
  }

  closePopup(): void {
    this.popupVisible = false;
  }

  gotoDocs(): void {
    window.open('https://docs.valuesmovement.org/welcome', '_blank');
  }

}
