import { Injectable } from '@angular/core';
import {map, Subject, Subscription} from 'rxjs';
import {BroadcastEvent, BroadcastMessage} from '@interfaces/broadcast.interface';
import {filter} from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class IntercomService {
  private subject$ = new Subject<any>();

  broadcast(message: BroadcastMessage): void {
    this.subject$.next(message);
  }

  on(eventName: BroadcastEvent, action: any): Subscription {
    return this.subject$.pipe(
      filter((e: BroadcastMessage) => e.event === eventName),
      map((e: BroadcastMessage) => e['data'])).subscribe(action);
  }

}
