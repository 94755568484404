import {Component, ElementRef, OnInit} from '@angular/core';
import {  FormBuilder } from '@angular/forms';
import { dropdownAnimation } from '@animations/dropdown.animations';
import { DropdownSelectionComponent } from '../dropdown-selection/dropdown-selection.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'ark-search-selection',
  templateUrl: './search-selection.component.html',
  styleUrls: ['./search-selection.component.scss'],
  animations: [dropdownAnimation()]
})
export class SearchSelectionComponent extends DropdownSelectionComponent implements OnInit {

  constructor(fb: FormBuilder, translate: TranslateService) {
    super(fb, translate);
   }

  override onBlur(): void {
    super.onBlur();
    if (!this.value && this.form.dirty) {
      this.formStates.error = true;
    }
  }
}
