import {Injectable} from '@angular/core';
import {ApiService} from '@services/api.service';
import {ApiLockCheckResponse, ApiNftAllResponse, Nft} from '@interfaces/api.nft.interface';

@Injectable({
  providedIn: 'root'
})
export class NftModelService {

  constructor(private api: ApiService) {
  }

  public async all(membershipId: number, pageNumber = 0, pageSize = 0): Promise<ApiNftAllResponse> {
    return new Promise(async (resolve, reject) => {
      this.api.nft.all(membershipId, {pageNumber, pageSize}).subscribe(async(response: any) => {
        resolve(response);
      }, (error) => {
        reject(error);
      });
    })
  }

  public async lock(nftItemId: number): Promise<any> {
    return new Promise(async (resolve, reject) => {
      this.api.nft.lock(nftItemId).subscribe(async(response: any) => {
        resolve(response);
      }, (error) => {
        reject(error);
      });
    })
  }

  public async unlock(nftItemId: number): Promise<any> {
    return new Promise(async (resolve, reject) => {
      this.api.nft.unlock(nftItemId).subscribe(async(response: any) => {
        resolve(response);
      }, (error) => {
        reject(error);
      });
    })
  }

  public async get(nftItemId: number): Promise<Nft> {
    return new Promise(async (resolve, reject) => {
      this.api.nft.get(nftItemId).subscribe(async(response: any) => {
        resolve(response);
      }, (error) => {
        reject(error);
      });
    })
  }

  public async myNfts(): Promise<Nft[]> {
    return new Promise(async (resolve, reject) => {
      this.api.nft.my().subscribe(async(response: Nft[]) => {
        resolve(response);
      }, (error) => {
        reject(error);
      });
    })
  }

  public async downloadBadge(nftItemId: number): Promise<any> {
    return new Promise(async (resolve, reject) => {
      this.api.nft.badge(nftItemId).subscribe(async(response: Nft[]) => {
        debugger
        resolve(response);
      }, (error) => {
        debugger
        reject(error);
      });
    })
  }

  public async lockCheck(nftItemId: number): Promise<ApiLockCheckResponse> {
    return new Promise(async (resolve, reject) => {
      this.api.nft.lockCheck(nftItemId).subscribe(async(response: any) => {
        resolve(response);
      }, (error) => {
        reject(error);
      });
    })
  }

}
