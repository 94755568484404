import {Pipe, PipeTransform} from '@angular/core';
import * as luxon from 'luxon';

const DateTime = luxon.DateTime;

@Pipe({name: 'timeAgo'})
export class TimeAgoPipe implements PipeTransform {

  constructor() {}

  transform(value: string): string | null {
    const date = DateTime.fromISO(value, {zone: 'utc'}).toLocal();
    if (date < DateTime.now()) {
      return date.toRelative();
    } else {
      return 'Just now'
    }
  }
}
