import {Component, Input, OnInit} from '@angular/core';
import {ProposalModelService} from '@models/proposal-model.service';
import {Track} from '@interfaces/proposal.interfase';

@Component({
  selector: 'ark-share',
  templateUrl: './share.component.html',
  styleUrls: ['./share.component.scss']
})
export class ShareComponent implements OnInit {

  public twitterUrl: string;
  public fbUrl: string;
  public linkedinUrl: string;
  public instagramUrl: string;

  @Input() public id: number = 0;
  @Input() public type: string = 'request';

  constructor(private model: ProposalModelService ) {
    const text = 'Value+Movement';
    const url = window.location.href.split('?')[0];
    const path = window.location.protocol + '//' + window.location.host + '/logo?share=' + url;
    this.twitterUrl = 'https://twitter.com/share?text=' + text + '&url=' + path;
    this.fbUrl = 'https://www.facebook.com/share.php?u=' + path + '&quote=' + text;
    this.linkedinUrl = 'https://www.linkedin.com/sharing/share-offsite/?url=' + path + '&title=' + text;
    this.instagramUrl = 'https://www.instagram.com/?url=' + path;
  }

  ngOnInit(): void {
  }

  public async share(net: 'Twitter' | 'Facebook' | 'Linkedin'): Promise<void> {
    let url = null;

    switch (net) {
      case 'Facebook':
        url = this.fbUrl;
        break;
      case 'Linkedin':
        url = this.linkedinUrl;
        break;
      case 'Twitter':
        url = this.twitterUrl;
        break;
    }

    if (url) {
      window.open(url, '_blank');
      try {
        await this.track();
      } catch (e) {
        console.error(e);
      }
    }


  }

  private async track(): Promise<void> {
    const data: Track = {
      entityType: this.type,
      entityId: this.id,
    }
    await this.model.track(data);
  }
}
