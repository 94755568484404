export interface BroadcastMessage {
  event: BroadcastEvent;
  data?: any;
}

export enum BroadcastEvent {
  METAMASK_CONNECTED    = 'metamaskConnected',
  METAMASK_DISCONNECTED = 'metamaskDisconnected',
  USER_LOGGED_IN        = 'userLoggedIn',
  USER_LOGGED_OUT       = 'userLoggedOut',
  DRAWER_ACTION         = 'drawerAction',
  SHOW_DRAWER           = 'showDrawer',
  REPLY_SUBMITTED       = 'replySubmitted',
  SHOW_REPLY_LINE       = 'showReplyLine',
  HIDE_REPLY_LINE       = 'hideReplyLine',
  SHOW_POST_COMMENTS    = 'showPostComments',
  BACKED                = 'backed',
  ENTITY_UPDATED        = 'updated',
  ENTITY_CREATED        = 'created',
  SHOW_NAVIGATION       = 'showNavigation',
  HIDE_NAVIGATION       = 'hideNavigation',
  PROPOSAL_DELETED      = 'proposalDeleted',
  NOTIFICATION_CHANGES  = 'notificationChanges',
}
