import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {GlobalService} from './global.service';
import {Observable, throwError} from 'rxjs';
import {environment} from '@env/environment';
import {catchError, share} from 'rxjs/operators';
import {StorageService} from '@services/storage.service';
import {HttpMethods, PaginationRequest} from '@interfaces/common.interface';
import {
  ApiAuthChallengeResponse,
  ApiAuthLoginRequest,
  ApiAuthLoginResponse,
  ApiAuthRefreshTokenResponse, ApiDiscordIdResponse
} from '@interfaces/api.auth.interface';
import {
  Proposal,
  ProposalAllRequest,
  ProposalRequest
} from '@app/etc/interfaces/api.proposal.interface';
import {Category} from '@app/etc/interfaces/api.category.interface';
import {
  ApiMembershipLevelResponse,
  ApiMembershipLevelsResponse,
  ApiMembershipPurchaseRequest,
  ApiMembershipPurchaseResponse, ApiMembershipTokensResponse
} from '@interfaces/api.membership.interface';
import {
  ApiAdminInviteRequest,
  ApiAdminInviteResponse, ApiAdminUserEditRequest,
  ApiAdminUsersRequest,
  ApiAdminUsersResponse, Pagination
} from '@interfaces/api.admin.interface';
import {
  PasswordCreate,
  ApiAccountProfileEdit,
  ApiAccountProfileResponse,
  EmailLogin, Refresh,
  Reset,
  Settings
} from '@interfaces/api.account.interface';
import { ApiCategoryStatisticResponse } from '@app/etc/interfaces/api.statistic.interface';
import {ApiForumCommentsAddResponse, ApiForumPagination, CertificationTypeId} from '@interfaces/api.forum.interface';
import { ApiStatisticResponse } from '@app/etc/interfaces/statistic.api.interface';
import {IDiscussion, IDiscussionAllResponse, IDiscussionsAllRequest} from '@interfaces/discussion.interface';
import {IProposalsRequest, StatisticsMine, Track} from '@app/etc/interfaces/proposal.interfase';
import {ApiLockCheckResponse, ApiNftAllResponse, Nft} from '@interfaces/api.nft.interface';
import { IDiscussionSearchAllResponse, IProposalSearchAllResponse } from '@app/etc/interfaces/api.unified-search.interface';
import { ActiveSurvey, Survey, SurveyAllRequest, SurveyAllResponse } from '@app/etc/interfaces/api.survey.interface';
import {INotificationResponse} from '@interfaces/notifications.interfase';


@Injectable({
  providedIn: 'root'
})
export class ApiService {
  statistic = {
    get: (): Observable<ApiStatisticResponse> => {
      return this.fetch(`Statistics`, 'get');
    }
  }

  auth = {
    login: (data: ApiAuthLoginRequest): Observable<ApiAuthLoginResponse> => {
      return this.fetch(`Auth/login`, 'post', data);
    },
    challenge: (walletId: string): Observable<ApiAuthChallengeResponse> => {
      return this.fetch(`Auth/challenge/${walletId}`, 'get');
    },
    refreshToken: {
      get: (refreshToken: string): Observable<ApiAuthRefreshTokenResponse> => {
        return this.fetch(`Auth/refreshToken`, 'post', {refreshToken});
      }
    }
  };

  account = {
    profile: {
      get: (): Observable<ApiAccountProfileResponse> => {
        return this.fetch(`Account/profile`, 'get');
      },
      edit: (data: ApiAccountProfileEdit): Observable<any> => {
        return this.fetch(`Account/update/profile`, 'put', data);
      },
    },
    completeWalkthrough: (): Observable<ApiAccountProfileResponse> => {
      return this.fetch(`Account/completeWalkthrough`, 'get');
    },
    invitation: {
      send: (data: any): Observable<any> => {
        return this.fetch(`Invitation/invite`, 'post', data);
      },
      request: (data: any): Observable<any> => {
        return this.fetch(`Invitation/invitation/request`, 'post', data);
      },
      sendRequest: (id: number, data: any): Observable<any> => {
        return this.fetch(`Invitation/invitation/request/send/${id}`, 'post', data);
      },
      listInvitationRequests: (): Observable<any> => {
        return this.fetch(`Invitation/invitation/requests`, 'get');
      },
      stats: (): Observable<any> => {
        return this.fetch(`Invitation/invitation/stats`, 'get');
      },
    },
    settings: {
      set: (data: Settings): Observable<Settings> => {
        return this.fetch(`UserSettings`, 'post', data);
      },
      get: (): Observable<Settings> => {
        return this.fetch(`UserSettings`, 'get');
      },
    },
    login: {
      reset: (data: Reset): Observable<any> => {
        return this.fetch(`Auth/password/reset`, 'post', data);
      },
      refresh: (data: Refresh): Observable<any> => {
        return this.fetch(`Auth/refreshToken`, 'post', data);
      },
      create: (data: PasswordCreate): Observable<any> => {
        return this.fetch(`Auth/password/create`, 'post', data);
      },
      login: (data: EmailLogin): Observable<any> => {
        return this.fetch(`Auth/login`, 'post', data);
      }
    }
  };

  proposal = {
    list: {
      post: (data: ProposalAllRequest): Observable<IDiscussionAllResponse> => {
        return this.fetch(`CertificationProposal/all`, 'post', data);
      }
    },
    getById: {
      get: (id: number): Observable<IDiscussion[]> => {
        return this.fetch(`CertificationProposal/${id}`, 'get');
      }
    },
    create: {
      post: (data: ProposalRequest): Observable<Proposal> => {
        return this.fetch(`CertificationProposal`, 'post', data);
      }
    },
    update: {
      put: (data: ProposalRequest): Observable<Proposal> => {
        return this.fetch(`CertificationProposal`, 'put', data);
      }
    },
    like: {
      post: (id: number): Observable<any> => {
        return this.fetch(`CertificationProposal/${id}/like`, 'post');
      },
      delete: (id: number): Observable<any> => {
        return this.fetch(`CertificationProposal/${id}/like`, 'delete', null);
      }
    },
    delete: {
      delete: (id: number): Observable<any> => {
        return this.fetch(`CertificationProposal/${id}`, 'delete');
      }
    }
  };

  category = {
    list: {
      get: (): Observable<Category[]> => {
        return this.fetch(`CertificationCategory/all`, 'get');
      }
    },
    getById: {
      get: (id: number): Observable<Category> => {
        return this.fetch(`CertificationCategory/${id}`, 'get');
      }
    },
    create: {
      post: (data: Category): Observable<any> => {
        return this.fetch(`CertificationCategory`, 'post', data);
      }
    },
    update: {
      put: (data: any): Observable<any> => {
        return this.fetch(`CertificationCategory`, 'put', data);
      }
    },
    delete: {
      delete: (id: number): Observable<any> => {
        return this.fetch(`CertificationCategory/${id}`, 'delete');
      }
    }
  };

  membership = {
    levels: (): Observable<ApiMembershipLevelsResponse> => {
      return this.fetch(`Membership/levels`, 'get');
    },
    purchase: (data: ApiMembershipPurchaseRequest): Observable<ApiMembershipPurchaseResponse> => {
      return this.fetch(`Membership/purchase`, 'post', data);
    },
    level: (): Observable<ApiMembershipLevelResponse> => {
      return this.fetch(`Membership/user/level`, 'get');
    },
    tokens: (): Observable<ApiMembershipTokensResponse> => {
      return this.fetch(`Membership/user/tokens`, 'get');
    }

  };

  nft = {
    all: (membershipId: number, data: PaginationRequest): Observable<ApiNftAllResponse> => {
      return this.fetch(`nft/${membershipId}/all`, 'post', data);
    },
    lock: (nftItemId: number): Observable<any> => {
      return this.fetch(`nft/lock/${nftItemId}`, 'get');
    },
    unlock: (nftItemId: number): Observable<any> => {
      return this.fetch(`nft/unlock/${nftItemId}`, 'get');
    },
    get: (nftItemId: number): Observable<Nft> => {
      return this.fetch(`nft/${nftItemId}`, 'get');
    },
    my: (): Observable<Nft[]> => {
      return this.fetch(`nft/myNfts`, 'get');
    },
    lockCheck: (nftItemId: number): Observable<ApiLockCheckResponse> => {
      return this.fetch(`nft/lockCheck/${nftItemId}`, 'get');
    },
    badge:(nftItemId: number): Observable<any> => {
      return this.fetch(`nft/myNfts/downloadBadge?nftItemId=${nftItemId}`, 'downloadImage');
    },
  };

  statistics = {
    byCategory: {
      get: (categoryId: number): Observable<ApiCategoryStatisticResponse> => {
        return this.fetch(`statistics/byCategory/${categoryId}`, 'get');
      }
    }
  }
  admin = {
    invite: (data: ApiAdminInviteRequest): Observable<ApiAdminInviteResponse> => {
      return this.fetch(`UserManagement/invite`, 'post', data);
    },
    users: (data: ApiAdminUsersRequest): Observable<ApiAdminUsersResponse> => {
      return this.fetch(`UserManagement/users`, 'post', data);
    },
    user: {
      get: (userId: number): Observable<ApiAdminUsersResponse> => {
        return this.fetch(`UserManagement/user?userId=${userId}`, 'get');
      },
      put: (userId: number, data: ApiAdminUserEditRequest): Observable<ApiAdminUsersResponse> => {
        return this.fetch(`UserManagement/user?userId=${userId}`, 'put', data);
      },
      delete: (userId: number): Observable<ApiAdminUsersResponse> => {
        return this.fetch(`UserManagement/user?userId=${userId}`, 'delete');
      }
    }
  };

  request = {
    list: {
      get: (data: any): Observable<any> => {
        return this.fetch(`CertificationRequest/all`, 'post', data);
      }
    },
    show: {
      get: (requestId: number): Observable<any> => {
        return this.fetch(`CertificationRequest/${requestId}`, 'get');
      },
    },
    delete: (requestId: number): Observable<any> => {
      return this.fetch(`CertificationRequest/${requestId}`, 'delete');
    },
    create: {
      post: (data: any): Observable<any> => {
        return this.fetch(`CertificationRequest`, 'post', data);
      }
    },
    update: {
      put: (data: any): Observable<any> => {
        return this.fetch(`CertificationRequest`, 'put', data);
      }
    },
    statistics: {
      get: (): Observable<StatisticsMine> => {
        return this.fetch(`CertificationRequest/stats/mine`, 'get');
      }
    },
    types: (): Observable<any> => {
      return this.fetch(`CertificationRequest/certificationTypes`, 'get');
    },
    track: (data: Track): Observable<any> => {
      return this.fetch(`Action/social/share`, 'post', data);
    },
    all: (data: any): Observable<any> => {
      return this.fetch(`CertificationRequest/all`, 'post', data);
    },
    back: (data: any): Observable<any> => {
      return this.fetch(`CertificationRequest/support/add`, 'post', data);
    },
    removeBack: (data: any): Observable<any> => {
      return this.fetch(`CertificationRequest/support/remove`, 'post', data);
    },
    updateBack: (data: any): Observable<any> => {
      return this.fetch(`CertificationRequest/support/update`, 'post', data);
    },
    like: (requestId: number): Observable<any> => {
      return this.fetch(`CertificationRequest/${requestId}/like`, 'post', null);
    },
    dislike: (requestId: number): Observable<any> => {
      return this.fetch(`CertificationRequest/${requestId}/like`, 'delete', null);
    },
    favorite: {
      add: (requestId: string): Observable<any> => {
        return this.fetch(`CertificationRequest/${requestId}/favorite`, 'post', null);
      },
      delete: (requestId: string): Observable<any> => {
        return this.fetch(`CertificationRequest/${requestId}/favorite`, 'delete', null);
      }
    }
  };

  forum = {
    comment: {
      all: (certificationTypeId: CertificationTypeId, certificationId: number): Observable<any> => {
        return this.fetch(`DiscussionForum/${certificationTypeId}/${certificationId}`, 'get');
      },
      add: (certificationTypeId: CertificationTypeId, certificationId: number, body: string): Observable<ApiForumCommentsAddResponse> => {
        return this.fetch(`DiscussionForum/${certificationTypeId}/${certificationId}/comment`, 'post', {body});
      },
      edit: (postCommentId: number, body: string): Observable<any> => {
        return this.fetch(`DiscussionForum/discussion/comment/${postCommentId}`, 'put', {body});
      },
      delete: (postCommentId: number): Observable<any> => {
        return this.fetch(`DiscussionForum/discussion/comment/${postCommentId}`, 'delete');
      },
    },
    like: {
      add: (postCommentId: number): Observable<any> => {
        return this.fetch(`DiscussionForum/discussion/comment/${postCommentId}/like`, 'get');
      },
      delete: (postCommentId: number): Observable<any> => {
        return this.fetch(`DiscussionForum/discussion/comment/${postCommentId}/like`, 'delete');
      },
    }
  };

  search = {
    discussions: {
      all: (data: IDiscussionsAllRequest): Observable<IDiscussionSearchAllResponse> => {
        return this.fetch(`Search/discussions/all`, 'post', data);
      }
    },
    proposals: {
      all: (data: IProposalsRequest): Observable<IProposalSearchAllResponse> => {
        return this.fetch(`Search/proposals/all`, 'post', data);
      }
    }
  }

  notifications = {
    list: (): Observable<INotificationResponse> => {
      return this.fetch(`Notifications`, 'get');
    },
    read: (id: number): Observable<any> =>{
      return this.fetch(`Notifications/read/${id}`, 'post');
    },
    delete: (id: number): Observable<any> => {
      return this.fetch(`Notifications?notificationId=${id}`, 'delete');
    },
  }

  survey = {
    list: {
      get: (data: SurveyAllRequest): Observable<SurveyAllResponse> => {
        return this.fetch(`Survey/all`, 'post', data);
      }
    },
    create: {
      post: (data: Survey): Observable<any> => {
        return this.fetch(`Survey`, 'post', data);
      }
    },
    update: {
      put: (data: Survey): Observable<any> => {
        return this.fetch(`Survey`, 'put', data);
      }
    },
    delete: {
      delete: (id: number): Observable<any> => {
        return this.fetch(`Survey/${id}`, 'delete');
      }
    },
    active: {
      get: (): Observable<ActiveSurvey> => {
        return this.fetch(`Survey/activeSurvey`, 'post');
      }
    }
  }

  private readonly apiURL: string;

  constructor(
    private storage: StorageService,
    private global: GlobalService,
    private http: HttpClient
  ) {
    this.apiURL = environment.apiBaseUrl;
  }

  private static handleError(error: HttpErrorResponse): Observable<never> {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(`Server Error ${error.status}:`, error.error);
    }
    // return an ErrorObservable with a user-facing error message
    return throwError(error);
  }

  fetch(endpoint: string, method: HttpMethods,
        data?: any, file?: any | null, mock?: boolean): Observable<any> {
    const jData = data ? JSON.stringify(data) : '';
    const url = this.apiURL + '/' + endpoint;
    if (mock) {
      return this.http.get(`./assets/mocks/${endpoint.replace('/', '.')}.${method.toUpperCase()}.json`);
    } else {
      switch (method) {
        case 'get':
          return this.http.get(url)
            .pipe(share())
            .pipe(catchError(error => ApiService.handleError(error)));
        case 'post':
          return this.http.post(url, jData)
            .pipe(share())
            .pipe(catchError(error => ApiService.handleError(error)));
        case 'patch':
          return this.http.patch(url, jData)
            .pipe(share())
            .pipe(catchError(error => ApiService.handleError(error)));
        case 'upload':
          const formData: FormData = new FormData();
          if (data) {
            Object.keys(data).forEach(item => {
              formData.append(item[0], item[1]);
            });
          }
          if (file) {
            formData.append('file', file);
          }
          return this.http.post(url, formData)
            .pipe(share())
            .pipe(catchError(error => ApiService.handleError(error)));
        case 'delete':
          return this.http.delete(url)
            .pipe(share())
            .pipe(catchError(error => ApiService.handleError(error)));
        case 'put':
          return this.http.put(url, jData)
            .pipe(share())
            .pipe(catchError(error => ApiService.handleError(error)));
        case 'download':
          return this.http.get(url, {responseType: 'arraybuffer'})
            .pipe(share())
            .pipe(catchError(error => ApiService.handleError(error)));
        case 'downloadImage':
              return this.http.get(url, {responseType: 'blob', observe: 'response'})
                .pipe(share())
                .pipe(catchError(error => ApiService.handleError(error)));
        case 'multipart':
          break;
      }
      return this.http.get(url);
    }
  }

}
