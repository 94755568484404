import {AfterViewInit, Directive, ElementRef, OnInit} from '@angular/core';

@Directive({
  selector: '[arkEllipsis]'
})
export class EllipsisDirective implements OnInit, AfterViewInit {

  constructor(private el: ElementRef) {}

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
    setTimeout(() => this.performTruncation());
  }

  performTruncation(): void {
    const el = this.el.nativeElement;
    const maxChars = Math.round(el.clientWidth / parseFloat(window.getComputedStyle(el, null).getPropertyValue('font-size')));
    const text = el.innerHTML;
    const snip = Math.round(maxChars / 2) * 2;
    if (text.length >= maxChars) {
      el.innerHTML = `${text.substring(0, snip - 3)}...${text.substring((text.length - 3))}`;
    }
  }
}
