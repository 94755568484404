import {Component} from '@angular/core';
import {ModalComponent} from '@components/common/modal/modal.component';
import {IProposalItem} from '@interfaces/proposal.interfase';
import {ProposalModelService} from '@models/proposal-model.service';
import {ModalService} from '@services/modal.service';
import {
  ProposalBackingRemovedComponent
} from '@components/modals/proposals/proposal-backing-removed/proposal-backing-removed.component';
import {ModalSize} from '@interfaces/common.interface';

@Component({
  selector: 'ark-proposal-unback-all',
  templateUrl: './proposal-unback-all.component.html',
  styleUrls: ['./proposal-unback-all.component.scss']
})
export class ProposalUnbackAllComponent {

  public item: IProposalItem;

  constructor(
    protected readonly parent: ModalComponent,
    private proposalService: ProposalModelService,
    private readonly modalService: ModalService) {
    this.parent.closeButton = true;
    this.item = parent.data.item;
  }

  public async unback(): Promise<void> {
    const amount = Math.floor(+this.item.tokensBackedByMember * 1000) / 1000;
    // const amount = this.item.tokensBackedByMember;
    const data = {
      Amount: '' + amount,
      CertificationRequestId: +this.item.certificationRequestId
    }

    try {
      const res = await this.proposalService.unbackAllProject(data);

      this.close('delete');
      if (res['message'] === 'unbacked') {
        this.modalService.open(ProposalBackingRemovedComponent, {
          style: 'dark',
          size: ModalSize.DYNAMIC,
        });
      }
    } catch (e) {
      this.close('delete');
      console.error(e);
    }


  }

  public cancel(): void {
    this.parent.close();
  }

  public close(value: string | undefined): void {
    this.parent.close(value);
  }
}
