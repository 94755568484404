import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {RadioGroupOptions, ValueChangeEvent} from '@interfaces/form.interface';

@Component({
  selector: 'ark-radio-group',
  templateUrl: './radio-group.component.html',
  styleUrls: ['./radio-group.component.scss']
})
export class RadioGroupComponent implements OnInit {
  _options: RadioGroupOptions = {
    name: '',
    items: []
  };

  get options(): RadioGroupOptions {
    return this._options;
  }

  @Input() set options(value: RadioGroupOptions) {
    this._options = value;
    this.checkedValue = this.options.items.filter(el => el.checked).map( el => el.value)[0] || null;
  }

  @Input() error = false;
  @Input() errorLabel = '';
  @Input() checkedValue: any = null;

  @Output() valueChanged: EventEmitter<ValueChangeEvent> = new EventEmitter<ValueChangeEvent>();

  // checkedValue: any = null;

  constructor() { }

  ngOnInit(): void {

  }

  selectOption(value: any): void {
    this.checkedValue = value;
    this.error = false;
    this.valueChanged.emit({value, status: 'VALID'});
  }

}
