import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable()
export class JsonLoaderService {

  basePath = {
    data: './assets/data/',
    mock: './assets/mocks/'
  };

  constructor(
    private http: HttpClient,

  ) {
  }

  public load(file: string, type: 'data' | 'mock' = 'data'): Observable<any> {
    return this.http.get(this.basePath[type] + file + '.json');
  }

}
