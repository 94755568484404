import {Injectable} from '@angular/core';
import {PasswordConditions} from '@interfaces/common.interface';

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  constructor() {
  }

  remToPixels(rem: number): number {
    return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
  }

  capitalize(text: string): string {
    const tokens = text.split(' ');
    for (let token of tokens) {
      token = token.charAt(0).toUpperCase() + token.slice(1);
    }
    return tokens.join(' ');
  }

  passwordStrength(pass: string): string {
    if (!pass.length) {
      return '';
    }
    const mediumRegex = new RegExp('^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})');
    const strongRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&amp;\*])(?=.{8,})');
    if (strongRegex.test(pass)) {
      return 'STRONG';
    } else if (mediumRegex.test(pass)) {
      return 'AVERAGE';
    } else {
      return 'WEAK';
    }
  }

  passwordValidity(password: string): PasswordConditions {
    return {
      lowercase: new RegExp('[a-z]').test(password),
      uppercase: new RegExp('[A-Z]').test(password),
      number: RegExp('[0-9]').test(password),
      length: password.length >= 8
    };
  }

  kiloFormatter(value: number | undefined | null, fractionDigits: number = 2): string {
    const sizes = [
      {value: 1e15, symbol: 'Q'},
      {value: 1e12, symbol: 'T'},
      {value: 1e9, symbol: 'B'},
      {value: 1e6, symbol: 'M'},
      {value: 1e3, symbol: 'K'},
    ];

    if (value) {
      for (const size of sizes) {
        if (value >= size.value) {
          return (value / size.value).toFixed(fractionDigits) + size.symbol;
        }
      }
      return value.toString();
    } else {
      return '0';
    }
  }

  isPlural(condition: boolean, token: string): string {
      return `${token}${condition ? '.PLURAL' : '.SINGULAR'}`;
  }

  isMobile(): boolean {
    return window.innerWidth <= 867;
  }

  isValidEmail(email: string): boolean {
    const regex = /^([a-zA-Z0-9_.+-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    return regex.test(email);
  }

  getUniqueId(): string {
    return Math.random().toString().substring(2);
  }

  public isIterable(data: any): boolean {
    if (data === null || data === undefined) {
      return false
    }

    return typeof data[Symbol.iterator] === 'function'
  }


  fromHex (s: any) {
    function add(x: any, y: any) {
      let c = 0, r = [];
      x = x.split('').map(Number);
      y = y.split('').map(Number);
      while (x.length || y.length) {
        let s = (x.pop() || 0) + (y.pop() || 0) + c;
        r.unshift(s < 10 ? s : s - 10);
        c = s < 10 ? 0 : 1;
      }
      if (c) r.unshift(c);
      return r.join('');
    }

    let dec = '0';
    s.split('').forEach(function (chr: any) {
      let n = parseInt(chr, 16);
      for (let t = 8; t; t >>= 1) {
        dec = add(dec, dec);
        if (n & t) dec = add(dec, '1');
      }
    });
    return dec;
  }
}
