import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {InputComponent} from '@components/form/input/input.component';
import {FormBuilder} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'ark-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss']
})

/**
 * @class {CheckboxComponent}
 * @extends {InputComponent}
 * @description Standalone checkbox form component.
 *
 * @description Checkbox label must be text only if passed through the 'label' @Input parameter.
 *
 * If you need to have additional functionality on the label (i.e. links, or popups on click)
 * omit 'label' @Input entirely, and pass the label element directly as a child
 *
 * ex: <ark-checkbox><div (click)="doSomething()">My Super Fancy Label</div></ark-checkbox>
 *
 * @implements OnInit
 * @author Sotiris Varotsis
 */

export class CheckboxComponent extends InputComponent implements OnInit, OnChanges {

  @Input()
  public selected: boolean = false;

  constructor(fb: FormBuilder, translate: TranslateService) {
    super(fb, translate);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('selected')) {
      this.formStates.selected = this.selected;
    }
  }

  override ngOnInit(): void {
    if (this.disabled) {
      this.formStates.disabled = true;
      this.tabIndex = -1;
    }
    this.formStates.selected = this.selected;
  }

  toggleSelection(evt: Event): void {
    evt.stopPropagation();
    evt.preventDefault();
    if (!this.disabled) {
      this.formStates.selected = !this.formStates.selected;
      if (this.required) {
        this.formStates.valid = this.formStates.selected;
        this.formStates.error = !this.formStates.valid;
      } else {
        this.formStates.valid = true;
        this.formStates.error = false;
      }
      this.valueChanged.emit({value: this.formStates.selected, status: this.formStates.valid ? 'VALID' : 'INVALID'});
    }
  }

  override onFocus(): void {
    this.formStates.focused = true;
  }

  override onBlur(): void {
    this.lostFocus.emit({value: this.formStates.selected, status: this.formStates.valid ? 'VALID' : 'INVALID'});
    this.formStates.focused = false;
  }
}
