<div class="wrapper--pagination">
  <div class="box">
    <ark-icon icon="system-left" size="small" (click)="previous()" [class.active]="canPrevious"></ark-icon>
    <span class="index">
      <span>{{pageIndex}}</span>
    </span>
    <ark-icon icon="system-right" size="small" (click)="next()" [class.active]="canNext"></ark-icon>
    <span class="total">
      of {{totalPages}}
    </span>
  </div>
</div>
