import { Component } from '@angular/core';
import {ModalComponent} from '@components/common/modal/modal.component';
import {Router} from '@angular/router';

@Component({
  selector: 'ark-not-logged',
  templateUrl: './not-logged.component.html',
  styleUrls: ['./not-logged.component.scss']
})
export class NotLoggedComponent  {

  constructor(protected readonly parent: ModalComponent, private router: Router,) {
    this.parent.closeButton = true;
  }

  public login(): void {
    this.parent.close()
    this.router.navigate(['/']).then();
  }
}
