import {Component, Input, OnInit} from '@angular/core';
import {AvatarSize} from '@interfaces/common.interface';
import {environment} from '@env/environment';

@Component({
  selector: 'ark-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss', './avatar.component.mobile.scss']
})
export class AvatarComponent implements OnInit {
  localImage = '';
  @Input() size: AvatarSize = 'medium';
  @Input() set image(value: string | undefined) {
    if (value) {
      if (/https?:\/\//.test(value)) {
        this.localImage = value;
      } else {
        this.localImage = environment.imageBaseUrl + value;
      }
    } else {
      this.localImage = '';
    }
  }
  get image(): string | undefined {
    return this.localImage;
  }

  constructor() { }

  ngOnInit(): void {
  }

}
