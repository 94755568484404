<div #wrapper
    class="wrapper"
    [formGroup]="form"
    [ngClass]="getFormClasses()"
    [class.expanded]="expanded"
    (click)="toggle($event)">
        <input #input
            class="input"
            (focus)="onFocus()"
            (blur)="onBlur()"
            (keydown.tab)="noTab ? disableTab($event) : null"
            [tabindex]="disabled ? -1 : tabIndex"
            [required]="required"
            [maxlength]="maxlength"
            formControlName="formValue"
            readonly>
        <div class="notch">
            <div class="lead"></div>
            <div class="main start">
                <label>{{label | translate}}</label>
            </div>
            <div class="trail"></div>
        </div>
    <div class="icon">
        <ark-icon
            [class.expanded]="expanded"
            icon="system-down"
            color="dark"
            size="small"></ark-icon>
    </div>
</div>

<div class="options">
    <ark-dropdown-options
        [type]="selectionType"
        [open]="expanded"
        [options]="filteredOptions"
        [localSelectedValues]="localSelectedValues"
        [minimum]="minimum"
        [maximum]="maximum"
        (cleared)="clear()"
        (saved)="save($event)"
        (selected)="select($event)"
        (changed)="optionsChanged($event)"
        (closed)="onClosed()"></ark-dropdown-options>
</div>


<div class="error" *ngIf="formStates.error" [@dropdownAnimation]>
    <span>{{errorMessage}}</span>
</div>
<div class="hint" *ngIf="info || hintLeft">
  <span class="hintLeft">{{hintLeft}}</span>
  <span>{{info}}</span>
</div>
