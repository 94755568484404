import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {AuthGuard} from '@guards/auth.guard';
import {NologinGuard} from '@guards/nologin.guard';

const routes: Routes = [
  // {path: '', loadChildren: () =>
  //     import('./pages/home/home.module').then(m => m.HomeModule), canActivate: [NologinGuard]},
  {path: '', loadChildren: () =>
      import('./pages/login/login.module').then(m => m.LoginModule), canActivate: [NologinGuard]},
  {path: 'reset', loadChildren: () =>
      import('./pages/reset/reset.module').then(m => m.ResetModule), canActivate: [NologinGuard]},
  {path: 'resetPassword', loadChildren: () =>
      import('./pages/reset-password/reset-password.module').then(m => m.ResetPasswordModule), canActivate: [NologinGuard]},
  {path: 'market-sectors', loadChildren: () =>
      import('./pages/categories/categories.module').then(m => m.CategoriesModule), canActivate: [AuthGuard]},
  {path: 'discussion/:id', loadChildren: () =>
      import('./pages/discussion-details/discussion-details.module').then(m => m.DiscussionDetailsModule)},
  {path: 'proposal/:id', loadChildren: () =>
      import('./pages/proposal-details/proposal-details.module').then(m => m.ProposalDetailsModule)},
  {path: 'search', loadChildren: () =>
      import('./pages/unified-search-results/unified-search-results.module').then(m => m.UnifiedSearchResultsModule)},
  {path: 'notifications', loadChildren: () =>
      import('./pages/notifications/notifications.module').then(m => m.NotificationsModule), canActivate: [AuthGuard]},
  {path: 'join', loadChildren: () =>
      import('./pages/join/join.module').then(m => m.JoinModule)},
  {path: 'supported', loadChildren: () =>
      import('./pages/supported/supported.module').then(m => m.SupportedModule), canActivate: [AuthGuard]},
  {path: 'profile', loadChildren: () =>
      import('./pages/profile/profile.module').then(m => m.ProfileModule), canActivate: [AuthGuard]},
  {path: 'logo', loadChildren: () =>
      import('./pages/logo/logo.module').then(m => m.LogoModule)},
  {path: 'dashboard', loadChildren: () =>
      import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule), canActivate: [AuthGuard]},
  {path: 'admin', loadChildren: () =>
      import('./pages/admin/admin.module').then(m => m.AdminModule), canActivate: [AuthGuard]},
  {path: '**', redirectTo: '', pathMatch: 'full'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
