import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {OptionsMenuItem, OptionsMenuItemIdentifier} from '@interfaces/common.interface';

@Component({
  selector: 'ark-options-menu-item',
  templateUrl: './options-menu-item.component.html',
  styleUrls: ['./options-menu-item.component.scss']
})
export class OptionsMenuItemComponent implements OnInit {

  @Input() item!: OptionsMenuItem;
  @Output() itemClicked: EventEmitter<OptionsMenuItemIdentifier> = new EventEmitter<OptionsMenuItemIdentifier>();

  constructor() { }

  ngOnInit(): void {
  }

  itemClick(): void {
    this.itemClicked.emit(this.item.identifier);
  }

}
