import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { distinctUntilChanged } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { IconImage, IconColor, IconSize } from '@interfaces/common.interface';

@Component({
  selector: 'ark-input-search',
  templateUrl: './input-search.component.html',
  styleUrls: ['./input-search.component.scss']
})
export class InputSearchComponent implements OnInit, OnDestroy {

  @ViewChild('input', { static: true })
  input!: ElementRef;

  @Input() label = '';
  @Input() errorLabel = 'Error Message';
  @Input() type = 'text';
  @Input() placeholder = '';
  @Input() disabled = false;

  @Output() valueCleared = new EventEmitter<any>();
  @Output() valueChanged = new EventEmitter<string>();

  tickIcon: IconImage = 'tick';
  tickIconColor: IconColor = '';
  tickIconSize: IconSize = 'input';

  closeIcon: IconImage = 'close';
  closeIconColor: IconColor = '';
  closeIconSize: IconSize = 'tiny';

  form: FormGroup = new FormGroup({});
  formSubscription: Subscription = new Subscription();

  constructor(private fb: FormBuilder) { }


  ngOnInit(): void {
    this.form = this.fb.group({
      formValue: new FormControl('', [Validators.required, Validators.email])
    });

    this.formSubscription = this.form.valueChanges.pipe(distinctUntilChanged()).subscribe((formValue: string) => {
      this.valueChanged.emit(formValue);
    });
  }

  ngOnDestroy(): void {
    if (this.formSubscription) {
      this.formSubscription.unsubscribe();
    }
  }

  clear(e: Event): void {
    e.preventDefault();
    e.stopPropagation();
    this.input.nativeElement.value = '';
    this.form.markAsPristine();
    this.form.markAsUntouched();
    this.valueCleared.emit();
  }
}
