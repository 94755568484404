<div class="item-container" (click)="gotoCategory()">
  <div class="decoration-line" [style.background-color]="color"></div>
  <div class="content">
    <h4>{{data.name}}</h4>
    <div class="typo-body-regular"><span>Proposals: </span>{{data.proposalsCount | number}} <span
      class="second">Discussions: </span>{{data.suggestionsCount | number}}</div>
    <div class="footer">
      <div class="left">
        <ark-icon icon="system-logo-new"></ark-icon>
        <h2>{{data.tokenSupportedCount | number: '1.2-2'}}</h2> Credits Pledged
      </div>
      <div class="right">
        <ark-icon icon="system-right"></ark-icon>
      </div>
    </div>
  </div>
</div>
