import {Component, OnInit} from '@angular/core';
import {NavigationEnd, Router, RouterOutlet} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {Settings} from 'luxon';
import {StorageService} from '@services/storage.service';
import {routerTransition} from './router.animations';
import {MetamaskService} from '@services/metamask.service';

@Component({
  selector: 'ark-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [routerTransition],
  providers: [MetamaskService]
})
export class AppComponent implements OnInit {
  currentRoute: string | undefined;
  currentParent: string | undefined = 'auth';

  constructor(
    private router: Router,
    private storage: StorageService,
    private translate: TranslateService,
    private metamask: MetamaskService
  ) {
//    this.matomo.init();
    router.events.subscribe(() => {
      this.currentRoute = this.router.url.split('?')[0];
      this.currentParent = this.currentRoute.split('/')[1];
    });
  }

  getState(outlet: RouterOutlet): string {
    return outlet.activatedRouteData['state'];
  }

  initDatabase(): void {
    if (!('indexedDB' in window)) {
      console.log('This browser does not support IndexedDB');
      return;
    }
    this.storage.openDatabase()
      .then(() => {
        // do nothing
      });
  }

  private setDefaultTranslation(): void {
    if (['en', 'el'].indexOf(<string>this.translate.getBrowserLang()) > -1) {
      this.translate.setDefaultLang(<string>this.translate.getBrowserLang());
      Settings.defaultLocale = this.translate.getBrowserLang() || 'en';
    } else {
      this.translate.setDefaultLang('en');
      Settings.defaultLocale = 'en_us';
    }
  }

  ngOnInit(): void {
    (window as any).global = window;
    this.initDatabase();
    this.setDefaultTranslation();
    // this.currentRoute = this.router.url.split('?')[0];
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }

}
