import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ark-send-invitation-success',
  templateUrl: './send-invitation-success.component.html',
  styleUrls: ['./send-invitation-success.component.scss']
})
export class SendInvitationSuccessComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
