// Icon Types

export type IconSize = 'xs' | 'micro' | 'pico' | 'tiny' | 'small' | 'medium' | 'large' | 'xlarge' | 'xl' | 'huge' | 'lhuge' | 'xhuge' | 'xxhuge'
  | 'input' | string;
export type AvatarSize = 'small' | 'xsmall' | 'forum' | 'preview' | 'medium' | 'large' | 'contributor'|
  'contributor-small' | 'profile' | string;

export type IconFill = 'fill' | 'hollow' | 'none' | string;

export type IconColor = 'error' | 'warning' | 'confirm' | 'info' | 'lightMaroon' | 'inactive' | 'disabled' | 'cta' | 'light' | 'dark' | 'social' | 'value' | 'value-wellbeing' |
  'value-social' | 'value-environmental' | 'value-economic' | 'value-integrity' | string;

export type IconImage = 'alert-error' | 'alert-information' | 'alert-success' | 'alert-warning' | 'aspirations-graph-petal-fill' |
  'aspirations-graph-petal-outline' | 'avatar-company' | 'avatar-individual' | 'campaign-ended' | 'campaign-error' |  'campaign-running' | 'campaign-waiting' | 'checkbox-active' | 'checkbox-inactive' |
  'experience-education' | 'experience-other' | 'experience-volunteering' | 'experience-work' | 'message-attach' |
  'message-cover-link' | 'message-file' | 'message-heading' | 'message-image-search' | 'message-link' | 'message-list-bullet' |
  'message-list-number' | 'message-mention' | 'message-picture' | 'message-quote-marks' | 'message-section-break' | 'message-send' |
  'message-share' | 'message-strikethrough' | 'message-text' | 'message-text-align-center' | 'message-text-align-justify' |
  'message-text-align-left' | 'message-text-align-right' | 'message-text-bold' | 'message-text-italic' | 'message-text-underline' |
  'message-trash' | 'message-video' | 'navigation-campaigns' | 'navigation-chat' | 'navigation-chat-alert' | 'navigation-community' |
  'navigation-company-profile' | 'navigation-company-profile-alert' | 'navigation-explore' | 'navigation-metamask' | 'navigation-profile'
  | 'navigation-products' | 'navigation-profile-alert' | 'navigation-saved' |  'navigation-saved-alert' | 'navigation-supported' |
  'navigation-profile-black' | 'navigation-home' | 'navigation-arrow-down' |
  'profile-about-us' | 'profile-activity' | 'profile-billing' | 'profile-contact' | 'profile-delete-account' | 'profile-help' |
  'profile-log-out' | 'profile-notifications' | 'profile-password' | 'profile-privacy' | 'profile-reports' | 'profile-sign-in' | 'profile-settings' |
  'profile-troubleshooting' | 'radio-button-active' | 'radio-button-inactive' | 'social-discord' | 'social-facebook' | 'social-instagram' | 'social-linkedin' |
  'social-messenger' | 'social-sms' | 'social-twitter' | 'social-whatsapp' | 'social-youtube' | 'status-badge-caster' |
  'status-badge-certifier' | 'status-badge-edit' | 'status-badge-influencer' | 'status-badge-unclaimed' | 'status-badge-verified' |
  'status-badge-visionary' | 'subscribe-base' | 'subscribe-previously-subscribed' | 'subscribe-subscribed' | 'system-arrow-down' |
  'system-arrow-left' | 'system-arrow-right' | 'system-arrow-up' | 'system-billing' | 'system-bookmark' | 'system-calendar' | 'system-card-view' |
  'system-close' | 'system-contract' | 'system-copy' | 'system-dislike' | 'system-down' | 'system-download' | 'system-edit' |
  'system-email' | 'system-expand' | 'system-external-link' | 'system-flag' | 'system-full-screen' | 'system-full-screen-exit' |
  'system-handle' | 'system-info' | 'system-left' | 'system-like' | 'system-location-pin' | 'system-lock' | 'system-logo' | 'system-map' |
  'system-menu' | 'system-minus' | 'system-more-horizontal' | 'system-more-vertical' | 'system-move' | 'system-notifications' |
  'system-notifications-alert' | 'system-phone' | 'system-plus' | 'system-posts' | 'system-press' | 'system-recently-seen' | 'system-record' |
  'system-redo' | 'system-refresh' | 'system-right' | 'system-save' | 'system-search' | 'system-settings' | 'system-star' |
  'system-switch' | 'system-tick' | 'system-time' | 'system-trash' | 'system-trending' | 'system-undo' | 'system-unlock' |
  'system-up' | 'system-upload' | 'system-video' | 'system-visibility-off' | 'system-visibility-on' | 'system-web' |
  'value-aspirations' | 'value-conflicting' | 'value-core' | 'value-new' | 'value-parent' | 'value-proposed' | 'value-search' |
  'value-subvalue' | 'value-category-economic-button' | 'value-category-economic-icon' | 'value-category-environmental-button' |
  'value-category-environmental-icon' | 'value-category-integrity-button' | 'value-category-integrity-icon' |
  'value-category-social-button' | 'value-category-social-icon' | 'value-category-wellbeing-button' | 'logo-arkius' |
  'certifications-empty' | 'products-empty' | 'campaign-empty' | 'plus' | 'minus' | 'navigation-contact' |
  'thumb-up' | 'thumb-up-black' | 'proposal-edit' | 'arkius-user' | 'back-arrow' | 'like' | 'share' | 'add-post' |
  'left-arrow' | 'right-arrow' | 'value-category-wellbeing-icon' | 'wallet-avalance' | 'display' | string;

export interface IconItem {
  id: string;
  category: IconCategory;
}

export enum IconCategory {
  ALERT = 'Alert',
  ASPIRATIONS_GRAPH = 'Aspirations Graph',
  AVATAR = 'Avatar',
  CAMPAIGN = 'Campaign',
  CERTIFICATIONS = 'Certifications',
  CHECKBOX = 'Checkbox',
  EXPERIENCE = 'Experience',
  MESSAGE = 'Message',
  NAVIGATION = 'Navigation',
  PROFILE = 'Profile',
  PRODUCTS = 'Products',
  RADIO_BUTTON = 'Radio Button',
  SOCIAL = 'Social',
  STATUS_BADGES = 'Status Badges',
  SUBSCRIBE = 'Subscribe',
  SYSTEM = 'System',
  VALUE = 'Value',
  VALUE_CATEGORY = 'Value Category',
  WALLET = 'Wallet',
  PROPOSAL = 'Proposal'
}

// Modal

export type ModalOverlayStyle = 'default' | 'dark' | string;

export enum ModalSize {
  DYNAMIC = 'dynamic',
  CONFIRM = 'confirm',
  BASE = 'base',
  SMALL = 'small',
  MEDIUM = 'medium',
  DEFAULT = 'default',
  LARGE = 'large',
  XLARGE = 'xlarge',
  FULLSCREEN = 'fullScreen'
}

// Api Service

export type HttpMethods = 'get' | 'post' | 'patch' | 'upload' | 'delete' | 'put' | 'multipart' | 'download' | string;

// Dropdown

export interface DropDownItem {
  label: string;
  id: string | null | undefined | number;
  disabled?: boolean;
  icon?: string;
  color?: string;
}

// Likes / Dislikes

export interface VoteTally {
  positive: number | undefined;
  negative: number | undefined;
}

// Password conditions {

export interface PasswordConditions {
  uppercase: boolean;
  lowercase: boolean;
  number: boolean;
  length: boolean;
}

/**
 * Interface for a user's Avatar
 *
 * @interface Avatar
 *
 * @property imageUrl {string} - Optional, the url to the user's avatar image
 * @property verified {boolean} - Whether the user is a verified one. Will show a badge above the avatar image if true
 */
export interface Avatar {
  imageUrl?: string;
  verified?: boolean;
}

/**
 * Interface for Popup Options Menus
 *
 * @interface OptionsMenu
 *
 * @property items {Array<OptionsMenuItem>} - Array of the list of options in the menu
 */
export interface OptionsMenu {
  items: Array<OptionsMenuItem>;
}

/**
 * Interface for an item of the Options Menu
 *
 * @interface OptionsMenuItem
 *
 * @property label {string} - The textual label of the item
 * @property icon {IconImage} - The Icon of the item
 * @property visible {boolean} - Condition to satisfy for the item to be visible
 * @property identifier {OptionsMenuItemIdentifier} - A textual identifier for click events
 */
export interface OptionsMenuItem {
  label: string;
  icon: IconImage;
  visible?: boolean;
  identifier: OptionsMenuItemIdentifier;
}

export enum OptionsMenuItemIdentifier {
  COMMENT_DELETE = 'comment.delete',
  COMMENT_EDIT = 'comment.edit',
  COMMENT_HIDE = 'comment.hide',
  POST_DELETE = 'post.delete',
  POST_EDIT = 'post.edit',
  POST_HIDE = 'post.hide',
  REVIEW_DELETE = 'review.delete',
  REVIEW_EDIT = 'review.edit'
}

export type NavigationPage = 'join' | 'join/complete' | 'join/invitation' | 'join/membership' | 'join/nft' | 'join/payment' | 'admin' | 'dashboard' |
  'proposals' | 'proposals/add' | 'proposals/list' | '/' | string;

export interface PaginationCallbackResponse {
  entities: any;
  count: number;
  offset: number;
  limit: number;
}

export interface PaginationCallback {
  (limit: number, offset: number, self?: any): Promise<PaginationCallbackResponse>
}

export interface Pagination {
  totalCount: number;
  pageSize: number;
  currentPage: number;
  totalPages: number;
  previousPage: boolean;
  nextPage: boolean;
}

export interface PaginationRequest {
  pageNumber: number;
  pageSize: number;
}
