<div class="post-action-bar--wrapper" [ngClass]="{'expanded': expanded}">
  <div class="start">
    <div class="comment-section" *ngIf="!!metadata.commentsTotal" (click)="toggleComments()">
      <div class="comments">{{ metadata.commentsTotal}}
        {{ util.isPlural(metadata.commentsTotal > 1, 'COMPONENTS.POST.' + (comment ? 'REPLY' : 'COMMENT')) | translate}}</div>
      <ark-icon icon="system-down" color="inactive" size="small"></ark-icon>
    </div>
    <div class="reply-section" (click)="reply()" *ngIf="comment">
      <div class="comments">{{ 'COMPONENTS.POST.REPLY.SINGULAR' | translate}}</div>
    </div>
  </div>
  <div class="end">
    <ark-vote *ngIf="comment"
              [votes]="votes?.likes"
              [voted]="!!votes?.myVote"
              (userVote)="doVote($event)">
    </ark-vote>
  </div>
</div>
