<div [formGroup]="form" class="wrapper" [ngClass]="getFormClasses()">
  <input
         type="checkbox"
         class="checkbox"
         [tabIndex]="tabIndex"
         (focus)="onFocus()"
         (blur)="onBlur()"
         (keydown.space)="toggleSelection($event)"
         (click)="toggleSelection($event)"
  >
  <label>{{label}}<ng-content *ngIf="!label"></ng-content></label>
  <ark-icon icon="system-tick" color="light" size="micro" class="checkbox-tick"></ark-icon>
</div>
<div class="error" *ngIf="formStates.error" [@dropdownAnimation]>
  <span [innerHTML]="errorMessage"></span>
</div>
