import {IconCategory, IconItem} from '@interfaces/common.interface';

export const iconRegistry: Array<IconItem> = [
  { category: IconCategory.ALERT, id: 'alert-error'},
  { category: IconCategory.ALERT, id: 'alert-information'},
  { category: IconCategory.ALERT, id: 'alert-success'},
  { category: IconCategory.ALERT, id: 'alert-warning'},
  { category: IconCategory.ASPIRATIONS_GRAPH, id: 'aspirations-graph-petal-fill'},
  { category: IconCategory.ASPIRATIONS_GRAPH, id: 'aspirations-graph-petal-outline'},
  { category: IconCategory.AVATAR, id: 'avatar-company'},
  { category: IconCategory.AVATAR, id: 'avatar-individual'},
  { category: IconCategory.CAMPAIGN, id: 'campaign-ended'},
  { category: IconCategory.CAMPAIGN, id: 'campaign-error'},
  { category: IconCategory.CAMPAIGN, id: 'campaign-running'},
  { category: IconCategory.CAMPAIGN, id: 'campaign-waiting'},
  { category: IconCategory.CAMPAIGN, id: 'display'},
  { category: IconCategory.CAMPAIGN, id: 'campaign-empty'},
  { category: IconCategory.CERTIFICATIONS, id: 'certifications-empty'},
  { category: IconCategory.CHECKBOX, id: 'checkbox-active'},
  { category: IconCategory.CHECKBOX, id: 'checkbox-inactive'},
  { category: IconCategory.EXPERIENCE, id: 'experience-education'},
  { category: IconCategory.EXPERIENCE, id: 'experience-other'},
  { category: IconCategory.EXPERIENCE, id: 'experience-volunteering'},
  { category: IconCategory.EXPERIENCE, id: 'experience-work'},
  { category: IconCategory.MESSAGE, id: 'message-attach'},
  { category: IconCategory.MESSAGE, id: 'message-cover-link'},
  { category: IconCategory.MESSAGE, id: 'message-file'},
  { category: IconCategory.MESSAGE, id: 'message-heading'},
  { category: IconCategory.MESSAGE, id: 'message-image-search'},
  { category: IconCategory.MESSAGE, id: 'message-link'},
  { category: IconCategory.MESSAGE, id: 'message-list-bullet'},
  { category: IconCategory.MESSAGE, id: 'message-list-number'},
  { category: IconCategory.MESSAGE, id: 'message-mention'},
  { category: IconCategory.MESSAGE, id: 'message-picture'},
  { category: IconCategory.MESSAGE, id: 'message-quote-marks'},
  { category: IconCategory.MESSAGE, id: 'message-section-break'},
  { category: IconCategory.MESSAGE, id: 'message-send'},
  { category: IconCategory.MESSAGE, id: 'message-share'},
  { category: IconCategory.MESSAGE, id: 'message-strikethrough'},
  { category: IconCategory.MESSAGE, id: 'message-text'},
  { category: IconCategory.MESSAGE, id: 'message-text-align-center'},
  { category: IconCategory.MESSAGE, id: 'message-text-align-justify'},
  { category: IconCategory.MESSAGE, id: 'message-text-align-left'},
  { category: IconCategory.MESSAGE, id: 'message-text-align-right'},
  { category: IconCategory.MESSAGE, id: 'message-text-bold'},
  { category: IconCategory.MESSAGE, id: 'message-text-italic'},
  { category: IconCategory.MESSAGE, id: 'message-text-underline'},
  { category: IconCategory.MESSAGE, id: 'message-trash'},
  { category: IconCategory.MESSAGE, id: 'message-video'},
  { category: IconCategory.NAVIGATION, id: 'navigation-arrow-down'},
  { category: IconCategory.NAVIGATION, id: 'navigation-campaigns'},
  { category: IconCategory.NAVIGATION, id: 'navigation-chat'},
  { category: IconCategory.NAVIGATION, id: 'navigation-chat-alert'},
  { category: IconCategory.NAVIGATION, id: 'navigation-community'},
  { category: IconCategory.NAVIGATION, id: 'navigation-company-profile'},
  { category: IconCategory.NAVIGATION, id: 'navigation-company-profile-alert'},
  { category: IconCategory.NAVIGATION, id: 'navigation-explore'},
  { category: IconCategory.NAVIGATION, id: 'navigation-home'},
  { category: IconCategory.NAVIGATION, id: 'navigation-contact'},
  { category: IconCategory.NAVIGATION, id: 'navigation-metamask'},
  { category: IconCategory.NAVIGATION, id: 'navigation-products'},
  { category: IconCategory.NAVIGATION, id: 'navigation-profile'},
  { category: IconCategory.NAVIGATION, id: 'navigation-profile-alert'},
  { category: IconCategory.NAVIGATION, id: 'navigation-profile-black'},
  { category: IconCategory.NAVIGATION, id: 'navigation-saved'},
  { category: IconCategory.NAVIGATION, id: 'navigation-saved-alert'},
  { category: IconCategory.NAVIGATION, id: 'navigation-saved-alert'},
  { category: IconCategory.NAVIGATION, id: 'navigation-supported'},
  { category: IconCategory.PRODUCTS, id: 'products-empty'},
  { category: IconCategory.PROFILE, id: 'profile-about-us'},
  { category: IconCategory.PROFILE, id: 'profile-activity'},
  { category: IconCategory.PROFILE, id: 'profile-billing'},
  { category: IconCategory.PROFILE, id: 'profile-contact'},
  { category: IconCategory.PROFILE, id: 'profile-delete-account'},
  { category: IconCategory.PROFILE, id: 'profile-help'},
  { category: IconCategory.PROFILE, id: 'profile-log-out'},
  { category: IconCategory.PROFILE, id: 'profile-notifications'},
  { category: IconCategory.PROFILE, id: 'profile-password'},
  { category: IconCategory.PROFILE, id: 'profile-privacy'},
  { category: IconCategory.PROFILE, id: 'profile-reports'},
  { category: IconCategory.PROFILE, id: 'profile-settings'},
  { category: IconCategory.PROFILE, id: 'profile-sign-in'},
  { category: IconCategory.PROFILE, id: 'profile-troubleshooting'},
  { category: IconCategory.RADIO_BUTTON, id: 'radio-button-active'},
  { category: IconCategory.RADIO_BUTTON, id: 'radio-button-inactive'},
  { category: IconCategory.SOCIAL, id: 'social-discord'},
  { category: IconCategory.SOCIAL, id: 'social-facebook'},
  { category: IconCategory.SOCIAL, id: 'social-instagram'},
  { category: IconCategory.SOCIAL, id: 'social-linkedin'},
  { category: IconCategory.SOCIAL, id: 'social-messenger'},
  { category: IconCategory.SOCIAL, id: 'social-sms'},
  { category: IconCategory.SOCIAL, id: 'social-twitter'},
  { category: IconCategory.SOCIAL, id: 'social-whatsapp'},
  { category: IconCategory.SOCIAL, id: 'social-youtube'},
  { category: IconCategory.SOCIAL, id: 'logo-arkius'},
  { category: IconCategory.STATUS_BADGES, id: 'status-badge-caster'},
  { category: IconCategory.STATUS_BADGES, id: 'status-badge-certifier'},
  { category: IconCategory.STATUS_BADGES, id: 'status-badge-edit'},
  { category: IconCategory.STATUS_BADGES, id: 'status-badge-influencer'},
  { category: IconCategory.STATUS_BADGES, id: 'status-badge-unclaimed'},
  { category: IconCategory.STATUS_BADGES, id: 'status-badge-verified'},
  { category: IconCategory.STATUS_BADGES, id: 'status-badge-visionary'},
  { category: IconCategory.SUBSCRIBE, id: 'subscribe-base'},
  { category: IconCategory.SUBSCRIBE, id: 'subscribe-previously-subscribed'},
  { category: IconCategory.SUBSCRIBE, id: 'subscribe-subscribed'},
  { category: IconCategory.SYSTEM, id: 'system-arrow-down'},
  { category: IconCategory.SYSTEM, id: 'system-arrow-left'},
  { category: IconCategory.SYSTEM, id: 'system-arrow-right'},
  { category: IconCategory.SYSTEM, id: 'system-arrow-up'},
  { category: IconCategory.SYSTEM, id: 'system-billing'},
  { category: IconCategory.SYSTEM, id: 'system-bookmark'},
  { category: IconCategory.SYSTEM, id: 'system-calendar'},
  { category: IconCategory.SYSTEM, id: 'system-card-view'},
  { category: IconCategory.SYSTEM, id: 'system-close'},
  { category: IconCategory.SYSTEM, id: 'system-contract'},
  { category: IconCategory.SYSTEM, id: 'system-copy'},
  { category: IconCategory.SYSTEM, id: 'system-dislike'},
  { category: IconCategory.SYSTEM, id: 'system-down'},
  { category: IconCategory.SYSTEM, id: 'system-download'},
  { category: IconCategory.SYSTEM, id: 'system-edit'},
  { category: IconCategory.SYSTEM, id: 'system-email'},
  { category: IconCategory.SYSTEM, id: 'system-expand'},
  { category: IconCategory.SYSTEM, id: 'system-external-link'},
  { category: IconCategory.SYSTEM, id: 'system-flag'},
  { category: IconCategory.SYSTEM, id: 'system-full-screen'},
  { category: IconCategory.SYSTEM, id: 'system-full-screen-exit'},
  { category: IconCategory.SYSTEM, id: 'system-handle'},
  { category: IconCategory.SYSTEM, id: 'system-info'},
  { category: IconCategory.SYSTEM, id: 'system-left'},
  { category: IconCategory.SYSTEM, id: 'system-like'},
  { category: IconCategory.SYSTEM, id: 'system-location-pin'},
  { category: IconCategory.SYSTEM, id: 'system-lock'},
  { category: IconCategory.SYSTEM, id: 'system-logo'},
  { category: IconCategory.SYSTEM, id: 'system-logo-new'},
  { category: IconCategory.SYSTEM, id: 'system-map'},
  { category: IconCategory.SYSTEM, id: 'system-menu'},
  { category: IconCategory.SYSTEM, id: 'system-minus'},
  { category: IconCategory.SYSTEM, id: 'system-more-horizontal'},
  { category: IconCategory.SYSTEM, id: 'system-more-vertical'},
  { category: IconCategory.SYSTEM, id: 'system-move'},
  { category: IconCategory.SYSTEM, id: 'system-notifications'},
  { category: IconCategory.SYSTEM, id: 'system-notifications-alert'},
  { category: IconCategory.SYSTEM, id: 'system-phone'},
  { category: IconCategory.SYSTEM, id: 'system-plus'},
  { category: IconCategory.SYSTEM, id: 'system-posts'},
  { category: IconCategory.SYSTEM, id: 'system-press'},
  { category: IconCategory.SYSTEM, id: 'system-recently-seen'},
  { category: IconCategory.SYSTEM, id: 'system-record'},
  { category: IconCategory.SYSTEM, id: 'system-redo'},
  { category: IconCategory.SYSTEM, id: 'system-refresh'},
  { category: IconCategory.SYSTEM, id: 'system-right'},
  { category: IconCategory.SYSTEM, id: 'system-save'},
  { category: IconCategory.SYSTEM, id: 'system-search'},
  { category: IconCategory.SYSTEM, id: 'system-settings'},
  { category: IconCategory.SYSTEM, id: 'system-star'},
  { category: IconCategory.SYSTEM, id: 'system-switch'},
  { category: IconCategory.SYSTEM, id: 'system-tick'},
  { category: IconCategory.SYSTEM, id: 'system-time'},
  { category: IconCategory.SYSTEM, id: 'system-trash'},
  { category: IconCategory.SYSTEM, id: 'system-trending'},
  { category: IconCategory.SYSTEM, id: 'system-undo'},
  { category: IconCategory.SYSTEM, id: 'system-unlock'},
  { category: IconCategory.SYSTEM, id: 'system-up'},
  { category: IconCategory.SYSTEM, id: 'system-upload'},
  { category: IconCategory.SYSTEM, id: 'system-user'},
  { category: IconCategory.SYSTEM, id: 'system-video'},
  { category: IconCategory.SYSTEM, id: 'system-visibility-off'},
  { category: IconCategory.SYSTEM, id: 'system-visibility-on'},
  { category: IconCategory.SYSTEM, id: 'system-web'},
  { category: IconCategory.VALUE, id: 'value-aspirations'},
  { category: IconCategory.VALUE, id: 'value-conflicting'},
  { category: IconCategory.VALUE, id: 'value-core'},
  { category: IconCategory.VALUE, id: 'value-new'},
  { category: IconCategory.VALUE, id: 'value-parent'},
  { category: IconCategory.VALUE, id: 'value-proposed'},
  { category: IconCategory.VALUE, id: 'value-search'},
  { category: IconCategory.VALUE, id: 'value-subvalue'},
  { category: IconCategory.VALUE_CATEGORY, id: 'value-category-economic-button'},
  { category: IconCategory.VALUE_CATEGORY, id: 'value-category-economic-icon'},
  { category: IconCategory.VALUE_CATEGORY, id: 'value-category-environmental-button'},
  { category: IconCategory.VALUE_CATEGORY, id: 'value-category-environmental-icon'},
  { category: IconCategory.VALUE_CATEGORY, id: 'value-category-integrity-button'},
  { category: IconCategory.VALUE_CATEGORY, id: 'value-category-integrity-icon'},
  { category: IconCategory.VALUE_CATEGORY, id: 'value-category-social-button'},
  { category: IconCategory.VALUE_CATEGORY, id: 'value-category-social-icon'},
  { category: IconCategory.VALUE_CATEGORY, id: 'value-category-wellbeing-button'},
  { category: IconCategory.VALUE_CATEGORY, id: 'value-category-wellbeing-icon'},
  { category: IconCategory.WALLET, id: 'wallet-avalanche'},
  { category: IconCategory.PROPOSAL, id: 'add-post'},
  { category: IconCategory.PROPOSAL, id: 'arkius-user'},
  { category: IconCategory.PROPOSAL, id: 'back-arrow'},
  { category: IconCategory.PROPOSAL, id: 'minus'},
  { category: IconCategory.PROPOSAL, id: 'like'},
  { category: IconCategory.PROPOSAL, id: 'left-arrow'},
  { category: IconCategory.PROPOSAL, id: 'plus'},
  { category: IconCategory.PROPOSAL, id: 'proposal-edit'},
  { category: IconCategory.PROPOSAL, id: 'right-arrow'},
  { category: IconCategory.PROPOSAL, id: 'share'},
  { category: IconCategory.PROPOSAL, id: 'thumb-up'},
  { category: IconCategory.PROPOSAL, id: 'thumb-up-black'},



];

