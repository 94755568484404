<div class="wrapper--chip-selection">
    <div #containerRef class="chip-input" [class.focus]="focus" (click)="containerClick($event)">
        <input #inputRef name="search" autocomplete="off" tabindex="0" [value]="selectedValues || ''"
            [placeholder]="!!selectedValues ? placeholder : ''" (click)="containerClick($event)" readonly>
        <ark-icon [class.expanded]="expanded" class="arrow-icon" icon="down" color="dark" size="small"
            (click)="toggle($event)"></ark-icon>
        <label [class.focus]="focus || !!selectedValues" class="floating-label">{{label}}</label>
    </div>
    <div #dropdownRef class="container-dropdown">
        <ul class="dropdown" [@dropdownAnimation] *ngIf="expanded">
            <div class="dropdown-items">
                <li *ngFor="let item of items" class="item" (click)="select(item)"
                    [class.selected]="isSelected(item[displayProperty])">
                    <span>{{item[displayProperty]}}</span>
                    <ark-icon *ngIf="isSelected(item[displayProperty])" class="tick" [icon]="'tick'" [size]="'small'"
                        [color]="'confirm'"></ark-icon>
                </li>
            </div>

            <div class="dropdown-footer">
                <span class="label" (click)="cancel()">Clear</span>
                <ark-button [disabled]="selectedItems.length <= 0" [size]="'small'" (click)="save()">SAVE</ark-button>
            </div>
        </ul>

    </div>
</div>