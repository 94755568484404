import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  ElementRef,
  OnDestroy,
  Type,
  ViewChild
} from '@angular/core';
import {Subject} from 'rxjs';
import {ModalInsertionDirective} from '@directives/modal-insertion.directive';
import {ModalRef} from './modal-ref';
import {modalAnimation} from './modal-animation';
import {ModalOverlayStyle, ModalSize} from '@interfaces/common.interface';
import { ModalConfig } from './modal.config';

@Component({
  selector: 'ark-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss', './modal.component.mobile.scss'],
  animations: [modalAnimation()]
})
export class ModalComponent implements AfterViewInit, OnDestroy {

  componentRef!: ComponentRef<any>;

  @ViewChild(ModalInsertionDirective)
  insertionPoint!: ModalInsertionDirective;

  size: ModalSize = ModalSize.DEFAULT;
  data: any;
  style: ModalOverlayStyle = 'default';
  closeDisable: boolean = false;
  childComponentType!: Type<any>;
  closeButton?: boolean = true;
  private readonly _onClose = new Subject<any>();
  public onClose = this._onClose.asObservable();
  public scroll = false;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private cd: ChangeDetectorRef,
    private modalRef: ModalRef,
    private elRef: ElementRef,
    private modalConfig: ModalConfig) {
      this.style = this.modalConfig.style || 'default';
      this.data = this.modalConfig.data;
      this.closeButton = this.modalConfig.closeButton || this.modalConfig.size === ModalSize.CONFIRM;
  }

  ngAfterViewInit(): void {
    this.loadChildComponent(this.childComponentType);
    this.cd.detectChanges();

    window.addEventListener('keydown', (e: KeyboardEvent) => {
      if (e.keyCode === 27) {
        this.onOverlayClicked();
      }
    });
  }

  onOverlayClicked(check: boolean = false): void {
    if (check) {
      const html = this.elRef.nativeElement.innerHTML;
      const dirty =  html.indexOf('ng-dirty') !== -1;
      if (!dirty && !this.closeDisable) {
        this.modalRef.close();
      }
    } else if (!this.closeDisable) {
      this.modalRef.close();
    }
  }

  onDialogClicked(evt: MouseEvent): void {
    evt.stopPropagation();
  }

  loadChildComponent(componentType: Type<any>): void {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(componentType);

    const viewContainerRef = this.insertionPoint.viewContainerRef;
    viewContainerRef.clear();

    this.componentRef = viewContainerRef.createComponent(componentFactory);
  }

  ngOnDestroy(): void {
    if (this.componentRef) {
      this.componentRef.destroy();
    }
  }

  close<T>(val?: T): void {
    if (!this.closeDisable) {
      this.modalRef.close(val);
      this._onClose.next(val);
    }
  }
}
