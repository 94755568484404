import {Injectable} from '@angular/core';
import {GlobalService} from './global.service';
import {openDB} from 'idb';
import {Store} from '@interfaces/store.interface';
import {Storage} from '@constants/storage';

@Injectable()
export class StorageService {

  public dbPromise: any;

  constructor(
    private global: GlobalService
  ) {
  }

  async openDatabase(store: string = Storage.SESSION): Promise<any> {
    this.dbPromise = await openDB(this.global.get('dbName'), 1, {
      upgrade(database, oldVersion): any {
        switch (oldVersion) {
          case 0:
            database.createObjectStore(store);
        }
      }
    });
  }

  store(objectStore: string): Store | null{
    if (this.dbPromise) {
      return {
        get: async (key) => {
          return (await this.dbPromise).get(objectStore, key);
        },
        getAll: async (key) => {
          return (await this.dbPromise).get(objectStore, key);
        },
        set: async (key, val) => {
          return (await this.dbPromise).put(objectStore, val, key);
        },
        add: async (val) => {
          return (await this.dbPromise).add(objectStore, val);
        },
        delete: async (key) => {
          return (await this.dbPromise).delete(objectStore, key);
        },
        clear: async () => {
          return (await this.dbPromise).clear(objectStore);
        },
        keys: async () => {
          return (await this.dbPromise).getAllKeys(objectStore);
        },
        count: async () => {
          return (await this.dbPromise).count(objectStore);
        }
      };
    } else {
      return null;
    }
  }

}
