import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Renderer2, SimpleChanges,
  ViewChild
} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {AvatarSize} from '@interfaces/common.interface';
const jazzicon = require('@metamask/jazzicon');
const Identicon = require('identicon.js');

@Component({
  selector: 'ark-identicon',
  templateUrl: './identicon.component.html',
  styleUrls: ['./identicon.component.scss']
})
export class IdenticonComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() address = '';
  @Input() size: AvatarSize = 'medium';
  @Input() type: 'identicon' | 'jazzicon' = 'jazzicon';
  @ViewChild('icon') icon!: ElementRef;

  iconSizes = {
    'xsmall': 4,
    'small': 32,
    'forum': 36,
    'preview': 160,
    'medium': 96,
    'contributor': 104,
    'contributor-small': 56,
    'profile': 144,
    'large': 72
  }

  constructor(private renderer: Renderer2,
              @Inject(DOCUMENT) private document: Document) { }

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {

  }

  generateImage(): void {
    let el;
    if (this.type === 'jazzicon') {
      // @ts-ignore
      el = jazzicon(this.iconSizes[this.size], parseInt(this.address.substring(2, 10), 16));
    } else {
      const options = {
        size: 420,
        format: 'svg'
      };
      const data = new Identicon(this.address, options).toString();
      el = this.document.createElement('img');
      el.setAttribute('width', '100%');
      el.setAttribute('height', '100%');
      el.setAttribute('title', this.address);
      el.setAttribute('src', `data:image/svg+xml;base64,${data}`);
    }
    this.renderer.appendChild(this.icon.nativeElement, el);
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'address': {
            setTimeout(() => this.generateImage());
          }
        }
      }
    }
  }

}
