import { Injectable } from '@angular/core';
import { ActiveSurvey, Survey, SurveyAllRequest, SurveyAllResponse } from '@app/etc/interfaces/api.survey.interface';
import { ApiService } from '@app/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class SurveyModelService {


  constructor(private api: ApiService) { }

  public async surveyList(data: SurveyAllRequest): Promise<SurveyAllResponse> {
    return new Promise((resolve, reject) => {
      this.api.survey.list.get(data).subscribe((response: SurveyAllResponse) => {
        resolve(response);
      }, (error) => {
        reject(error);
      });
    });
  }


  public async createSurvey(data:Survey): Promise<any> {
    return new Promise((resolve, reject) => {
      this.api.survey.create.post(data).subscribe((response: any) => {
        resolve(response);
      }, (error) => {
        reject(error);
      });
    });
  }

  public async updateSurvey({ data }: { data: Survey; }): Promise<any> {
    return new Promise((resolve, reject) => {
      this.api.survey.update.put(data).subscribe((response: any) => {
        resolve(response);
      }, (error) => {
        reject(error);
      });
    });
  }

  public async deleteSurvey(id: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.api.survey.delete.delete(id).subscribe((response: any) => {
        resolve(response);
      }, (error) => {
        reject(error);
      });
    });
  }

  public async activeSurvey(): Promise<ActiveSurvey> {
    return new Promise((resolve, reject) => {
      this.api.survey.active.get().subscribe((response: ActiveSurvey) => {
        resolve(response);
      }, (error) => {
        reject(error);
      });
    });
  }
}
