import {Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import {DropDownItem} from '@interfaces/common.interface';

@Component({
  selector: 'ark-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss']
})
export class DropdownComponent implements OnInit {

  @Input() label = '';
  @Input() showBackground = true;
  @Input() showBorder = true;
  @Input() iconSize = 'small';
  @Input() iconColor = 'dark';
  @Input() iconContent = 'system-down';
  @Input() size = 'large';
  @Input() type = 'box';
  selected: DropDownItem = {
    label: '',
    id: '',
  };
  @Input() items: Array<DropDownItem> = [{
    label: '',
    id: ''
  }];
  @Output() itemSelected = new EventEmitter();

  expanded = false;

  constructor(private eRef: ElementRef) {
  }

  @HostListener('document:click', ['$event'])
  out(event: any): void {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.expanded = false;
    }
  }

  ngOnInit(): void {
    if (this.type == 'box') {
      this.selected = this.items[0];
    }
  }

  toggleExpand(): void {
    this.expanded = !this.expanded;
  }

  selectItem(item: DropDownItem): void {
    if (!item.disabled) {
      if ((this.selected.id !== item.id) && this.type == 'box') {
        this.selected = item;
      }
      this.itemSelected.emit(item);
      this.expanded = false;
    }
  }

}
