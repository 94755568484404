import { Component, Input, Output, EventEmitter, ViewChild, ElementRef, HostListener } from '@angular/core';
import { dropdownAnimation } from '@animations/dropdown.animations';
import { DropDownOption, DropDownType } from '@interfaces/form.interface';

@Component({
  selector: 'ark-dropdown-options',
  templateUrl: './dropdown-options.component.html',
  styleUrls: ['./dropdown-options.component.scss'],
  animations: [dropdownAnimation()]
})
export class DropdownOptionsComponent {

  @ViewChild('wrapper')
  wrapper!: ElementRef;

  @Input() open = false;
  @Input() options: DropDownOption[] = [];
  @Input() localSelectedValues:DropDownOption[] = [];
  @Input() type: DropDownType = 'list';
  @Input() minimum = 1;
  @Input() maximum = 3;

  @Output() saved = new EventEmitter<DropDownOption[]>();
  @Output() closed = new EventEmitter<void>();
  @Output() cleared = new EventEmitter<void>();

  @Output() changed = new EventEmitter<DropDownOption[]>();
  @Output() selected = new EventEmitter<DropDownOption>();


  get empty(): boolean {
    return this.options.filter(e => e.selected)?.length <= 0;
  }
  get valid(): boolean {
    return this.options.filter(e => e.selected)?.length >= this.minimum;
  }

  constructor() { }

  @HostListener('document:click', ['$event.target'])
  public onClick(target: any): void {
    const wrapperRef = this.wrapper?.nativeElement;
    if (wrapperRef && !wrapperRef.contains(target)) {
      this.open = false;
      this.closed.emit();
    }
  }

  select(option: DropDownOption): void {
    const f = this.localSelectedValues.find(l => l.value === option.value);
    if (this.localSelectedValues.length < this.maximum) {
      option.selected = !option.selected;
      if (option.selected) {
        this.selected.emit(option);
      }
      this.changed.emit(this.options.filter(e => e.selected));
    } else {
      if (f) {
        option.selected = !option.selected;
        if (option.selected) {
          this.selected.emit(option);
        }
        this.changed.emit(this.options.filter(e => e.selected));
      }
    }
  }

  selectOne(option: DropDownOption): void {
    this.clear();
    option.selected = true;
    this.selected.emit(option);
    this.closed.emit();
  }

  save(): void {
      this.saved.emit(this.options.filter(e => e.selected));
      this.closed.emit();
  }

  clear(): void {
    this.options.forEach(e => e.selected = false);
    this.cleared.emit();
  }
}
