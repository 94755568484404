import {Component, OnDestroy, OnInit} from '@angular/core';
import {SessionService} from '@services/session.service';
import {NavigationService} from '@services/navigation.service';
import {NavigationEnd, Router} from '@angular/router';
import {GlobalService} from '@services/global.service';
import {BroadcastEvent} from '@interfaces/broadcast.interface';
import {IntercomService} from '@services/intercom.service';
import {first, Subject, takeUntil, tap} from 'rxjs';
import {ModalService} from '@services/modal.service';
import {ModalConfig} from '@components/common/modal/modal.config';
import {ModalSize} from '@interfaces/common.interface';
import {
  RequestInvitationComponent
} from '@components/modals/invitation/request-invitation/request-invitation.component';
import {
  RequestInvitationSuccessComponent
} from '@components/modals/invitation/request-invitation-success/request-invitation-success.component';
import { NftModelService } from '@app/models/nft-model.service';
import {environment} from '@env/environment';
import {MemberSettingsComponent} from '@components/modals/navigation/member-settings/member-settings.component';
import {NotificationsModelService} from '@models/notifications-model.service';
import {INotification} from '@interfaces/notifications.interfase';

@Component({
  selector: 'ark-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit, OnDestroy {

  public currentRoute = '';
  public navigationHidden = true;
  public navigationAdmin = false;
  public openProfile = false;
  public menuHidden = false;
  public hiddenPages = ['/', '/join', '/join/invitation', '/logo', '/create', '/reset', '/resetPassword'];
  public hiddenAdmin = ['/admin', '/admin/invitations', '/admin/categories', '/admin/invitations/requests', '/admin/surveys'];
  public hiddenMenu = ['/join/membership', '/join/nft'];
  loggedIn = false;
  nft = false;
  nftImgUrl!: string;
  nftItemId!: number;
  nftUrl!: string;
  selectedPackage = '';
  public notificationsCount = 0;
  private notificationsLoaded: boolean = false;

  protected readonly destroy$ = new Subject();
  private modalConfig: ModalConfig = {
    size: ModalSize.DYNAMIC,
    style: 'dark'
  };

  constructor(
    private session: SessionService,
    private global: GlobalService,
    private router: Router,
    private intercom: IntercomService,
    private navigation: NavigationService,
    private nftSrv: NftModelService,
    private modal: ModalService,
    private notificationsModelService: NotificationsModelService
  ) {
    this.intercom.on(BroadcastEvent.USER_LOGGED_IN, (data: any) => {
     this.loggedIn = true;
     this.getNft();
    });
    this.intercom.on(BroadcastEvent.USER_LOGGED_OUT, () => {
      //this.loggedIn = false;
    });
    this.intercom.on(BroadcastEvent.SHOW_NAVIGATION, () => {
      this.menuHidden = false;
    });
    this.intercom.on(BroadcastEvent.HIDE_NAVIGATION, () => {
      this.menuHidden = true;
    });
    this.intercom.on(BroadcastEvent.NOTIFICATION_CHANGES, async () => {
      await this.getNotificationCount();
    });
    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.currentRoute = val.urlAfterRedirects.split('?')[0];
        this.navigationHidden = this.hiddenPages.includes(this.currentRoute);
        this.navigationAdmin = this.hiddenAdmin.includes(this.currentRoute);
        this.menuHidden = this.hiddenMenu.includes(this.currentRoute);
        if (this.currentRoute === '/join/nft') {
          this.nft = true;
          const pack = this.global.get('selectedPackage');
          if (pack) {
            this.selectedPackage = pack.title;
          }
        } else {
          this.nft = false;
        }
      }
    });
  }

  async ngOnInit(): Promise<void> {
    setTimeout(async () => {
      await this.loadNotifications();
    }, 500);
  }

  ngOnDestroy(): void {
    this.destroy$.complete();
  }

  public async getNotificationCount(): Promise<void> {
    const notifications = await this.notificationsModelService.notificationsList();
    if (notifications && 'notifications' in notifications) {
      const notRead: Array<INotification> = notifications.notifications.filter(item => !item.isRead);
      this.notificationsCount = notRead.length;
    }
  }

  private async loadNotifications(): Promise<void> {
    if (this.loggedIn) {
      this.notificationsLoaded = true;
      await this.getNotificationCount();
    } else {
      this.notificationsLoaded = false;
    }

    const time = this.notificationsLoaded ? 60000 : 500;
    setTimeout(async () => {
      await this.loadNotifications();
    }, time);
  }

  getNft() {
    this.nftSrv.myNfts().then(myNft => {
      const apiUrl = environment.apiBaseUrl;
      this.nftImgUrl = myNft[0]?.nftImageUrl;
      this.nftItemId = myNft[0]?.nftItemId;
      this.nftUrl = `${apiUrl}/nft/myNfts/downloadBadge?nftItemId=${this.nftItemId}`
    })
  }

  public async logOut() {
    await this.session.logout();
    this.navigation.goTo('/');
  }

  public requestInvitation(): void {
    this.modal.open(RequestInvitationComponent, this.modalConfig).afterClosed.pipe(
      first(),
      tap((data: string) => {
        if (data) {
          setTimeout(() => {
            this.modal.open(RequestInvitationSuccessComponent, this.modalConfig);
          }, 100);
        }
      }),
      takeUntil(this.destroy$),
    ).subscribe();
  }

  public dashboard(): void {
    if (!this.menuHidden) {
      this.navigation.goTo('/dashboard');
    }
  }

  gotoPackages(): void {
    this.navigation.goTo('/join/membership');
  }

  public toggleProfile(): void {
    this.openProfile = !this.openProfile;
  }

  public supported(): void {
    this.navigation.goTo('/supported');
  }

  public notifications(): void {
    this.navigation.goTo('/notifications');
  }

  public settings(): void {
    this.modal.open(MemberSettingsComponent, {
      style: 'dark',
      size: ModalSize.DYNAMIC,
    })
  }
}
