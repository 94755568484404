import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Nft} from '@interfaces/api.nft.interface';

@Component({
  selector: 'ark-nft-item',
  templateUrl: './nft-item.component.html',
  styleUrls: ['./nft-item.component.scss']
})
export class NftItemComponent implements OnInit {
  @Input() badge!: Nft;
  @Output() badgeSelected: EventEmitter<Nft> = new EventEmitter<Nft>();
  hovering = false;
  imgLoaded = false;
  purchasing = false;
  constructor() { }

  ngOnInit(): void {
  }

  mouseOver(): void {
    if (this.imgLoaded) {
      this.hovering = true;
    }
  }

  select(): void {
    if (!this.purchasing) {
      this.purchasing = true;
      this.badgeSelected.emit(this.badge);
      setTimeout(() => {
        this.purchasing = false;
      }, 2000);
    }
  }

}
