import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'ark-box',
  templateUrl: './box.component.html',
  styleUrls: ['./box.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BoxComponent implements OnInit {

  @Input() size: 'small' | 'medium' | 'large' = 'medium';
  @Input() shadow: 'none' | 'tiny' | 'small' | 'medium' | 'large' = 'medium';
  @Input() shadowType: 'box' | 'filter' = 'box';
  @Input() arrow: 'none' | 'top' | 'left'| 'right' | 'bottom' = 'none';
  @Input() arrowPosition: 'start' | 'center' | 'end' = 'center';

  constructor() { }

  ngOnInit(): void {
  }

}
