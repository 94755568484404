import { Component, OnInit } from '@angular/core';
import {ModalService} from '@services/modal.service';
import {ModalComponent} from '@components/common/modal/modal.component';

@Component({
  selector: 'ark-request-invitation-success',
  templateUrl: './request-invitation-success.component.html',
  styleUrls: ['./request-invitation-success.component.scss']
})
export class RequestInvitationSuccessComponent implements OnInit {

  constructor(protected readonly parent: ModalComponent,) {
    setTimeout(() => this.parent.close(), 2500)
  }

  ngOnInit(): void {
  }

}
