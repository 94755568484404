<div class="invitation-form-wrapper">
    <h2 class="h2">Send invitation</h2>
    <div class="typo-body-small description">
      You have sent <b>{{invitationStats?.send}}</b> invitation(s). You have <b>{{invitationStats?.remaining}}</b> left.
    </div>
    <div class="form">
      <ark-input
      name="email"
      [value]="form.get('email')?.value"
      [required]="true"
      type="email"
      errorLabel="Please enter a valid e-mail address"
      label="Email address"
      (valueChanged)="formValueChange($event, 'email')"
    ></ark-input>
      <div class="error-msg" *ngIf="serverErrorMsg"><ark-icon icon="alert-error" size="small" color="error"></ark-icon> <p class="text">{{this.serverErrorMsg}}</p></div>
    </div>
    <div class="actions">
      <ark-button [link]="true" type="secondary" (click)="close()">
        Cancel
      </ark-button>
      <ark-button (click)="submit()" [loading]="loading">
        SEND
      </ark-button>
    </div>
</div>
