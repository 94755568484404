// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  network: 'dev',
  discordAuthUrl: require('../../environments.json').dev.discordAuthUrl,
  version: require('../../package.json').version + '-dev',
  apiBaseUrl: require('../../environments.json').dev.apiBaseUrl,
  imageBaseUrl: require('../../environments.json').dev.imageBaseUrl,
  matomoUrl: require('../../environments.json').dev.matomoUrl,
  matomoSiteId: require('../../environments.json').dev.matomoSiteId,
  magicKey: require('../../environments.json').dev.magicKey,
  avalancheRpc: require('../../environments.json').dev.avalanche.rpc,
  avalancheChainId: require('../../environments.json').dev.avalanche.chainId,
  stripeKey: require('../../environments.json').dev.stripeKey,
  theme: 'arkius',
  recaptcha: {
    siteKey: '6LeDRakjAAAAANYxq5jrIpd9luLuvUnJDnocegIo',
    secretKey: '6LeDRakjAAAAANLkZNaQcUIULNNV-nW6e_3Sh5X7'
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
