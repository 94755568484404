import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Category} from '@interfaces/api.category.interface';

@Component({
  selector: 'ark-market-sector-item',
  templateUrl: './market-sector-item.component.html',
  styleUrls: ['./market-sector-item.component.scss']
})
export class MarketSectorItemComponent implements OnInit {
  @Input() data!: Category;
  @Output() categoryClicked: EventEmitter<number> = new EventEmitter<number>();
  color: string = '';
  colorMap = ['#F0B94B', '#FA7882', '#A54B91', '#37A5D2', '#55B96E'];
  constructor() { }

  ngOnInit(): void {
    this.color = this.colorMap[this.data.order % 5];
  }

  gotoCategory(): void {
    this.categoryClicked.emit(this.data.certificationCategoryId);
  }
}
