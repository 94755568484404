import {DropDownItem, OptionsMenu, OptionsMenuItemIdentifier} from '@interfaces/common.interface';

export const PostOptionsMenu: OptionsMenu = {
  items: [
    {
      label: 'MENUS.FORUM.POST.HIDE',
      icon: 'visibility-off',
      identifier: OptionsMenuItemIdentifier.POST_HIDE
    },
    {
      label: 'MENUS.FORUM.POST.EDIT',
      icon: 'edit',
      identifier: OptionsMenuItemIdentifier.POST_EDIT
    },
    {
      label: 'MENUS.FORUM.POST.DELETE',
      icon: 'trash',
      identifier: OptionsMenuItemIdentifier.POST_DELETE
    }
  ]
};

export const CommentsOptionsMenu: OptionsMenu = {
  items: [
    // {
    //   label: 'MENUS.FORUM.COMMENT.HIDE',
    //   icon: 'visibility-off',
    //   identifier: OptionsMenuItemIdentifier.COMMENT_HIDE,
    // },
    // {
    //   label: 'MENUS.FORUM.COMMENT.EDIT',
    //   icon: 'system-edit',
    //   identifier: OptionsMenuItemIdentifier.COMMENT_EDIT,
    // },
    {
      label: 'MENUS.FORUM.COMMENT.DELETE',
      icon: 'system-trash',
      identifier: OptionsMenuItemIdentifier.COMMENT_DELETE
    }
  ]
};

export const ReviewOptionsMenu: DropDownItem[] = [
    {
      label: 'MENUS.REVIEWS.EDIT',
      icon: 'system-edit',
      id: OptionsMenuItemIdentifier.REVIEW_EDIT
    },
    {
      label: 'MENUS.REVIEWS.DELETE',
      icon: 'system-trash',
      id: OptionsMenuItemIdentifier.REVIEW_DELETE
    }
];
