import { Component, OnInit } from '@angular/core';
import {ModalComponent} from '@components/common/modal/modal.component';
import {AccountModelService} from '@models/account-model.service';
import {fadeInAnimations} from '@animations/fadein.animations';
import {slideInSameAnimations} from '@animations/slideinsame.animations';

@Component({
  selector: 'ark-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.scss'],
  animations: [fadeInAnimations(), slideInSameAnimations()]
})
export class OnboardingComponent implements OnInit {
  currentStep = 1;
  firstName = '';
  hasCompleted = false;
  constructor(protected readonly parent: ModalComponent,
              private account: AccountModelService) { }

  ngOnInit(): void {
    this.firstName = this.parent.data.firstName;
  }

  close(): void {
    this.parent.close();
  }

  learnMore(): void {
    window.open('https://docs.valuesmovement.org/welcome', '_blank');
  }

  next(): void {
    if (this.currentStep < 3) {
      this.currentStep++;
    } else {
      this.currentStep = 3;
    }
    if (this.currentStep === 3 && !this.hasCompleted) {
      this.account.completeWalkthrough().then((res) => {
        this.hasCompleted = res.walthroughCompleted;
      });
    }

  }
  prev(): void {
    if (this.currentStep > 1) {
      this.currentStep--;
    } else {
      this.currentStep = 1;
    }
  }
}
