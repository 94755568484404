<div class="wrapper--unback-all--created">
  <h3>
    {{'PAGES.CATEGORY_DETAILS.PROPOSALS.ACTION_MODAL.NEW.BACKED_ALL.TITLE' | translate}}
  </h3>
  <div class="description">
    {{'PAGES.CATEGORY_DETAILS.PROPOSALS.ACTION_MODAL.NEW.BACKED_ALL.DESCRIPTION' | translate}}
  </div>
  <ark-button size="huge" type="error"  (click)="unback()">
    {{'PAGES.CATEGORY_DETAILS.PROPOSALS.ACTION_MODAL.NEW.BACKED_ALL.CTA' | translate}}
  </ark-button>
  <div class="cancel" (click)="cancel()">
    {{'PAGES.CATEGORY_DETAILS.PROPOSALS.ACTION_MODAL.NEW.BACKED_ALL.CANCEL' | translate}}
  </div>
</div>
