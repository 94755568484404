import {
  AfterContentInit,
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {InputComponent} from '@components/form/input/input.component';
import {TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs';


@Component({
  selector: 'ark-dropdown-autocomplete',
  templateUrl: './dropdown-autocomplete.component.html',
  styleUrls: ['./dropdown-autocomplete.component.scss']
})
export class DropdownAutocompleteComponent extends InputComponent implements AfterContentInit {
  @ViewChild('wrapper')
  wrapper!: ElementRef<HTMLDivElement>;
  @ViewChild('input') override input!: ElementRef<HTMLInputElement>;
  @Input() maximum = -1;
  @Input() minimum = -1;
  @Input() override name = '';
  @Input() keywords: string[] = [];
  @Input() infoText: string = '';

  protected _errorMaxError: boolean = false;
  get errorMaxError(): boolean {
    return this._errorMaxError;
  }
  @Input() set errorMaxError(value:  boolean) {
    this._errorMaxError = value;
    this.formStates.error = value;
    if (value) {
      this.form.setErrors({incorrect: true});
    } else {
      this.form.setErrors(null);
    }
  }

  protected _errorMinError: boolean = false;
  get errorMinError(): boolean {
    return this._errorMinError;
  }
  @Input() set errorMinError(value:  boolean) {
    this._errorMinError = value;
    this.formStates.error = value;
    if (value) {
      this.form.setErrors({incorrect: true});
    } else {
      this.form.setErrors(null);
    }
  }

  @Output() keywordsChanged: EventEmitter<string[]> = new EventEmitter<string[]>();

  protected intercomSubscription$: Subscription | undefined;

  height = '3.5rem';

  constructor(
    fb: FormBuilder,
    translate: TranslateService
  ) {
    super(fb, translate);
  }

  ngAfterContentInit(): void {
    if (this.keywords?.length > 0) {
      this.formStates.labelRaised = true;
    }
  }

  addKeyword(type: string = ''): void {
    const newValue = this.form.controls['formValue'].value.replace(',', '').trim();
    this.form.controls['formValue'].setValue('');
    if (newValue && !this.keywords.includes(newValue)) {
      this.keywords.push(newValue);
    }
    if (type === 'Comma') {//Comma fix!!!!
      this.input.nativeElement.style.display = 'none';
      setTimeout(() => {
        this.input.nativeElement.style.display = 'block';
        this.input.nativeElement.focus();
      }, 50);
    }
    this.keywordsChanged.emit(this.keywords);
  }

  enter(e: any): void {
    if (this.noTab) {
      e.preventDefault();
    }
    this.addKeyword(e.code);
  }

  divideKeywords(e: Event) {
    if (e instanceof InputEvent) {
      if (e.data === ',') { // e.data === ' '
        this.addKeyword();
      }
    }
  }

  override onBlur(): void {
    super.onBlur();
    this.addKeyword();

    this.formStates.labelRaised = this.keywords.length > 0;
  }

  remove(item: string): void {
    this.keywords = this.keywords.filter((e) => e !== item);
    this.keywordsChanged.emit(this.keywords);
    setTimeout(() => {
      this.height = ((this.wrapper.nativeElement.offsetHeight * 0.0625) + 0.5) + 'rem';
      this.input.nativeElement.focus();
    }, 10);
    this.formStates.labelRaised = this.keywords?.length > 0;
  }

  onDeletePress(e: Event): void {
    if (!(e instanceof KeyboardEvent))
      return;

    if (e.key !== 'Delete')
      return;

    if (this.keywords.length === 0)
      return;

    this.remove(this.keywords[this.keywords.length - 1]);
  }

  onSelectChip(item: string): void {
    // console.log('onSelectChip => ', item);
  }
}
