import { Injectable } from '@angular/core';
import { ApiService } from '@app/services/api.service';
import {
  ApiForumCommentAddRequest,
  ApiForumCommentsAddResponse,
  CertificationTypeId
} from '@interfaces/api.forum.interface';

@Injectable({
  providedIn: 'root'
})
export class ForumModelService {

  constructor(private api: ApiService) { }

  public async getAllComments(certificationTypeId: CertificationTypeId, certificationId: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.api.forum.comment.all(certificationTypeId, certificationId).subscribe((response: any) => {
        resolve(response);
      }, (error) => {
        reject(error);
      });
    });
  }

  public async postComment(certificationTypeId: CertificationTypeId, certificationId: number, body: string): Promise<ApiForumCommentsAddResponse> {
    return new Promise((resolve, reject) => {
      this.api.forum.comment.add(certificationTypeId, certificationId, body).subscribe((response: any) => {
        resolve(response);
      }, (error) => {
        reject(error);
      });
    });
  }

  public async deleteComment(commentId: number): Promise<ApiForumCommentsAddResponse> {
    return new Promise((resolve, reject) => {
      this.api.forum.comment.delete(commentId).subscribe((response: any) => {
        resolve(response);
      }, (error) => {
        reject(error);
      });
    });
  }

  public async likeComment(commentId: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.api.forum.like.add(commentId).subscribe((response: any) => {
        resolve(response);
      }, (error) => {
        reject(error);
      });
    });
  }

  public async unlikeComment(commentId: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.api.forum.like.delete(commentId).subscribe((response: any) => {
        resolve(response);
      }, (error) => {
        reject(error);
      });
    });
  }

}
