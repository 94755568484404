<div #wrapper [formGroup]="form" class="wrapper" [ngClass]="getFormClasses()">
  <ng-container *ngFor="let item of keywords">
    <ark-chip (chipSelected)="onSelectChip(item)" (deleteChip)="remove(item)" [selected]="true" [label]="item" [value]="item"></ark-chip>
  </ng-container>
  <input #input class="input"
         (focus)="onFocus()"
         (blur)="onBlur()"
         [name]="name"
         [placeholder]="formStates.labelRaised && keywords.length === 0 ? placeholder : ''"
         [type]="passwordVisible ? 'text' : type"
         (keydown.tab)="enter($event)"
         (keydown.enter)="enter($event)"
         (keydown.delete)="onDeletePress($event)"
         (input)="divideKeywords($event)"
         [pattern]="pattern"
         [tabindex]="disabled ? -1 : tabIndex"
         [required]="required"
         formControlName="formValue">
  <div class="notch">
    <div class="lead"></div>
    <div class="main">
      <label>{{label}}</label>
    </div>
    <div class="trail"></div>
  </div>
  <div class="icon">
    <ark-icon *ngIf="rightIcon === 'alert-information' && keywords.length === 0"
              ngxTippy
              [tippyName]="tippyName"
              [attr.data-tippy-content]="toolTip"
              [tippyProps]="toolOptions"
              icon="alert-information" size="small" color="darkTint"></ark-icon>
  </div>
</div>
<div class="hint">
  <div class="input-info-text left-info">{{infoText}}</div>
  <div class="input-info-text right-info"
       [ngClass]="{'error': minimumChars && keywords.length < minimum}"
       *ngIf="minimum > -1 && keywords.length === 0"
  >
    {{ 'COMPONENTS.DROPDOWN.MINIMUM_KEYWORDS' | translate: {'value': minimum, 'prefix': minimum > 1 ? 's' : ''} }}
  </div>
  <div class="input-info-text right-info"
       [ngClass]="{'error': maximum && keywords.length > maximum}"
       *ngIf="maximum > -1 && keywords.length > 0"
  >
    {{ 'COMPONENTS.DROPDOWN.MAXIMUM_KEYWORDS' | translate: {'value': maximum} }}
  </div>

</div>
