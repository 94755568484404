import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalComponent } from '@app/components/common/modal/modal.component';
import { ApiAccountProfileResponse } from '@app/etc/interfaces/api.account.interface';
import { ValueChangeEvent } from '@app/etc/interfaces/form.interface';
import { AccountModelService } from '@app/models/account-model.service';
import { UtilService } from '@app/services/util.service';

@Component({
  selector: 'ark-profile-edit',
  templateUrl: './profile-edit.component.html',
  styleUrls: ['./profile-edit.component.scss']
})
export class ProfileEditComponent implements OnInit {
  form: FormGroup = this.initForm();
  loading = false;
  serverErrorMsg: string | null = null;
  profile!: ApiAccountProfileResponse;
  invalidEmail = false;

  constructor( private account: AccountModelService, protected readonly parent: ModalComponent, private fb: FormBuilder, private util: UtilService) {
    this.parent.closeButton = true;
    this.profile = this.parent.data;
  }


  ngOnInit(): void {
    if (this.profile) {
      this.form.patchValue(this.profile);
    }
  }


  initForm(): FormGroup {
    return this.fb.group({
      firstName: [null, Validators.required],
      lastName: [null, Validators.required],
      email: [null, [Validators.required, Validators.email]]
    });
  }


  formValueChange(event: ValueChangeEvent, control: string) {
    this.form.get(control)?.setValue(event.value);
    this.form.markAsDirty();
    this.form.updateValueAndValidity();
  }


  validateForm() {
    if (this.form.get('firstName')?.value === null) {
      this.form.get('firstName')?.setValue('')
    }

    if (this.form.get('lastName')?.value === null) {
      this.form.get('lastName')?.setValue('')
    }

    if (this.form.get('email')?.value === null) {
      this.form.get('email')?.setValue('')
    }

    this.form.updateValueAndValidity();
  }

  submit() {
    this.validateForm();
    this.serverErrorMsg = null;
    if (this.form?.valid && this.form?.dirty) {
      this.loading = true;
      this.account.editProfile(this.form.getRawValue()).then(() => {
        this.loading = false;
        this.parent.close('success');
      }).catch((err: {code: string, description: string }) => {
        this.loading = false;
        this.serverErrorMsg = err.description;
      });
    }
  }


  public close(): void {
    this.parent.close();
  }

}
