<div class="wrapper--login--success">
  <ark-icon size="huge" icon="alert-success" color="dark"></ark-icon>
  <h3>
    You're logged in!
  </h3>
  <div class="description">
    You can close this window.
  </div>
  <div class="cta">
    <ark-button size="medium" type="primary" (click)="close()">
      CLOSE TAB
    </ark-button>
  </div>
</div>
