import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ModalComponent } from '@components/common/modal/modal.component';
import { ValueChangeEvent } from '@interfaces/form.interface';

@Component({
  selector: 'ark-post-hide',
  templateUrl: './post-hide.component.html',
  styleUrls: ['./post-hide.component.scss']
})
export class PostHideComponent implements OnInit {

  step = 1;
  form: FormGroup = new FormGroup({});

  valueIds: string[] = [];

  get hasValues(): boolean {
    return this.valueIds.length > 0;
  }

  constructor(
    private fb: FormBuilder,
    private parent: ModalComponent) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      valueIds: new FormControl([]),
      reason: new FormControl(''),
      description: new FormControl('')
    });
  }

  next(): void {
    this.step++;
  }

  valueSingleSelected(chip: { selected: boolean, value: string }): void {
    if (chip.selected) {
      this.valueIds.push(chip.value);
    } else {
      this.valueIds.splice(this.valueIds.indexOf(chip.value), 1);
    }
  }
  valuesAdd(chip: { name: string, value: string }): void {
    this.valueSingleSelected({ selected: true, value: chip.value });
  }

  valuesRemove(chip: { name: string, value: string }): void {
    this.valueSingleSelected({ selected: false, value: chip.value });
  }

  chipSelected(chip: ValueChangeEvent): void {
    this.form.get('reason')?.setValue(chip.value);
  }

  descriptionChanged(e: ValueChangeEvent): void {
    this.form.get('description')?.setValue(e.value);
  }

  hidePost(): void {
    this.form.get('valueIds')?.setValue(this.valueIds);
    this.next();
    setTimeout(() => {
      this.parent.close();
    }, 2000);
  }

}
