import {Injectable} from '@angular/core';
import {BehaviorSubject, fromEvent} from 'rxjs';
import {distinctUntilChanged, map, startWith, tap} from 'rxjs/operators';

@Injectable()
export class ResponsiveService {

  public resize$: any;
  private screenSizeSubject = new BehaviorSubject(null);
  public screenSize = this.screenSizeSubject.asObservable();

  constructor() {
  }

  onWidthChange(status: any): void {
    this.screenSizeSubject.next(status);
  }

  public resize(): void {
    this.resize$ = fromEvent(window, 'resize')
      .pipe(
        map(() => window.innerWidth),
        distinctUntilChanged(),
        startWith(window.innerWidth),
        tap(width => {
          this.checkWidth(width);
        }),
      );
    this.resize$.subscribe();
  }

  public checkWidth(w: number | null = null): void {
    const width = w || window.innerWidth;
    if (width <= 768) {
      this.onWidthChange({
        status: 'sm',
        width
      });
    } else if (width > 768 && width <= 1023) {
      this.onWidthChange({
        status: 'md',
        width
      });
    } else if (width > 1023) {
      this.onWidthChange({
        status: 'lg',
        width
      });
    }
  }
}
