import { Component, OnInit } from '@angular/core';
import { ModalComponent } from '@app/components/common/modal/modal.component';

@Component({
  selector: 'ark-survey-delete',
  templateUrl: './survey-delete.component.html',
  styleUrls: ['./survey-delete.component.scss']
})
export class SurveyDeleteComponent implements OnInit {

  constructor(private parent: ModalComponent) { }

  ngOnInit(): void {
  }

  submit(): void {
    this.parent.close('delete');
  }
  cancel(): void {
    this.parent.close();
  }

}
