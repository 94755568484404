import {ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Avatar} from '@interfaces/common.interface';
import {PostAuthor} from '@interfaces/forum.interface';
import {IntercomService} from '@services/intercom.service';
import {BroadcastEvent} from '@interfaces/broadcast.interface';
import {UtilService} from '@services/util.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'ark-post-reply-line',
  templateUrl: './post-reply-line.component.html',
  styleUrls: ['./post-reply-line.component.scss', './post-reply-line.component.mobile.scss']
})
export class PostReplyLineComponent implements OnInit {

  @ViewChild('area', {static: false}) area: ElementRef | undefined;

  @Input() avatar!: string;
  @Input() mobile = false;
  @Input() type: 'comment' | 'reply' = 'comment';
  @Input() replyTo?: PostAuthor;

  _disable = false;
  selfHidden = false;

  get disable(): boolean {
    return this._disable;
  }
  @Input() set disable(value: boolean) {
    this._disable = value;
    if (!value) {
      this.content = '';
      this.replyClass = '';
      this.changeDetectionRef.detectChanges();
    }
  }
  @Output() replySubmitted: EventEmitter<string> = new EventEmitter();
  @Output() triggerMobileReply: EventEmitter<string> = new EventEmitter();

  replyClass = '';
  content = '';
  focused = false;

  constructor(private changeDetectionRef: ChangeDetectorRef,
              private util: UtilService,
              private intercom: IntercomService) {
    this.intercom.on(BroadcastEvent.HIDE_REPLY_LINE, () => {
      this.selfHidden = false;
    });
  }

  ngOnInit(): void {
    if (this.content || this.mobile) {
      this.replyClass = 'content';
    }
    if (this.mobile) {
      setTimeout(() => {
        this.area?.nativeElement.focus();
      }, 1000);
    }
  }

  onFocus(): void {
    if (!this.mobile && this.util.isMobile()) {
      this.triggerMobileReply.emit();
      this.selfHidden = true;
    } else {
      this.focused = true;
      if (this.content) {
        this.replyClass = 'focusContent';
      } else {
        this.replyClass = 'focus';
      }
    }
  }

  onBlur(): void {
    this.focused = false;
    if (this.content) {
      this.replyClass = 'content';
    } else {
      this.replyClass = '';
    }
  }

  onChange(): void {
    if (this.content) {
      if (this.focused) {
        this.replyClass = 'focusContent';
      } else {
        this.replyClass = 'content';
      }
    } else {
      if (this.focused) {
        this.replyClass = 'focus';
      } else {
        this.replyClass = '';
      }
    }
  }

  keyDown(evt: KeyboardEvent): void {
    if (!this.util.isMobile()) {
      if ((evt.ctrlKey || evt.metaKey) && evt.key === 'Enter') {
        this.content += '\r\n';
      } else {
        if (evt.key === 'Enter') {
          this.send(evt);
        }
      }
    }
  }

  send(evt: Event): void {
    evt.preventDefault();
    evt.stopPropagation();
    if (this.content) {
      this.replySubmitted.emit(this.content);
    }
  }

}
