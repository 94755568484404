import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {InputComponent} from '@components/form/input/input.component';
import {FormBuilder} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {ValueChangeEvent} from '@interfaces/form.interface';

@Component({
  selector: 'ark-slider-display',
  templateUrl: './slider-display.component.html',
  styleUrls: ['./slider-display.component.scss']
})
export class SliderDisplayComponent extends InputComponent implements OnInit {
  private inputValue: string | number | undefined | null;

  constructor(fb: FormBuilder, translate: TranslateService) {
    super(fb, translate);
    this._value = 0;
    this.inputValue = 0;
  }


  @Input() maxValue: number | string | undefined;
  @Input()
  override set value(value: string | number | null | undefined) {
    this._value = value;
    this.inputValue = value;
  }

  @Output() keyUpEnter: EventEmitter<ValueChangeEvent> = new EventEmitter<ValueChangeEvent>();
  @Output() keyUpTab: EventEmitter<ValueChangeEvent> = new EventEmitter<ValueChangeEvent>();

  public  getValue(): string | number | null | undefined {
    return this._value;
  }

  public onEnter(e: Event): void {
    this.setValue()
    this.keyUpEnter.emit({value: this.getValue(), status: 'VALID'});
  }

  public onTab(e: Event): void {
    this.setValue()
    this.keyUpTab.emit({value: this.getValue(), status: 'VALID'});
  }

  public override onBlur(): void {
    this.setValue()
    this.lostFocus.emit({value: this.getValue(), status: 'VALID'});
  }

  public setValue() {
    if (this.maxValue && this.inputValue && +this.maxValue < +this.inputValue) {
      this.inputValue = this.maxValue;
    }
    this._value = this.inputValue;
  }

  public setInputValue(e: Event) {
    if (e instanceof InputEvent) {
      const element = e.target as HTMLInputElement;
      this.inputValue = +element.value;
    }
  }
}
