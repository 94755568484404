import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'ark-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {
  @Input() pageIndex: number = 1;
  @Input() itemsPerPage: number = 0;
  @Input() totalPages = 0;
  @Input() canPrevious = false;
  @Input() canNext = true;
  @Output() selectedPage: EventEmitter<number> = new EventEmitter<number>();


  constructor() { }

  ngOnInit() {}

  public previous(): void {
    if (this.canPrevious) {
      this.pageIndex -= 1;
      this.selectedPage.emit(this.pageIndex);
    }
  }

  public next(): void {
    if (this.canNext) {
      this.pageIndex += 1;
      this.selectedPage.emit(this.pageIndex);
    }
  }

}
