import {Component, Input, OnInit} from '@angular/core';
import {PostComment, PostMetaData} from '@interfaces/forum.interface';

@Component({
  selector: 'ark-post-more-comments',
  templateUrl: './post-more-comments.component.html',
  styleUrls: ['./post-more-comments.component.scss']
})
export class PostMoreCommentsComponent implements OnInit {

  @Input() metadata!: PostMetaData | PostComment;
  @Input() type: 'comment' | 'reply' = 'comment';
  @Input() loading = false;

  constructor() { }

  ngOnInit(): void {
  }

}
