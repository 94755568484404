<div class="wrapper-survey-details">
  <div *ngIf="!activeSurvey?.nextSurveyInDays">
   <div class="head">
    <h2 class="h2">{{activeSurvey.title}}</h2>
    <!-- <a class="link" [href]="activeSurvey.typeformUrl" target="_blank">Start survey</a> -->
   </div>
    <div class="typo-body-small description"><b>Earn:</b> {{activeSurvey.creditsGiven}} credits </div>
    <a class="link" [href]="activeSurvey.typeformUrl" target="_blank"><ark-icon [icon]="'system-posts'" [size]="'small'"></ark-icon> Start survey</a>
  </div>
  <div *ngIf="activeSurvey?.nextSurveyInDays">
    Next survey in {{activeSurvey.nextSurveyInDays}} day(s).
  </div>
  <div class="actions">
    <ark-button [link]="true" type="secondary" (click)="close()">
      Close
    </ark-button>
  </div>
</div>
