import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IconImage, IconSize, IconColor } from '@interfaces/common.interface';

@Component({
  selector: 'ark-value-modal',
  templateUrl: './value-modal.component.html',
  styleUrls: ['./value-modal.component.scss']
})
export class ValueModalComponent implements OnInit {

  @Input() value = 0;
  @Input() minValue = 0;
  @Input() maxValue = 10;

  @Input() label: string | undefined;
  @Input() description: string | undefined;
  @Input() disabled = false;

  @Output() valueChange = new EventEmitter<number>();

  minusIcon: IconImage = 'system-minus';
  minusIconSize: IconSize = 'tiny';
  minusIconColor: IconColor = 'confirm';

  plusIcon: IconImage = 'system-plus';
  plusIconSize: IconSize = 'tiny';
  plusIconColor: IconColor = 'confirm';



  constructor() { }

  ngOnInit(): void {
  }

  increase(): void {
    if (this.value >= this.maxValue) {
      return;
    }
    this.value++;
    this.valueChange.emit(this.value);
  }

  decrease(): void {
    if (this.value <= this.minValue) {
      return;
    }
    this.value--;
    this.valueChange.emit(this.value);
  }

}
