import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild
} from '@angular/core';
import {Subscription} from 'rxjs';
import {ResponsiveService} from '@services/responsive.service';

@Component({
  selector: 'ark-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})
export class SliderComponent implements OnInit, OnDestroy {

  @ViewChild('slider', {static: false}) slider: ElementRef | undefined;
  @ViewChild('bar', {static: false}) bar: ElementRef | undefined;
  @ViewChild('knob', {static: false}) knob: ElementRef | undefined;
  @Input() inGrid = false;
  @Input() textHidden = false;
  @Input() showKnob = true;
  @Input() fillColor = '';
  @Input() blocked = 0;
  fillCurrentPercentage = 0;
  fillDefaultPercentage = 0;
  currentLeft = 0;
  knobPositionX = 0;
  knobDragActive = false;
  percentageDisplay = 0;
  calcLeft = 0;
  calcWidth = 0;
  knobWidth = 0;
  valuesSet = false;
  mousemoveListener: any = null;
  mouseupListener: any = null;
  notchValue = 0;
  percentageStore = 0;
  responsiveSubscription: Subscription | undefined;
  @Output() dragEnd = new EventEmitter();

  constructor(private renderer: Renderer2, private responsive: ResponsiveService) {
    this.responsive.resize();
    this.responsiveSubscription = this.responsive.screenSize.subscribe(size => {
      if (size !== null && this.valuesSet) {
        this.initPositions(this.percentageStore);
        this.calculatePositions();
      }
    });
  }

  @Input() set current(value: number | undefined) {
    if (value || value === 0) {
      setTimeout(() => {
        this.initPositions(value);
        this.calculatePositions();
        this.valuesSet = true;
      }, 100);
    }
  }

  @Input() set reference(value: number | undefined) {
    if (value) {
      this.notchValue = value;
      this.fillDefaultPercentage = value;
      this.calculatePositions();
    }
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.calculatePositions();
    });
  }

  ngOnDestroy(): void {
    this.responsiveSubscription?.unsubscribe();
  }

  calculatePositions(): void {
    this.calcLeft = this.slider?.nativeElement.getBoundingClientRect().left / (this.inGrid ? 1.1 : 1);
    this.calcWidth = this.bar?.nativeElement.getBoundingClientRect().width / (this.inGrid ? 1.1 : 1);
    this.knobWidth = this.knob?.nativeElement.getBoundingClientRect().width / (this.inGrid ? 1.1 : 1);
  }

  knobDragStart(evt: MouseEvent | TouchEvent): void {
    if (!this.showKnob) {
      return;
    }
    evt.preventDefault();
    this.calculatePositions();
    this.knobDragActive = true;
    const pageX = (evt instanceof MouseEvent ? evt.pageX : evt.touches[0].pageX) / (this.inGrid ? 1.1 : 1);
    this.setElementPositions(pageX);
    if (evt instanceof MouseEvent) {
      this.mousemoveListener = this.renderer.listen('window', 'mousemove', (event) => {
        this.knobDragMove(event);
      });
      this.mouseupListener = this.renderer.listen('window', 'mouseup', (event) => {
        this.knobDragEnd(event);
      });
    } else {
      this.mousemoveListener = this.renderer.listen('window', 'touchmove', (event) => {
        this.knobDragMove(event);
      });
      this.mouseupListener = this.renderer.listen('window', 'touchend', (event) => {
        this.knobDragEnd(event);
      });
    }
  }

  knobDragMove(evt: MouseEvent | TouchEvent): void {
    if (!this.showKnob) {
      return;
    }
    if (this.knobDragActive) {
      const pageX = (evt instanceof MouseEvent ? evt.pageX : evt.touches[0].pageX) / (this.inGrid ? 1.1 : 1);
      this.dragEnd.emit(this.setElementPositions(pageX));
    }
  }

  knobDragEnd(evt: MouseEvent | TouchEvent): void {
    if (!this.showKnob) {
      return;
    }
    if (this.knobDragActive) {
      this.knobDragActive = false;
      const pageX = (evt instanceof MouseEvent ? evt.pageX : evt.changedTouches[0].pageX) / (this.inGrid ? 1.1 : 1);
      this.dragEnd.emit(this.setElementPositions(pageX));
      this.mousemoveListener();
      this.mouseupListener();
    }
  }

  setElementPositions(x: number): number {
    let pct = (x - this.calcLeft) / this.calcWidth * 100;
    pct = Math.max(Math.min(pct, 100), 0);
    const amount = Math.round(pct);
    if (this.blocked === 0 || (this.blocked > 0 && amount <= this.blocked)) {
      this.percentageDisplay = amount;
      this.setCurrent(pct);
      this.percentageStore = pct;
      this.knobPositionX = (pct / 100 * this.calcWidth - (this.knobWidth * 0.53));
    }
    return this.percentageDisplay;
  }

  setCurrent(pct: number): void {
    this.percentageStore = pct;
    if (pct > this.notchValue) {
      this.fillCurrentPercentage = pct - this.notchValue;
      this.currentLeft = this.notchValue;
    } else {
      this.fillCurrentPercentage = this.notchValue - pct;
      this.currentLeft = pct;
    }
  }

  initPositions(pct: number): void {
    if (pct > 100) {
      pct = 100;
    }
    this.setCurrent(pct);
    this.percentageDisplay = Math.round(pct);
    this.knobPositionX = (pct / 100 * this.calcWidth - (this.knobWidth * 0.53));
  }

  @HostListener('window:mousemove', ['$event'])
  public mousemove(event: MouseEvent): void {
    // detachChanges
  }

}
