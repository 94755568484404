import { Component, OnInit } from '@angular/core';
import { ModalComponent } from '@app/components/common/modal/modal.component';
import { ActiveSurvey } from '@app/etc/interfaces/api.survey.interface';
import { SurveyModelService } from '@app/models/survey-model.service';

@Component({
  selector: 'ark-survey-details',
  templateUrl: './survey-details.component.html',
  styleUrls: ['./survey-details.component.scss']
})
export class SurveyDetailsComponent implements OnInit {
  activeSurvey!: ActiveSurvey;

  constructor(protected readonly parent: ModalComponent) {
    this.parent.closeButton = true;
    this.activeSurvey = parent.data.activeSurvey;
   }

  ngOnInit(): void {

  }

  public close(): void {
    this.parent.close();
  }


}
