<div class="post-author--wrapper">
  <ark-identicon size="small" [address]="author.walletId || ''"></ark-identicon>
  <div class="post-author--details">
    <span *ngIf="!comment">{{ 'COMPONENTS.POST_AUTHOR.BY' | translate }}</span> {{ authorName }} <span *ngIf="date">- {{ date | timeAgo }}</span>
  </div>
  <div class="post-author--options" *ngIf="comment">
    <ark-post-user-options (openMobileContextMenu)="openMobileContextMenu()">
      <ark-options-menu (itemClicked)="itemClicked($event)" [options]="menu"></ark-options-menu>
    </ark-post-user-options>
  </div>
</div>
