<div class="post-container">
  <ark-box *ngIf="me" shadow="small" [size]="isMobile ? 'small' : 'large'" [ngClass]="{'doubleBottomPadding': footerVisible}">
    <div class="post--wrapper">
      <h3>Discuss</h3>
      <ark-post-action-bar [metadata]="post.metadata"
                           [expanded]="post.metadata.commentsExpanded"
                           (toggleExpand)="toggleComments()">
      </ark-post-action-bar>

      <ark-post-reply-line [avatar]="me ? me.walletId : ''"
                           [disable]="replyDisabled"
                           (triggerMobileReply)="triggerMobileReply()"
                           (replySubmitted)="submitReply($event)">
      </ark-post-reply-line>

      <ark-post-comments-section [@dropdownAnimation]=""
                                 [me]="me"
                                 [depth]="post.metadata.maxReplyDepth"
                                 *ngIf="post.metadata.commentsExpanded"
                                 [comments]="post.comments"
                                 (replyToParent)="submitReply($event, true)"
                                 (gotoComment)="gotoCommentApply($event)"
                                 (userVote)="doVote($event)"
                                 (deleteComment)="applyDeleteComment($event)">
      </ark-post-comments-section>
    </div>

  </ark-box>
</div>

