<ark-post-comment
  *ngFor="let comment of comments"
  [depth]="nextDepth"
  id="comment-{{comment.id}}"
  [me]="me"
  [mobile]="mobile"
  [comment]="comment"
  [comments]="comments"
  (replyToParent)="parentReply($event)"
  (deleteComment)="applyDeleteComment($event)"
  (gotoComment)="gotoCommentApply($event)"
  (userVote)="doVote($event)"
></ark-post-comment>
