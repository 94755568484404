<div class="profile-form-wrapper">
  <h2 class="h2">Edit Profile</h2>
  <div class="form">
    <ark-input
      [required]="true"
      [value]="form.get('firstName')?.value"
      [name]="'firstName'"
      [label]="'First name'"
      (valueChanged)="formValueChange($event, 'firstName')"
    ></ark-input>
    <ark-input
    [required]="true"
    [value]="form.get('lastName')?.value"
    [name]="'lastName'"
    [label]="'Last name'"
    (valueChanged)="formValueChange($event, 'lastName')"
  ></ark-input>
  <ark-input
  name="email"
  [value]="form.get('email')?.value"
  [required]="true"
  type="email"
  errorLabel="Please enter a valid e-mail address"
  label="Email address"
  (valueChanged)="formValueChange($event, 'email')"
></ark-input>
    <div class="error-msg" *ngIf="serverErrorMsg"><ark-icon icon="alert-error" size="small" color="error"></ark-icon> <p class="text">{{this.serverErrorMsg}}</p></div>
  </div>
  <div class="actions">
    <ark-button [link]="true" type="secondary" (click)="close()">
      Cancel
    </ark-button>
    <ark-button (click)="submit()" [loading]="loading">
      UPDATE
    </ark-button>
  </div>
</div>
