<div [formGroup]="form" class="wrapper" [ngClass]="getFormClasses()">
  <input #input class="input"
         (focus)="onFocus()"
         (blur)="onBlur()"
         [name]="name"
         [placeholder]="formStates.labelRaised ? placeholder : ''"
         [type]="passwordVisible ? 'text' : type"
         (keydown.tab)="noTab ? disableTab($event) : null"
         [readOnly]="readonly"
         [pattern]="pattern"
         [disabled]="disabled"
         [tabindex]="disabled ? -1 : tabIndex"
         [required]="required"
         formControlName="formValue">
  <div class="notch">
    <div class="lead"></div>
    <div class="main">
      <label>{{label}}</label>
    </div>
    <div class="trail"></div>
  </div>
  <div class="icon">
    <ark-icon *ngIf="displayIcon === 'lock'" [icon]="'system-lock'" size="medium" [staticStroke]="true" [ngxTippy]="toolTip"
              [tippyProps]="{arrow: true, placement: 'right'}" color="inactive"></ark-icon>
    <ark-icon *ngIf="displayIcon === 'tick'"
              icon="system-tick" size="medium" [staticStroke]="true" color="cta"></ark-icon>
    <ark-icon *ngIf="displayIcon === 'clear'" icon="system-close" size="tiny"
              color="inactive" (mousedown)="clear($event)"></ark-icon>
    <ark-icon *ngIf="displayIcon === 'eye'" (mousedown)="togglePasswordVisibility($event)"
              [icon]="passwordVisible ? 'system-visibility-on' : 'system-visibility-off'" size="medium" [staticStroke]="true"
              [color]="passwordVisible ? 'cta' : 'disabled'"></ark-icon>
    <ark-icon *ngIf="rightIcon === 'alert-information' && !_value"
              ngxTippy
              [tippyName]="tippyName"
              [attr.data-tippy-content]="toolTip"
              [tippyProps]="toolOptions"
              icon="alert-information" size="small" color="darkTint"></ark-icon>
    <div class="verifying" *ngIf="verifying">
      <ark-spinner></ark-spinner>
    </div>
  </div>
</div>
<div class="error error-bottom" *ngIf="formStates.error" [@dropdownAnimation]>
  <span>{{errorMessage}}</span>
</div>
<div class="info" *ngIf="info">
  <span>{{info}}</span>
</div>
