<div class="share-wrapper">
  <a href="javascript:void 0" (click)="share('Twitter')">
    <ark-icon icon="social-twitter" size="large"></ark-icon>
  </a>
  <a href="javascript:void 0" (click)="share('Facebook')">
    <ark-icon icon="social-facebook" size="large"></ark-icon>
  </a>
  <a href="javascript:void 0" (click)="share('Linkedin')">
    <ark-icon icon="social-linkedin" size="large"></ark-icon>
  </a>
<!--  <a href="{{instagramUrl}}" target="_blank">-->
<!--    <ark-icon icon="social-instagram" size="large"></ark-icon>-->
<!--  </a>-->
</div>
