import {animate, AnimationTriggerMetadata, style, transition, trigger} from '@angular/animations';

export function modalAnimation(): AnimationTriggerMetadata {
  return trigger('modalAnimation', [
    transition(':enter', [
      style({ opacity: 0 }),
      animate('0.3s ease-in-out', style({ opacity: 1 }))
    ]),
    transition(':leave', [
      style({ opacity: 1 }),
      animate('0.3s ease-in-out', style({ opacity: 0 }))
    ])
  ]
  );
}
