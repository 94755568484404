<div class="modal--wrapper">
  <div class="close">
    <ark-icon (click)="close()" icon="system-close" size="small"></ark-icon>
  </div>
  <h2>Confirm your membership</h2>
  <div class="hr"></div>
  <div class="nft-details">
    <div class="nft-image">
      <img src="{{nft.nftImageThumbUrl}}" alt="{{nft.nftName}}" />
    </div>
    <div class="nft-description">
      <h5>You'll get...</h5>
      <div class="get-line">
        <ark-icon icon="system-tick" size="small"></ark-icon>
        <div class="text">
          <ark-icon icon="system-logo-new" size="small"></ark-icon>
          {{membership.credits}} VALU credits
        </div>
      </div>
      <div class="get-line">
        <ark-icon icon="system-tick" size="small"></ark-icon>
        <div class="text">{{nft.nftName}}</div>
      </div>
      <div class="get-line" *ngIf="membership.multiplier > 0">
        <ark-icon icon="system-tick" size="small"></ark-icon>
        <div class="text multi">x{{membership.multiplier}} rewards multiplier
          <ark-icon icon="system-info"
                    size="small"
                    color="disabled"
                    ngxTippy="Earn x{{membership.multiplier}} rewards for completing in-app activities"
                    tippyClassName="typo-body-xsmall"
          ></ark-icon>
        </div>
      </div>
    </div>
  </div>
  <div class="hr"></div>
  <div class="total">
    <h3 class="numbers">Total</h3>
    <h3 class="numbers">{{membership.price | currency}}</h3>
  </div>
  <div class="typo-body-xsmall">
    By clicking “Pay Now” you agree to our <a href="https://www.valuesmovement.org/terms-of-use" target="_blank">Terms of Use</a> and will be redirected to <a href="https://stripe.com" target="_blank">Stripe</a> for payment.
  </div>
  <div class="footer">
    <div (click)="gotoStripe()" class="stripe-logo"></div>
    <ark-button (click)="close(true)" rightIcon="system-external-link">PAY NOW</ark-button>
  </div>
</div>
