<div #dobContainer class="wrapper--date-input" [class.us]="isUS" [class.error]="hasError" [formGroup]="form">
    <label class="label floating" [class.active]="hasFocus"
        >{{label}}</label>
    <div class="day">
        <input #dayInput type="text" placeholder="DD" [maxLength]="2" step="1" formControlName="day"

            (input)="onInput($event, 'day', 2)" (focus)="onInputFocus()" (blur)="onInputBlur()">
    </div>

    <div class="separator sep1">/</div>

    <div class="month">
        <input #monthInput type="text" placeholder="MM" [maxLength]="2" formControlName="month"
            (input)="onInput($event, 'month', 2)" (focus)="onInputFocus()" (blur)="onInputBlur()">
    </div>

    <div class="separator sep2">/</div>

    <div class="year">
        <input #yearInput type="text" placeholder="YYYY" [maxLength]="4" formControlName="year"
            (input)="onInput($event, 'year', 4)" (focus)="onInputFocus()" (blur)="onInputBlur()">
    </div>
</div>

<div class="error-container" *ngIf="hasError">
    <span>{{"COMPONENTS.DATE.INVALID_DATE" | translate}}</span>
</div>
