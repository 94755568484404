import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {IconColor, IconImage, IconSize} from '@interfaces/common.interface';

@Component({
  selector: 'ark-card-checkbox',
  templateUrl: './card-checkbox.component.html',
  styleUrls: ['./card-checkbox.component.scss']
})
export class CardCheckboxComponent implements OnInit {

  @Input() size: 'small' | 'large' | 'tiny' = 'small';

  @Input() label: string | null = null;
  @Input() description: string | null = null;
  @Input() checked = false;
  @Input() disabled = false;

  @Input() icon: IconImage = 'close';
  @Input() iconColor: IconColor = 'confirm';
  @Input() iconSize: IconSize = 'large';

  @Output() selectionChanged = new EventEmitter<{ label: string | null, checked: boolean }>();

  @Input() tickIcon: IconImage = 'tick';
  tickIconColor: IconColor = 'light';
  tickIconSize: IconSize = 'small';
  constructor() { }

  ngOnInit(): void {
  }

  select(): void {
    this.checked = !this.checked;
    this.selectionChanged.emit({
      label: this.label,
      checked: this.checked
    });
  }

}
