<div class="wrapper--checkbox" [ngSwitch]="size">
  <div class="checkbox" [class.checked]="checked" [class.disabled]="disabled" [class.small]="size === 'small'"
       [class.large]="size === 'large'" [class.tiny]="size === 'tiny'" (click)="select()">
    <ng-container *ngSwitchCase="'tiny'" [ngTemplateOutlet]="tiny"></ng-container>
    <ng-container *ngSwitchCase="'small'" [ngTemplateOutlet]="small"></ng-container>
    <ng-container *ngSwitchCase="'large'" [ngTemplateOutlet]="large"></ng-container>
  </div>
</div>


<ng-template #tiny>
  <div class="tick-box" [class.checked]="checked">
    <ark-icon class="icon" [icon]="tickIcon" [size]="'tiny'" [color]="tickIconColor"></ark-icon>
  </div>
  <div class="content">
    <span class="label">{{label}}</span>
  </div>
</ng-template>

<ng-template #small>
  <div class="tick-box" [class.checked]="checked">
    <ark-icon class="icon" [icon]="tickIcon" [size]="tickIconSize" [color]="tickIconColor"></ark-icon>
  </div>
  <div class="content">
    <span class="label">{{label}}</span>
  </div>
</ng-template>

<ng-template #large>
  <div class="icon-wrapper">
    <ark-icon class="icon" [icon]="icon" [size]="iconSize" [color]="iconColor"></ark-icon>
  </div>
  <div class="content">
    <span class="label">{{label}}</span>
    <span class="description">{{description}}</span>
  </div>
  <div class="tick-box" [class.checked]="checked">
    <ark-icon class="icon" [icon]="tickIcon" [size]="tickIconSize" [color]="tickIconColor"></ark-icon>
  </div>
</ng-template>
