import { Component, OnInit } from '@angular/core';
import { ModalComponent } from '@app/components/common/modal/modal.component';

@Component({
  selector: 'ark-survey-action-success',
  templateUrl: './survey-action-success.component.html',
  styleUrls: ['./survey-action-success.component.scss']
})
export class SurveyActionSuccessComponent implements OnInit {
  edit: boolean = false;

  constructor(protected readonly parent: ModalComponent) {
    this.edit = parent.data.edit;
   }

  ngOnInit(): void {
    setTimeout(() => this.parent.close('success'), 2000);
  }

}
