<div [formGroup]="form" class="slider-display"  [ngClass]="getFormClasses()">
<input #input
       type="text"
       (focus)="onFocus()"
       (blur)="onBlur()"
       [name]="name"
       (input)="setInputValue($event)"
       [placeholder]="formStates.labelRaised ? placeholder : ''"
       [type]="passwordVisible ? 'text' : type"
       (keydown.tab)="noTab ? disableTab($event) : null"
       (keyup.enter)="onEnter($event)"
       (keyup.tab)="onTab($event)"
       [readOnly]="readonly"
       [pattern]="pattern"
       [tabindex]="disabled ? -1 : tabIndex"
       [required]="required"
       [value]="getValue()"
       [disabled]="disabled"
       >
</div>
