import { Injectable } from '@angular/core';
import { Proposal, ProposalAllRequest, ProposalAllResponse, ProposalRequest } from '@app/etc/interfaces/api.proposal.interface';
import { ApiService } from '@app/services/api.service';
import { CategoryModelService } from './category-model.service';
import {IDiscussion, IDiscussionAllResponse} from '@interfaces/discussion.interface';

@Injectable({
  providedIn: 'root'
})
export class DiscussionModelService {

  constructor(private api: ApiService, private categorySrv: CategoryModelService) { }

  public async proposalList(data:ProposalAllRequest): Promise<IDiscussionAllResponse> {
    return new Promise((resolve, reject) => {
      this.api.proposal.list.post(data).subscribe(async (response: IDiscussionAllResponse) => {
        resolve(response);
      }, (error) => {
        reject(error);
      });
    });
  }

  public async createProposal(data:ProposalRequest): Promise<Proposal> {
    return new Promise((resolve, reject) => {
      this.api.proposal.create.post(data).subscribe((response: Proposal) => {
        resolve(response);
      }, (error) => {
        reject(error);
      });
    });
  }

  public async updateProposal(data:ProposalRequest): Promise<Proposal> {
    return new Promise((resolve, reject) => {
      this.api.proposal.update.put(data).subscribe((response: Proposal) => {
        resolve(response);
      }, (error) => {
        reject(error);
      });
    });
  }

  public async deleteProposal(id: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.api.proposal.delete.delete(id).subscribe((response: Proposal) => {
        resolve(response);
      }, (error) => {
        reject(error);
      });
    });
  }

  public async likeProposal(id: number, like: boolean): Promise<any> {
    return new Promise((resolve, reject) => {
      if (!like) {
        this.api.proposal.like.post(id).subscribe((response: any) => {
          resolve(response);
        }, (error) => {
          reject(error);
        });
      } else {
        this.api.proposal.like.delete(id).subscribe((response: any) => {
          resolve(response);
        }, (error) => {
          reject(error);
        });
      }
    });
  }

  public async proposalById(id: number): Promise<IDiscussion> {
    return new Promise((resolve, reject) => {
      this.api.proposal.getById.get(id).subscribe((response: IDiscussion[]) => {
        resolve(response[0]);
      }, (error) => {
        reject(error);
      });
    });
  }
}
