import {Injectable} from '@angular/core';
import {ApiService} from '@services/api.service';
import {
  PasswordCreate,
  ApiAccountProfileEdit,
  ApiAccountProfileResponse, EmailLogin,
  InvitationStats, Refresh, Reset,
  Settings
} from '@interfaces/api.account.interface';
import {ApiAdminInviteRequest, ApiAdminInviteRequestsResponse} from '@interfaces/api.admin.interface';
import {ApiAuthLoginEmailResponse} from '@interfaces/api.auth.interface';


@Injectable({
  providedIn: 'root'
})
export class AccountModelService {

  constructor(private api: ApiService) {
  }

  public async profile(): Promise<ApiAccountProfileResponse> {
    return new Promise(async (resolve, reject) => {
      this.api.account.profile.get().subscribe(async(response: ApiAccountProfileResponse) => {
        resolve(response);
      }, (error) => {
        reject(error);
      });
    })
  }

  public async editProfile(data: ApiAccountProfileEdit): Promise<any> {
    return new Promise(async (resolve, reject) => {
      this.api.account.profile.edit(data).subscribe(async(response: any) => {
        resolve(response);
      }, (error) => {
        reject(error);
      });
    })
  }

  public async completeWalkthrough(): Promise<ApiAccountProfileResponse> {
    return new Promise(async (resolve, reject) => {
      this.api.account.completeWalkthrough().subscribe(async(response: any) => {
        resolve(response);
      }, (error) => {
        reject(error);
      });
    })
  }

  public async sendInvitation(data: ApiAdminInviteRequest): Promise<any> {
    return new Promise(async (resolve, reject) => {
      this.api.account.invitation.send(data).subscribe(async(response: any) => {
        resolve(response);
      }, (error) => {
        reject(error.error.errors[0]);
      });
    })
  }


  public async requestInvitation(data: ApiAdminInviteRequest): Promise<any> {
    return new Promise(async (resolve, reject) => {
      this.api.account.invitation.request(data).subscribe(async(response: any) => {
        resolve(response);
      }, (error: any) => {
        reject(error.error.errors[0]);
      });
    })
  }

  public async requestSendInvitation(id: number, data: any): Promise<any> {
    return new Promise(async (resolve, reject) => {
      this.api.account.invitation.sendRequest(id, data).subscribe(async(response: any) => {
        resolve(response);
      }, (error: any) => {
        reject(error.error.errors[0]);
      });
    })
  }

  public async listInvitationRequests(): Promise<ApiAdminInviteRequestsResponse[]> {
    return new Promise(async (resolve, reject) => {
      this.api.account.invitation.listInvitationRequests().subscribe(async(response: any) => {
        resolve(response);
      }, (error: any) => {
        console.log(error)
        reject(error);
      });
    })
  }

  public async invitationStats(): Promise<InvitationStats> {
    return new Promise(async (resolve, reject) => {
      this.api.account.invitation.stats().subscribe(async(response: InvitationStats) => {
        resolve(response);
      }, (error) => {
        reject(error);
      });
    })
  }

  public async postSettings(data: Settings): Promise<Settings> {
    return new Promise(async (resolve, reject) => {
      this.api.account.settings.set(data).subscribe(async(response: Settings) => {
        resolve(response);
      }, (error) => {
        reject(error);
      });
    })
  }

  public async getSettings(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      this.api.account.settings.get().subscribe(async(response: Settings) => {
        resolve(response);
      }, (error) => {
        reject(error);
      });
    })
  }

  public async reset(data: Reset): Promise<any> {
    return new Promise(async (resolve, reject) => {
      this.api.account.login.reset(data).subscribe(async(response: any) => {
        resolve(response);
      }, (error) => {
        reject(error);
      });
    })
  }

  public async refresh(data: Refresh): Promise<any> {
    return new Promise(async (resolve, reject) => {
      this.api.account.login.refresh(data).subscribe(async(response: any) => {
        resolve(response);
      }, (error) => {
        reject(error);
      });
    })
  }

  public async create(data: PasswordCreate): Promise<ApiAuthLoginEmailResponse> {
    return new Promise(async (resolve, reject) => {
      this.api.account.login.create(data).subscribe(async(response: any) => {
        resolve(response);
      }, (error) => {
        reject(error);
      });
    })
  }

  public async login(data: EmailLogin): Promise<ApiAuthLoginEmailResponse> {
    return new Promise(async (resolve, reject) => {
      this.api.account.login.login(data).subscribe(async(response: any) => {
        resolve(response);
      }, (error) => {
        reject(error);
      });
    })
  }
}
