import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {DropDownItem} from '@interfaces/common.interface';
import {OrderByTypes, SortByTypes} from '@constants/sort-filter-types';
import {IFilter} from '@interfaces/api.category.interface';

@Component({
  selector: 'ark-tabs-filter-bar',
  templateUrl: './tabs-filter-bar.component.html',
  styleUrls: ['./tabs-filter-bar.component.scss']
})
export class TabsFilterBarComponent implements OnChanges {
  @Input() pageView: string | undefined;
  @Input() order: string = '';
  @Input() field: string = '';
  @Input() showSearch: boolean = true;

  @Output()
  public filter: EventEmitter<IFilter> = new EventEmitter<IFilter>();

  public filterData: IFilter = {
    orderBy: {
      order: '',
      field: ''
    },
    searchStr: ''
  };

  constructor() {
  }

  public sortBy: DropDownItem[] = [
    {
      label: 'PAGES.CATEGORY_DETAILS.FILTER_BAR.SORT_BY.ITEMS.RANKING',
      id: SortByTypes.Ranking,
    },
    {
      label: 'PAGES.CATEGORY_DETAILS.FILTER_BAR.SORT_BY.ITEMS.LAST_SUPPORTED',
      id: SortByTypes.LastSupported,
    },
    {
      label: 'PAGES.CATEGORY_DETAILS.FILTER_BAR.SORT_BY.ITEMS.PAST_MONTH_CREDITS',
      id: SortByTypes.PastMonthCredits,
    },
    // {
    //   label: 'PAGES.CATEGORY_DETAILS.FILTER_BAR.SORT_BY.ITEMS.MOST_SUPPORTERS',
    //   id: SortByTypes.MostSupporters,
    // },
    // {
    //   label: 'PAGES.CATEGORY_DETAILS.FILTER_BAR.SORT_BY.ITEMS.MOST_RECENT',
    //   id: SortByTypes.MostRecent,
    // },
    // {
    //   label: 'PAGES.CATEGORY_DETAILS.FILTER_BAR.SORT_BY.ITEMS.MOST_FUNDED',
    //   id: SortByTypes.MostFunded,
    // },
    // {
    //   label: 'PAGES.CATEGORY_DETAILS.FILTER_BAR.SORT_BY.ITEMS.CLOSEST_GOAL',
    //   id: SortByTypes.ClosestGoal,
    // },
  ];

  public orderBy: DropDownItem[] = [
    {
      label: 'PAGES.CATEGORY_DETAILS.FILTER_BAR.ORDER_BY.ITEMS.TITLE_A_Z',
      id: OrderByTypes.TitleAz,
    },
    {
      label: 'PAGES.CATEGORY_DETAILS.FILTER_BAR.ORDER_BY.ITEMS.TITLE_Z_A',
      id: OrderByTypes.TitleZa,
    },
    {
      label: 'PAGES.CATEGORY_DETAILS.FILTER_BAR.ORDER_BY.ITEMS.A_Z',
      id: OrderByTypes.DescriptionAz,
    },
    {
      label: 'PAGES.CATEGORY_DETAILS.FILTER_BAR.ORDER_BY.ITEMS.Z_A',
      id: OrderByTypes.DescriptionZa,
    },
  ];

  ngOnChanges(changes: SimpleChanges): void {
    if (this.pageView && this.pageView === 'proposals') {
      this.filterData.orderBy.field = SortByTypes.MostSupporters;
      this.filterData.orderBy.order = 'DESC'
    } else {
      this.filterData.orderBy.field = 'title';
      this.filterData.orderBy.order = 'ASC'
    }
  }

  public async onSearchTextChange(query: any): Promise<void> {
    this.filterData.searchStr = query.formValue;
    this.filter.emit(this.filterData);
  }


  public async setOrderFilter(item: DropDownItem): Promise<any> {

    if (this.pageView === 'proposals') {
      this.filterData.orderBy.field = item.id?.toString();
    } else {
      switch (item.id) {
        case OrderByTypes.TitleAz:
          this.filterData.orderBy.order = 'ASC';
          this.filterData.orderBy.field = 'title';
          break;
        case OrderByTypes.TitleZa:
          this.filterData.orderBy.order = 'DESC';
          this.filterData.orderBy.field = 'title';
          break;
        case OrderByTypes.DescriptionAz:
          this.filterData.orderBy.order = 'ASC';
          this.filterData.orderBy.field = 'description';
          break;
        case OrderByTypes.DescriptionZa:
          this.filterData.orderBy.order = 'DESC';
          this.filterData.orderBy.field = 'description';
          break;
        default:
          break;
      }
    }

    this.filter.emit(this.filterData);
  }
}
