import { Injectable } from '@angular/core';
import {NavigationPage} from '@interfaces/common.interface';
import {Router} from '@angular/router';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  protected readonly _scrolling$ = new BehaviorSubject<boolean>(false);

  constructor(private router: Router) { }

  goTo(page: NavigationPage): void {
    this.router.navigate([page]);
  }

  public get scrolling$(): Observable<boolean> {
    return this._scrolling$.asObservable();
  }

  public setScrolling(value: boolean): void {
    this._scrolling$.next(value);
  }
}
